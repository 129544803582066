<div style="background: white">
  <div fxLayout="row nowrap" fxFlexFill fxLayoutAlign="space-between stretch" class="presse-header">
    <div class="navbar-title" fxFlex="1 1" fxFlexFill>
      <h1> Presse </h1>
    </div>
  </div>
  <div style="margin: 0 10px 0 30px;">
    <div fxLayout="column" fxLayoutAlign="space-evenly center" class="bloc">
      <!-- <div fxLayout="row nowrap" fxFlexFill fxLayoutAlign="space-between stretch" class="presse-bloc"> -->
      <div class="presse-bloc" fxFlex="1 1" fxFlexFill>
        <h2> Informations presse </h2>
        <hr>
      </div>
      <!-- </div> -->

      <div fxLayout="row wrap" fxLayoutAlign="space-around center" class="information-bloc">
        <div fxLayout="column" fxLayoutAlign="space-between stretch" class="bloc-bloc">
          <div class="image-bloc" style="background-image: url('../../../assets/image/presseImg2.jpeg');">
            <div>
              <a href="assets/data/gOtaf_Communique_de_Presse.pdf" target="_blank" rel="noopener noreferrer">
                <mat-icon class="icon-overlay" style="height: 100px; width: 100px;" svgIcon="open_in_new_tab">
                </mat-icon>
              </a>
            </div>

          </div>
          <div fxLayout="row nowrap" fxLayoutAlign="space-around center" class="button-bloc">
            <div>
              <h6>
                Communiqué de presse
              </h6>
            </div>
            <div>
              <button mat-icon-button (click)="downloadFile('assets/data/gOtaf_Communique_de_Presse.pdf')">
                <mat-icon svgIcon="download"></mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between stretch" class="bloc-bloc">
          <div class="image-bloc" style="background-image: url('../../../assets/image/presseImg1.jpeg');">
            <div>
              <a href="assets/data/gOtaf_Dossier_de_presse.pdf" target="_blank" rel="noopener noreferrer">
                <mat-icon class="icon-overlay" style="height: 100px; width: 100px;" svgIcon="open_in_new_tab">
                </mat-icon>
              </a>

            </div>
          </div>
          <div fxLayout="row nowrap" fxLayoutAlign="space-around center" class="button-bloc">
            <div>
              <h6>Dossier de presse</h6>
            </div>
            <div>
              <button mat-icon-button (click)="downloadFile('assets/data/gOtaf_Dossier_de_presse.pdf')">
                <mat-icon svgIcon="download"></mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between stretch" class="bloc-bloc">
          <div class="image-bloc" style="background-image: url('../../../assets/image/presseImg3.jpeg');">
          </div>
          <div fxLayout="row nowrap" fxLayoutAlign="space-around center" class="button-bloc">
            <div>
              <h6>Kit Visuels</h6>
            </div>
            <div>
              <button mat-icon-button (click)="downloadFile(imageBaseUrl + 'files/visuels.zip')">
                <mat-icon svgIcon="download"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  <div fxLayout="column" fxLayoutAlign="space-evenly center" class="bloc">
    <div fxLayout="row nowrap" fxFlexFill fxLayoutAlign="space-between stretch" class="">
      <div class="presse-bloc" fxFlex="1 1" fxFlexFill>
        <h2> Notre teaser presse </h2>
        <hr>
      </div>
    </div>
    <div fxLayout="row nowrap" fxFlexFill fxLayoutAlign="center center">
      <div fxFlex="60" style="height: 500px" class="video-bloc" fxLayout="column" fxLayoutAlign="space-between stretch">
        <div>
          <video id="my_video_1" class="video-js vjs-default-skin image-bloc-2" width="100%" height="440px" controls controlsList="nodownload"
            preload="none" poster='../../../assets/data/poster.jpeg'
            data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>
            <source src="{{ imageBaseUrl + 'files/gOtaf_candidat_Paysage_Final.mp4'}}" type='video/mp4' />
          </video>
        </div>
        <div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="button-bloc-2">
          <div>
            <h6 style="font-weight: bold; margin-left: 20px;">
              Gotaf Teaser
            </h6>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div fxLayout="row nowrap" fxFlexFill fxLayoutAlign="space-between stretch" class="">
    <div class="presse-bloc" fxFlex="1 1" fxFlexFill>
      <h2> Nos identités sonores </h2>
      <hr>
    </div>
  </div>
  <div fxLayout="row wrap" fxFlexFill fxLayoutAlign="space-around center" class="">
    <mat-card class="audio-card">
<!--      <iframe src="https://anchor.fm/jean-muriel-azonhoume/embed/episodes/Offre-demploi-gOtaf-euct6k" height="100%"-->
<!--        width="100%" frameborder="0" scrolling="no"></iframe>-->
      <iframe src="https://anchor.fm/jean-muriel-azonhoume/embed/episodes/Presentation-gOtaf-eudcdc" height="100%"
        width="100%" frameborder="0" scrolling="no"></iframe>
    </mat-card>
    <mat-card class="audio-card">
      <iframe src="https://anchor.fm/jean-muriel-azonhoume/embed/episodes/Offre-demploi-gOtaf-euct6k/a-a4v5oc8" height="100%"
              width="100%" frameborder="0" scrolling="no"></iframe>
    </mat-card>
    <mat-card class="audio-card">
      <iframe src="https://anchor.fm/jean-muriel-azonhoume/embed/episodes/CV-Audio-Candidat-gOtaf-eudbni" height="100%"
        width="100%" frameborder="0" scrolling="no"></iframe>
    </mat-card>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-evenly center" class="bloc">
    <!-- <div fxLayout="row nowrap" fxFlexFill fxLayoutAlign="space-between stretch" class="presse-bloc"> -->
    <!-- <div fxLayout="row nowrap" fxFlexFill fxLayoutAlign="space-between stretch" class=""> -->
    <div class="presse-bloc" fxFlex="1 1" fxFlexFill>
      <h2> Nos contacts presse </h2>
      <hr>
      <!-- </div> -->
    </div>
    <!-- </div> -->

    <div fxLayout="row wrap" fxLayoutAlign="space-evenly center" class="information-bloc">

      <div id="wrapper">
        <div id="profile">
          <div class="img"></div>
          <div class="info">
            <div>
              <span style="font-weight: bold;">Ophélie Surelle</span>
            </div>
            <div>
              ophelie.surelle@gmail.com
            </div>
            <div>(+33) 06 28 51 42 70</div>

          </div>
        </div>
      </div>

      <div id="wrapper">
        <div id="profile">
          <div class="img-2"></div>
          <div class="info">
            <div>
              <span style="font-weight: bold;">Marie Devemy</span>
            </div>
            <div>
              mdevemy@gmail.com
            </div>
            <div>(+33) 06 03 97 90 01</div>
          </div>
        </div>
      </div>
      <!-- <div style="height: 250px; background-color: red; width: 350px">
      </div>
      <div style="height: 250px; background-color: red; width: 350px">
      </div> -->
        </div>
      </div>
    </div>
  </div>

<mat-toolbar-row fxLayout="row wrap" fxLayoutAlign="space-evenly center" class="footer-toolbar-row" id="footerNav">
  <div class="row" fxLayout="row wrap">
    <div class="left-navbar-bloc" style="text-align: left" fxFlex>
      <span> Nous croyons dans le potentiel de chacun<br>Retrouvez-nous sur les réseaux sociaux</span>
    </div>
  </div>
  <span class="navbar-spacer"></span>
  <div class="row" fxLayout="row wrap">
    <div class="right-navbar-bloc" fxFlex fxLayoutAlign="flex-end center">
      <a target="_blank" href="https://www.facebook.com/gotafemploi" style="text-decoration: none;">
        <mat-icon svgIcon="icon_facebook"></mat-icon>
      </a>
      <a target="_blank" href="https://www.instagram.com/gotafemplois/" style="text-decoration: none;">
        <mat-icon svgIcon="icon_instagram"></mat-icon>
      </a>
      <a target="_blank" href="https://youtu.be/I5WrwtfknxE"
        style="text-decoration: none;">
        <mat-icon svgIcon="icon_youtube"></mat-icon>
      </a>
      <a target="_blank" href="https://www.tiktok.com/@gotaf21?lang=fr" style="text-decoration: none;">
        <mat-icon svgIcon="icon_tiktok"></mat-icon>
      </a>
      <a target="_blank" href="https://www.twitch.tv/gotaf21" style="text-decoration: none;">
        <mat-icon svgIcon="icon_twitch"></mat-icon>
      </a>
      <a target="_blank" href="https://www.linkedin.com/company/gotaf" style="text-decoration: none;">
        <mat-icon svgIcon="icon_linkedin"></mat-icon>
      </a>
    </div>
  </div>
</mat-toolbar-row>
<!-- <app-footer></app-footer> -->
