import { MaxSizeValidator } from '@angular-material-components/file-input';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { CompanyService } from '../../../service/company/company.service';
import { AuthenticationService } from '../../../service/authentication/authentication.service';
import { SendJobFileService } from '../../../service/company/send-job-file.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { SnackService } from '../../../service/snack/snack.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-company-job-multiple',
  templateUrl: './company-job-multiple.component.html',
  styleUrls: ['./company-job-multiple.component.css']
})
export class CompanyJobMultipleComponent implements OnInit {
  fileControl: FormControl;
  color: ThemePalette = 'primary';
  disabled = false;
  multiple = false;
  accept = '.xlsx';
  public files;
  maxSize = 16;
  public company: any;
  progress = 0;
  bufferValue = 75;
  mode: ProgressBarMode = 'determinate';


  // tslint:disable-next-line: max-line-length
  constructor(private companyService: CompanyService, private authenticationService: AuthenticationService, private sendJobFileService: SendJobFileService, private snack: SnackService, public dialogRef: MatDialogRef<CompanyJobMultipleComponent>) {
    this.fileControl = new FormControl(this.files, [
      Validators.required,
      MaxSizeValidator(this.maxSize * 1024)
    ]);
  }

  ngOnInit(): void {
    this.fileControl.valueChanges.subscribe((files: any) => {
      if (!Array.isArray(files)) {
        this.files = [files];
      } else {
        this.files = files;
      }
    });
  }

  public submit(): void {
    this.companyService.getCompanyByUser(this.authenticationService.userValue.id).subscribe((data) => {
      this.company = data;
      this.sendJobFileService.uploadFile(this.files[0], this.company.id).subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
          this.snack.global('Votre demande sera traité dans les plus bref délais');
        }
        this.dialogRef.close();
      }, (error) => {
        console.log(error);
        this.progress = 0;
        this.snack.error('Erreur lors de l\'envoie du fichier');
        // this.msg = 'Error occured while uploading file';
        // this.existingFile = undefined;
      });
    }, ignore => {
      console.log('error when loading lastJobs'); // TODO: change here by a fancy snack to display error
    });
  }
}
