<div class="job-question-modal-header">
  <h4 mat-dialog-title>Choisissez une image</h4>
  <!-- <button mat-button (click)="onNoClick()">x</button> -->
</div>
<div fxLayout="row wrap" fxLayout.xs="column" fxFlexFill fxLayoutAlign="space-between">
  <!-- premiere column -->
  <div (click)="activateClass(i)" *ngFor="let url of imageUrls; let i = index" fxFlex="30" fxFlex.md="30" fxFlex.sm="35"
    class="img" fxFlex.xs="30" style="background-image: url({{url}})" [ngClass]="{'active-style': imageId === i}">
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Fermer</button>
  <button mat-button type="button" class="btn btn-outline-dark" cdkFocusInitial (click)="submit()">Confirmer votre
    choix</button>
</div>