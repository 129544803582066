import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-edit-text',
  templateUrl: './edit-text.component.html',
  styleUrls: ['./edit-text.component.css']
})
export class EditTextComponent implements OnInit {
  @Input() value: string;

  @ViewChild('editField')
  public editField: ElementRef;

  isEditing = false;

  constructor() { }

  ngOnInit(): void {
  }

  public onClick(event): void {
    this.isEditing = !this.isEditing;
    if (event) {
      const style = getComputedStyle(event.target);
      if (this.editField) {
        this.editField.nativeElement.style.font = style.font;
        // this.editField.nativeElement.style.fontSize = style.fontSize;
        // this.editField.nativeElement.style.fontWeight = style.fontWeight;
      }

      console.log(style.fontSize);
    }

  }

}
