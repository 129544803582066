import { Component, OnInit } from '@angular/core';
import { YtPlayerService, PlayerOptions } from 'yt-player-angular';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.css']
})
export class YoutubeComponent implements OnInit {
  constructor(private ytPlayerService: YtPlayerService) {
  }

  option: PlayerOptions = {
    autoplay: true,
    playsInline: true,
    related: false
  };

  ngOnInit() { }
}
