import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SendJobFileService {
  private apiUrl: any;
  constructor(private httpClient: HttpClient) {
    this.apiUrl = environment.backendApiUrl;
  }

  uploadFile(file: File, id: number): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('jobFile', file);

    const request = new HttpRequest('POST', `${this.apiUrl}company/${id}/jobs/upload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.httpClient.request(request);
  }
}
