import { Component, OnInit } from '@angular/core';
import { JobsService } from 'src/app/service/jobs/jobs.service';
import { SnackService } from 'src/app/service/snack/snack.service';
import { AuthenticationService } from '../../../service/authentication/authentication.service';
import { environment } from '../../../../environments/environment';
import { AudioService } from '../../../service/audio.service';
import { AudioFileService } from '../../../service/audiofile.service';

@Component({
  selector: 'app-job-favorite',
  templateUrl: './user-job-favorite.component.html',
  styleUrls: ['./user-job-favorite.component.css', './../../../../../node_modules/bootstrap/dist/css/bootstrap.css']
})
export class UserJobFavoriteComponent implements OnInit {
  public jobsFavorite: any;
  public imageBaseUrl: string = environment.imageBaseUrl;
  constructor(
    private jobsService: JobsService,
    private snack: SnackService,
    private authenticationService: AuthenticationService,
    private audioService: AudioService,
    private audioFileService: AudioFileService,
  ) { }

  ngOnInit(): void {
    this.getAllJobFavorite(this.authenticationService.userValue.id);
  }


  public getAllJobFavorite(userId): void {
    this.jobsService.getJobFavorite(userId).subscribe((data) => {
      this.jobsFavorite = data;
    }, (err) => {
      this.snack.error('Erreur lors de la récupération des données.');
    });
  }
  public playAudio(audioName: string, audioToPlay: string, job: any): void {
    this.audioFileService.addToPlayer(job);
    // this.audioSource = audioToPlay;
    this.audioService.getAudioFile(audioName);
  }

}
