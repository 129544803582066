import { FormGroup, FormBuilder } from '@angular/forms';
import { Job } from './../../../model/job';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import Shuffle from 'shufflejs';
import { JobsService } from '../../../service/jobs/jobs.service';
import { GoogleMap } from '@angular/google-maps';
import { JobCategoriesService } from 'src/app/service/jobCategories/job-categories.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { logger } from 'codelyzer/util/logger';
import { environment } from '../../../../environments/environment';
import { SnackService } from '../../../service/snack/snack.service';
import { SpeechToTextService } from '../../../service/speech-to-text/speech-to-text.service';
import { AudioService } from '../../../service/audio.service';
import { AudioFileService } from '../../../service/audiofile.service';
import { Observable, fromEvent } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { JobCategory } from '../../../model/jobCategory';
import { ModalCustomService } from '../../../service/dialog/modal-custom.service';
import { Size } from '../../../shared/const/const';
import { SocialSharedComponent } from '../../../shared/social-shared/social-shared.component';
import { ItineraryOptionsService } from '../../../service/itinerary-options/itinerary-options.service';
import { NgxMasonryOptions } from 'ngx-masonry';
import { Platform } from '@angular/cdk/platform';
import { SharedServiceService } from '../../../shared/shared-service.service';
declare var FCMPlugin: any;

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
interface Location {
  lat: number;
  lng: number;
  viewport?: Object;
  zoom: number;
  address_level_1?: string;
  address_level_2?: string;
  address_country?: string;
  address_zip?: string;
  address_state?: string;
  marker?: Marker;
  options: any;
  jobId: number;
}

@Component({
  selector: 'app-job-category',
  templateUrl: './job-category.component.html',
  styleUrls: ['./job-category.component.css', './../../../../../node_modules/bootstrap/dist/css/bootstrap.css', '../../../shared/css/masonry.style.css', '../../../shared/css/map-view.style.css', '../../../shared/css/job-list.style.css']
})
export class JobCategoryComponent implements OnInit {
  public myOptions: NgxMasonryOptions = {
    // itemSelector: '.grid-item',
    columnWidth: 75,
    fitWidth: true,
    resize: true,
    itemSelector: '.grid-item'
  };
  @ViewChild('GoogleMap', { static: false }) map: GoogleMap;
  @ViewChildren('div') set divs(value: QueryList<ElementRef<HTMLTextAreaElement>>) {
    // For lifecycle error, use timeout
    setTimeout(() => this.speechToText.divs = value);
  }
  public searchModelChange: any;
  public array = [1, 2];
  public imageBaseUrl: string = environment.imageBaseUrl;
  public isPreLoader = false;
  pageNumber = 1;
  paginatedJobs: any[] = [];
  jobPerPage = 8;
  totalJobList: any[] = [];
  public totalJobs = 0;
  public lastPage = null;
  public loadingJobs = false;
  public searchForm: FormGroup;
  public jobCategories: JobCategory[] = [];
  public lastScrollTop: number = 0;

  public markerIconClikedValue: number;
  public location: Location = {
    lat: 47.351861,
    lng: 0.6613099,
    marker: {
      lat: 47.351861,
      lng: 0.6613099,
      draggable: true
    },
    zoom: 5,
    options: {},
    jobId: null
  };
  public isAGMDirection = false;
  public origin: any;
  public destination: any;
  public renderOptions = {
    suppressMarkers: true,
    polylineOptions: { strokeColor: '#7b7c7c' }
  };
  public waypoints: any = [];
  public mapTransportMode = '';
  public mapDistanceDuration: string;
  private previous;
  public currentLatitude = 47.351861;
  public currentLongitude = 0.6613099;


  zoom = 12;
  options: google.maps.MapOptions = {
    // mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };
  isVoirCLick = true;
  jobsList: any[] = [];
  allJobMarkers: any;
  public categoryJobNumber: number;
  categoryId: number;
  category: any;
  userConnected: any;
  // markerOptions = { icon: '../../../../assets/image/icon_localisation_map.svg' };
  markerOptions: any;
  gridSection = true;
  listSection = false;
  public audioSource: string;
  constructor(private readonly jobCategoryService: JobCategoriesService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    public jobsService: JobsService,
    private snack: SnackService,
    private speechToText: SpeechToTextService,
    private audioService: AudioService,
    private audioFileService: AudioFileService,
    private modalCustomService: ModalCustomService,
    public itineraryOptionsService: ItineraryOptionsService,
    private sharedService: SharedServiceService,
    private plt: Platform,
  ) {
    this.authenticationService.user.subscribe(x => this.userConnected = x);
  }

  public gridFunction(): void {
    this.gridSection = true;
    this.listSection = false;
  }
  public listFunction(): void {
    this.listSection = true;
    this.gridSection = false;
  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      querry: [''],
      city: ['']
    });
    this.categoryId = +this.activatedRoute.snapshot.params.job_category_id;
    this.getCurrentCategorieByd();
    this.getCurrentLatLong(null);
    this.getAllJobsByCategorie();
    this.getCategories();
    this.jobsService.isVoirCLick = true;
  }

  public updateMapView(job: any): void {
    this.location.lat = job.options.lat;
    this.location.lng = job.options.lng;
    this.location.zoom = 20;
  }

  // Map Click Marker
  public clickedMarker(infowindow: any) {
    this.isAGMDirection = false;

    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;

  }
  public removeItineraire(): void {
    this.isAGMDirection = false;
    this.mapTransportMode = '';
    if (this.previous) {
      this.previous.close();
    }
  }

  public getDirection(destinationLatLong: any) {
    this.isAGMDirection = true;
    this.origin = { lat: this.currentLatitude, lng: this.currentLongitude };
    this.destination = { lat: destinationLatLong.options.lat, lng: destinationLatLong.options.lng };
    this.getVehicle('DRIVING');
    // this.origin = { lat: 24.799448, lng: 120.979021 };
    // this.destination = { lat: 24.799524, lng: 120.975017 };
  }
  public getVehicle(transportMode) {
    console.log('transportMode' + transportMode);
    console.log('this.currentLatitude' + this.currentLatitude);
    console.log('this.currentLatitude' + this.currentLongitude);
    console.log('this.destination.lng' + this.destination.lng);
    console.log('this.destination.lat' + this.destination.lat);
    this.itineraryOptionsService.getVehicle(transportMode, this.currentLatitude, this.currentLongitude, this.destination.lat, this.destination.lng).then(data => {
      this.mapTransportMode = transportMode;
      this.mapDistanceDuration = data;
    });
  }
  public focusOneJobs(id: number): void {
    this.markerIconClikedValue = id;
  }

  // public displayActivePage(activePageNumber: number): void {
  //   this.pageNumber = activePageNumber;
  //   this.paginatedJobs = this.jobsList.slice((activePageNumber - 1) * this.jobPerPage, this.jobPerPage * activePageNumber);
  // }
  public click(e): void {
  }

  public getCurrentCategorieByd(): void {
    this.jobCategoryService.getJobCategory(this.categoryId).subscribe((data) => {
      this.category = data;
    });
  }

  public getLatLong(address, element): void {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, function (results, status): void {
      if (status === google.maps.GeocoderStatus.OK) {

        element.position.lat = results[0].geometry.location.lat();
        element.position.lng = results[0].geometry.location.lng();
      }
    });
  }

  searchModel(searchModel: any) {
    throw new Error('Method not implemented.');
  }

  public backClick(): void {
    this.jobsService.isVoirCLick = true;
    this.jobsService.mapButtonclicked = false;
    this.options.mapTypeId = google.maps.MapTypeId.ROADMAP;
    this.removeItineraire();
    if (this.plt.ANDROID || this.plt.IOS) {
      this.sharedService.navigationToDetails.next(true);
    }
  }

  get f(): any {
    return this.searchForm.controls;
  }

  searchFunction1(): void {
    console.log('searchFunction1');
    this.jobsList = this.totalJobList.filter((job) => job.jobName.includes(this.f.querry.value)
      || job.mission.includes(this.f.querry.value));
    this.categoryJobNumber = this.jobsList.length;
  }

  public saveToSearch(): void {
    this.jobsService.saveUserSearch({
      searchWord: this.f.querry.value, town: this.f.city.value, userId: this.authenticationService.userValue.id
    }).subscribe((data) => {
      this.snack.info('vos paramètres de recherches ont été enregistrés');
    }, (err) => {
      this.snack.error('erreur');
    });
  }
  public getCurrentLatLong(params): void {
    if (params === null) {
      if (this.plt.ANDROID || this.plt.IOS) {
        window.scrollTo(0, 0);
        navigator.geolocation.getCurrentPosition((position) => {
          this.location.lng = +position.coords.longitude;
          this.location.lat = +position.coords.latitude;
          this.location.marker.lat = +position.coords.latitude;
          this.location.marker.lng = +position.coords.longitude;
          this.currentLatitude = +position.coords.latitude;
          this.currentLongitude = +position.coords.longitude;
          this.markerOptions = { icon: 'assets/image/icon_localisation_map.svg' };
        }, (error) => { console.log({ error }); });
        this.sharedService.navigationToDetails.next(true);
      } else {
        window.scrollTo(0, 0);
        if (navigator) {
          navigator.geolocation.getCurrentPosition((pos) => {
            this.location.lng = +pos.coords.longitude;
            this.location.lat = +pos.coords.latitude;
            this.location.marker.lat = +pos.coords.latitude;
            this.location.marker.lng = +pos.coords.longitude;
            this.currentLatitude = +pos.coords.latitude;
            this.currentLongitude = +pos.coords.longitude;
            this.markerOptions = { icon: 'assets/image/icon_localisation_map.svg' };
          });
        }
        this.removeItineraire();
      }
    } else {
      if (this.plt.ANDROID || this.plt.IOS) {
        window.scrollTo(0, 0);
        navigator.geolocation.getCurrentPosition((position) => {
          this.location.lng = +position.coords.longitude;
          this.location.lat = +position.coords.latitude;
          this.location.marker.lat = +position.coords.latitude;
          this.location.marker.lng = +position.coords.longitude;
          this.currentLatitude = +position.coords.latitude;
          this.currentLongitude = +position.coords.longitude;
          this.markerOptions = { icon: 'assets/image/icon_localisation_map.svg' };
        }, (error) => { console.log({ error }); });
        this.sharedService.navigationToDetails.next(false);
      } else {
        window.scrollTo(0, 0);
        if (navigator) {
          navigator.geolocation.getCurrentPosition((pos) => {
            this.location.lng = +pos.coords.longitude;
            this.location.lat = +pos.coords.latitude;
            this.location.marker.lat = +pos.coords.latitude;
            this.location.marker.lng = +pos.coords.longitude;
            this.currentLatitude = +pos.coords.latitude;
            this.currentLongitude = +pos.coords.longitude;
            this.markerOptions = { icon: 'assets/image/icon_localisation_map.svg' };
          });
        }
        this.removeItineraire();
      }
    }
  }

  // public getLastJobsFromFilters(data: any): void {
  //   console.log(data);
  //   this.jobsList = data.lastJobs;
  //   this.totalJobs = data.totalJobs;
  //   this.displayActivePage(1);
  // }

  public showUrgentJob(e): void {
    e.map((job) => {
      this.getImgSize(this.imageBaseUrl + job.image).subscribe((data: any) => {
        if (data.height > data.width) {
          job.cardHeight = '320px';
        } else {
          job.cardHeight = '240px';
        }
      });
    });
    this.pageNumber = 1;
    this.totalJobList = e;
    this.paginatedJobs = this.totalJobList.slice((this.pageNumber - 1) * this.jobPerPage, this.jobPerPage * this.pageNumber);
    this.categoryJobNumber = this.totalJobList.length;
  }

  searchFunction(): void {
    /*const textareaElement1 = this.divs.find((div, i) => i === 0);
    const voiceHandler1 = textareaElement1.nativeElement.value;
    const textareaElement2 = this.divs.find((div, i) => i === 1);
    const voiceHandler2 = textareaElement2.nativeElement.value;
    this.searchForm.setValue({ querry: voiceHandler1.trim(), city: voiceHandler2.trim() });

    this.paginatedJobs = this.totalJobList.filter((job) =>
      (job.jobName.toLowerCase().includes(this.f.querry.value.toLowerCase()) ||
        job.companyName.toLowerCase().includes(this.f.querry.value.toLowerCase()))
      && job.address.toLowerCase().includes(this.f.city.value.toLowerCase()));
      // || job.mission.toLowerCase().includes(this.f.querry.value);
    this.categoryJobNumber = this.paginatedJobs.length;*/
  }

  public voiceSearch(objsIndex): void {
    this.speechToText.voicesearch(objsIndex);
    // voiceHandler.value = e.results[0][0].transcript;
  }

  // tslint:disable-next-line: adjacent-overload-signatures
  get divs(): QueryList<ElementRef<HTMLTextAreaElement>> {
    return this.speechToText.divs;
  }


  public playAudio(audioName: string, audioToPlay: string, job: any): void {
    this.audioFileService.addToPlayer(job);
    this.audioSource = audioToPlay;
    this.audioService.getAudioFile(audioName);
  }

  private getImgSize(imageSrc: string): Observable<ISize> {
    const mapLoadedImage = (event: any): ISize => {
      return {
        width: event.target.width,
        height: event.target.height
      };
    };
    const image = new Image();
    const $loadedImg = fromEvent(image, 'load').pipe(take(1), map(mapLoadedImage));
    image.src = imageSrc;
    return $loadedImg;
  }


  public getAllJobsByCategorie(clearresults:boolean = false): void {
    if(clearresults)
    {
      this.totalJobList = [];
      this.paginatedJobs = [];
    }
    var querry = this.f.querry.value
    var city = this.f.city.value
    this.isPreLoader = true;
    window.scrollTo(0, 0);
    this.pageNumber = 1;
    this.loadingJobs = true;
    this.jobCategoryService.getCategoryJobs(this.categoryId, {page: this.pageNumber}, querry,city).subscribe((res: any) => {
      this.handleJobsLoading(res);
    });
  }

  private handleJobsLoading({jobs, lastPage, totalJobs}): void {
      jobs.map((job) => {
        this.getImgSize(this.imageBaseUrl + job.image).subscribe((data: any) => {
          if (data.height > data.width) {
            job.cardHeight = '320px';
          } else {
            job.cardHeight = '240px';
          }
        });
      });
      this.lastPage = lastPage;
      this.totalJobList.push(...jobs);
      this.paginatedJobs.push(...jobs);
      this.categoryJobNumber = totalJobs;
      this.isPreLoader = false;
      this.loadingJobs = false;

  }

  public onScroll(): void {

    var st = window.pageYOffset || document.documentElement.scrollTop;
    let isScrollingBottom = false;
   if (st > this.lastScrollTop){
     let footerHeight: number = document.getElementsByTagName('footer')[0].clientHeight;
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight-footerHeight) {
      isScrollingBottom = true;
    }
   }
   this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

    var querry = this.f.querry.value
    var city = this.f.city.value

    if (this.loadingJobs || ( this.lastPage && this.pageNumber >= this.lastPage) || !isScrollingBottom) {
      return;
    }
    this.loadingJobs = true;
    this.isPreLoader = true;
    this.pageNumber++;
    this.jobCategoryService.getCategoryJobs(this.categoryId, {page: this.pageNumber}, querry,city).subscribe((res: any) => {
      this.handleJobsLoading(res);
    });

  }

  public abbJobToFavorite(jobId: number): void {
    if (this.authenticationService.userValue) {
      this.jobsService.addJobToFavorite(jobId, this.authenticationService.userValue.id).subscribe((data) => {
        this.snack.info('Job ajouté à vos favoris.');
      }, (err) => {
        console.log(err);
      });
    } else {
      this.snack.warn('Vous devez être connecté pour ajouter aux favoris.');
    }
  }

  public getCategories(): void {
    this.jobCategoryService.getJobCategories().subscribe(data => {
      this.jobCategories = data;
      // console.log(this.jobCategories);
    }, ignore => {
      this.jobCategories = [];
      console.log('error when loading jobCategories'); // TODO: change here by a fancy snack to display error
    });
  }

  public openShareDialog(job: Job): void {
    this.modalCustomService.open({ width: Size.SMAL }, SocialSharedComponent, job);
  }
}

interface ISize { width: number; height: number; }
