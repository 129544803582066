import { FormGroup, FormBuilder } from '@angular/forms';
import { Job } from './../../model/job';
import { environment } from './../../../environments/environment';
import { GoogleMap } from '@angular/google-maps';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JobCategoriesService } from 'src/app/service/jobCategories/job-categories.service';
import { JobsService } from 'src/app/service/jobs/jobs.service';
import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, asNativeElements } from '@angular/core';
import Shuffle from 'shufflejs';
import { JobCategory } from '../../model/jobCategory';
import { SpeechToTextService } from '../../service/speech-to-text/speech-to-text.service';
import { SnackService } from '../../service/snack/snack.service';
import { Observable, fromEvent } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { AudioService } from '../../service/audio.service';
import { AudioFileService } from '../../service/audiofile.service';
import { ModalCustomService } from '../../service/dialog/modal-custom.service';
import { Size } from '../../shared/const/const';
import { SocialSharedComponent } from '../../shared/social-shared/social-shared.component';
import { ItineraryOptionsService } from '../../service/itinerary-options/itinerary-options.service'
import { NgxMasonryOptions } from 'ngx-masonry';
import { Platform } from '@angular/cdk/platform';
import { SharedServiceService } from '../../shared/shared-service.service';
import { TextToSpeechService } from 'src/app/service/text-to-speech/text-to-speech.service';

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
interface Location {
  lat: number;
  lng: number;
  viewport?: Object;
  zoom: number;
  address_level_1?: string;
  address_level_2?: string;
  address_country?: string;
  address_zip?: string;
  address_state?: string;
  marker?: Marker;
  options: any;
  jobId: number;
}

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css', './../../../../node_modules/bootstrap/dist/css/bootstrap.css', '../../../app/shared/css/masonry.style.css', '../../shared/css/map-view.style.css', '../../../app/shared/css/job-list.style.css']
})
export class JobsComponent implements OnInit {
  public myOptions: NgxMasonryOptions = {
    // itemSelector: '.grid-item',
    columnWidth: 75,
    fitWidth: true,
    resize: true,
    itemSelector: '.grid-item'
  };
  public jobCategories: JobCategory[] = [];
  public searchForm: FormGroup;
  // @ViewChild('shuffleContainer') private shuffleContainer: ElementRef;
  // @ViewChild('shuffleSizer') private shuffleSizer: ElementRef;
  @ViewChild('GoogleMap', { static: false }) map: GoogleMap;
  @ViewChildren('div') set divs(value: QueryList<ElementRef<HTMLTextAreaElement>>) {
    // For lifecycle error, use timeout
    setTimeout(() => this.speechToText.divs = value);
    console.log(value);
  }
  public imageBaseUrl: string = environment.imageBaseUrl;
  activePage: number = 0;
  paginatedJobs: any[] = [];
  jobPerPage: number = 8;
  searchQuerryValue = '';
  searchCityValue = '';
  public array = [1, 2];
  private companyId: number;
  private pageNumber = 1;
  private lastPage = null;
  private loadingJobs = false;
  public markerIconClikedValue: number;
  public location: Location = {
    lat: 47.351861,
    lng: 0.6613099,
    marker: {
      lat: 47.351861,
      lng: 0.6613099,
      draggable: true
    },
    zoom: 5,
    options: {},
    jobId: null
  };
  public isAGMDirection = false;
  public origin: any;
  public destination: any;
  public renderOptions = {
    suppressMarkers: true,
    polylineOptions: { strokeColor: '#7b7c7c' }
  };
  public waypoints: any = [];
  public mapTransportMode: string = '';
  public mapDistanceDuration: string;
  private previous;
  public currentLatitude = 47.351861;
  public currentLongitude = 0.6613099;
  public showHeaderToMap: boolean = false;
  public isPreLoader = false;
  public lastScrollTop: number = 0;

  userConnected: any;
  zoom = 12;
  center = {
    lat: 47.351861,
    lng: 0.6613099
  };
  options: google.maps.MapOptions = {
    // mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };
  totalJobList: any[] = [];
  private backup: any[] = [];

  public totalJobs: number = 0;
  categoryId: number;
  category: any;
  markerOptions = { icon: '../../../../assets/image/icon_localisation_map.svg' };
  gridSection = true;
  listSection = false;
  constructor(
    public jobsService: JobsService,
    private readonly jobCategorieService: JobCategoriesService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private speechToText: SpeechToTextService,
    private snack: SnackService,
    private audioService: AudioService,
    private audioFileService: AudioFileService,
    private modalCustomService: ModalCustomService,
    private router: Router,
    public itineraryOptionsService: ItineraryOptionsService,
    private plt: Platform,
    private sharedService: SharedServiceService,
    private textToSpeech: TextToSpeechService,
  ) {
    this.searchForm = this.formBuilder.group({
      querry: [''],
      city: [''],
      querryMap: [''],
    });
    this.authenticationService.user.subscribe(x => this.userConnected = x);
    this.activatedRoute.queryParams.subscribe((data) => {
      this.searchQuerryValue = data?.querry;
      this.searchCityValue = data?.city;
      this.companyId = data?.compagnyId;

      if (data.map !== undefined) {
        this.jobsService.isVoirCLick = data.map === 'true' ? true : false;
      }
      this.searchForm?.setValue({ querry: this.searchQuerryValue, city: this.searchCityValue, querryMap: this.searchQuerryValue });

      // this.searchFunction();
    });
    // this.options.mapTypeId = google.maps.MapTypeId.ROADMAP;
  }

  public voiceSearch(objsIndex): void {
    this.speechToText.voicesearch(objsIndex);
  }

  // tslint:disable-next-line: adjacent-overload-signatures
  get divs(): QueryList<ElementRef<HTMLTextAreaElement>> {
    return this.speechToText.divs;
  }



  public gridFunction(): void {
    this.gridSection = true;
    this.listSection = false;
  }
  public listFunction(): void {
    this.listSection = true;
    this.gridSection = false;
  }

  ngOnInit(): void {
    this.location.marker.draggable = true;
    this.categoryId = +this.activatedRoute.snapshot.params.job_category_id;
    this.getCategories();
    this.getAllJobs();
    if (this.plt.IOS || this.plt.ANDROID) {
      this.showHeaderToMap = false;
    } else {
      this.showHeaderToMap = true;
    }
    this.getCurrentLatLong(null);
    this.jobsService.isVoirCLick = true;
  }

  public updateMapView(job: any): void {
    this.location.lat = job.options.lat;
    this.location.lng = job.options.lng;
    this.location.zoom = 20;
  }

  // Map Click Marker
  public clickedMarker(infowindow: any) {
    this.isAGMDirection = false;

    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;

  }
  public removeItineraire(): void {
    this.isAGMDirection = false;
    this.mapTransportMode = '';
    if (this.previous) {
      this.previous.close();
    }
  }

  public getDirection(destinationLatLong: any) {
    this.isAGMDirection = true;
    this.origin = { lat: this.currentLatitude, lng: this.currentLongitude };
    this.destination = { lat: destinationLatLong.options.lat, lng: destinationLatLong.options.lng };
    // this.origin = { lat: 24.799448, lng: 120.979021 };
    // this.destination = { lat: 24.799524, lng: 120.975017 };
    this.getVehicle('DRIVING');
  }
  public getVehicle(transportMode) {
    this.itineraryOptionsService.getVehicle(transportMode, this.currentLatitude, this.currentLongitude, this.destination.lat, this.destination.lng).then(data => {
      this.mapTransportMode = transportMode;
      this.mapDistanceDuration = data;
    });
  }
  public focusOneJobs(id: number): void {
    this.markerIconClikedValue = id;
  }
  public click(e): void {
    console.log(e);
  }

  public voirClick(): void {
    this.jobsService.isVoirCLick = false;
  }

  public backClick(): void {
    this.jobsService.isVoirCLick = true;
    this.jobsService.mapButtonclicked = false;
    this.options.mapTypeId = google.maps.MapTypeId.ROADMAP;
    this.removeItineraire();
    if (this.plt.ANDROID || this.plt.IOS) {
      this.sharedService.navigationToDetails.next(true);
    }
  }

  get f(): any {
    return this.searchForm.controls;
  }

  searchFunction(): void {
    const textareaElement1 = this.divs.find((div, i) => i === 0);
    const voiceHandler1 = textareaElement1.nativeElement.value;

    const textareaElement2 = this.divs.find((div, i) => i === 1);
    const voiceHandler2 = textareaElement2.nativeElement.value;

    const textareaElement3 = this.divs.find((div, i) => i === 2);

    const voiceHandler3 = textareaElement3 === undefined ? '' : textareaElement3.nativeElement.value;
    voiceHandler3 === '' ? this.searchForm.setValue({
      querry: voiceHandler1.trim(),
      city: voiceHandler2.trim(), querryMap: voiceHandler1.trim()
    }) : this.searchForm.setValue({ querry: voiceHandler3.trim(), city: voiceHandler2.trim(), querryMap: voiceHandler3.trim() });
    this.pageNumber = 1;
    this.paginatedJobs = [];
    this.totalJobList = [];
    this.loadJobs();
  }

  public getCategories(): void {
    this.jobCategorieService.getJobCategories().subscribe(data => {
      this.jobCategories = data;
    }, ignore => {
      this.jobCategories = [];
      // this.snack.error('error when loading jobCategories');
    });
  }

  public saveToSearch(): void {
    this.jobsService.saveUserSearch({
      searchWord: this.f.querry.value, town: this.f.city.value, userId: this.authenticationService.userValue.id
    }).subscribe((data) => {
      this.snack.info('vos paramètres de recherches ont été enregistrés');
    }, (err) => {
      this.snack.error('erreur');
    });
  }

  // public getLastJobsFromFilters(data: any): void {
  //   this.pageNumber = 1;
  //   console.log(data);
  //   this.totalJobList = data.lastJobs;
  //   this.totalJobs = data.totalJobs;
  //   // this.displayActivePage(1);
  // }

  public showUrgentJob(e): void {
    console.log('showUrgentJob');
    e.map((job) => {
      this.getImgSize(this.imageBaseUrl + job.image).subscribe((data: any) => {
        if (data.height > data.width) {
          job.cardHeight = '320px';
        } else {
          job.cardHeight = '240px';
        }
      });
    });
    if (this.companyId !== undefined) {
      this.totalJobList = e.filter((data) => Number(data.companyId) === Number(this.companyId));
      this.totalJobs = this.totalJobList.length;
    } else {
      this.totalJobList = e;
      this.pageNumber = 1;
      this.paginatedJobs = this.totalJobList.slice((this.pageNumber - 1) * this.jobPerPage, this.jobPerPage * this.pageNumber);
      console.log(this.paginatedJobs);
      this.totalJobs = this.totalJobList.length;
      // this.totalJobs = this.totalJobList.length;
      // this.searchFunction();
    }

  }

  public getCurrentLatLong(params): void {

    if (params === null) {
      if (this.plt.ANDROID || this.plt.IOS) {
        window.scrollTo(0, 0);
        navigator.geolocation.getCurrentPosition((position) => {
          this.location.lng = +position.coords.longitude;
          this.location.lat = +position.coords.latitude;
          this.location.marker.lat = +position.coords.latitude;
          this.location.marker.lng = +position.coords.longitude;
          this.currentLatitude = +position.coords.latitude;
          this.currentLongitude = +position.coords.longitude;
          this.markerOptions = { icon: 'assets/image/icon_localisation_map.svg' };
        }, (error) => { console.log({ error }); });
        this.sharedService.navigationToDetails.next(true);
        // navigator.geolocation.getCurrentPosition(onSuccess, (error) => { console.log({error})});
      } else {
        if (navigator) {
          navigator.geolocation.getCurrentPosition((pos) => {
            this.location.lng = +pos.coords.longitude;
            this.location.lat = +pos.coords.latitude;
            this.location.marker.lat = +pos.coords.latitude;
            this.location.marker.lng = +pos.coords.longitude;
            this.currentLatitude = +pos.coords.latitude;
            this.currentLongitude = +pos.coords.longitude;
            this.markerOptions = { icon: 'assets/image/icon_localisation_map.svg' };
          });
        }
        this.removeItineraire();
      }
    } else {
      if (this.plt.ANDROID || this.plt.IOS) {
        window.scrollTo(0, 0);
        navigator.geolocation.getCurrentPosition((position) => {
          this.location.lng = +position.coords.longitude;
          this.location.lat = +position.coords.latitude;
          this.location.marker.lat = +position.coords.latitude;
          this.location.marker.lng = +position.coords.longitude;
          this.currentLatitude = +position.coords.latitude;
          this.currentLongitude = +position.coords.longitude;
          this.markerOptions = { icon: 'assets/image/icon_localisation_map.svg' };
        }, (error) => { console.log({ error }); });
        this.sharedService.navigationToDetails.next(false);
        // navigator.geolocation.getCurrentPosition(onSuccess, (error) => { console.log({error})});
      } else {
        if (navigator) {
          navigator.geolocation.getCurrentPosition((pos) => {
            this.location.lng = +pos.coords.longitude;
            this.location.lat = +pos.coords.latitude;
            this.location.marker.lat = +pos.coords.latitude;
            this.location.marker.lng = +pos.coords.longitude;
            this.currentLatitude = +pos.coords.latitude;
            this.currentLongitude = +pos.coords.longitude;
            this.markerOptions = { icon: 'assets/image/icon_localisation_map.svg' };
          });
        }
        this.removeItineraire();
      }
    }

    this.voirClick();
  }

  private getImgSize(imageSrc: string): Observable<ISize> {
    // console.log(imageSrc);
    const mapLoadedImage = (event: any): ISize => {
      return {
        width: event.target.width,
        height: event.target.height
      };
    };
    const image = new Image();
    const $loadedImg = fromEvent(image, 'load').pipe(take(1), map(mapLoadedImage));
    image.src = imageSrc;
    return $loadedImg;
  }
  public alreadyApplied() {
    this.snack.info("Vous avez déjà postulé à ce Job");
    this.textToSpeech.play("Vous avez déjà postulé à ce Job")
    return false;
  }

  public abbJobToFavorite(jobId: number, jobFavorite): void {
    if (this.authenticationService.userValue) {
      if (jobFavorite == 1) {
        this.snack.warn("Déjà ajouté à vos favoris");
      } else {
        this.jobsService.addJobToFavorite(jobId, this.authenticationService.userValue.id).subscribe((data) => {
          this.snack.info('Job ajouté à vos favoris');
        }, (err) => {
          console.log(err);
        });
      }
    } else {
      this.snack.warn('Vous devez être connecté pour ajouter aux favoris');
    }
  }

  public playAudio(audioName: string, audioToPlay: string, job: any): void {
    console.log(job);
    this.audioFileService.addToPlayer(job);
    // this.audioSource = audioToPlay;
    console.log(job.audio);
    this.audioService.getAudioFile(audioName);
  }

  public onScroll(): void {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    let isScrollingBottom = false;
   if (st > this.lastScrollTop){
     let footerHeight: number = document.getElementsByTagName('footer')[0].clientHeight;
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight-footerHeight) {
      isScrollingBottom = true;
    }
   }
   this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling


    if (this.loadingJobs || ( this.lastPage && this.pageNumber >= this.lastPage) || !isScrollingBottom) {
      return;
    }
    this.pageNumber++;
    this.isPreLoader = true;
    this.loadJobs();
  }

  private loadJobs(): void {
    const userId = this.authenticationService.userValue === null
                      ? null : this.authenticationService.userValue.id;
    this.loadingJobs = true;
    if (this.f.querry.value !== '' || this.f.city.value !== '') {
      this.jobsService.searchJobs(userId, {city: this.f.city.value, query: this.f.querry.value }, {page: this.pageNumber})
        .subscribe((res: any) => {
          this.handleJobsLoading(res);
        });
    } else {
      this.jobsService.getJobs(userId, {page: this.pageNumber}).subscribe((res: any) => {
        this.handleJobsLoading(res);
        // this.searchFunction();
      });
    }
  }

  private handleJobsLoading({jobs, lastPage, totalJobs}): void {
    jobs.map((job) => {
      this.getImgSize(this.imageBaseUrl + job.image).subscribe((data: any) => {
        if (data.height > data.width) {
          job.cardHeight = '320px';
        } else {
          job.cardHeight = '240px';
        }
      });
    });
    console.log({jobs});
    this.lastPage = lastPage;
    this.totalJobList.push(...jobs);
    this.paginatedJobs.push(...jobs);
    this.totalJobs = totalJobs;
    if (this.companyId !== undefined) {
      this.totalJobList = this.totalJobList.filter((data) => Number(data.companyId) === Number(this.companyId));
      this.totalJobs = this.totalJobList.length;
    }
    this.loadingJobs = false;
    this.isPreLoader = false;
  }

  public getAllJobs(): void {
    window.scrollTo(0, 0);
    this.pageNumber = 1;
    const userId = this.authenticationService.userValue === null
                      ? null : this.authenticationService.userValue.id;
    this.loadingJobs = true;
    this.isPreLoader = true;
    this.loadJobs();
  }

  public openShareDialog(job: Job): void {
    this.modalCustomService.open({ width: Size.SMAL }, SocialSharedComponent, job);
  }
}


interface ISize { width: number; height: number; }
