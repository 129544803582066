import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../../service/user/user.service';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../service/snack/snack.service';
import {AuthenticationService} from '../../../service/authentication/authentication.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  public resetForm: FormGroup;
  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private dialogRef: MatDialogRef<PasswordResetComponent>,
              private snack: SnackService,
  ) { }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      phone: ['', Validators.compose([Validators.required, Validators.pattern('^((\\+)33|0)[1-9](\\d{2}){4}$')])],
      email: ['', [Validators.email]]
    });
  }


  public submit(): any {
    console.log(this.resetForm.value.phone);
    this.resetForm.value.phone.toString().replace(/ /g, '');

    this.authenticationService.resetUserPassword(this.resetForm.value).subscribe((d) => {
      this.dialogRef.close();
      this.snack.info('Veuillez consulter le SMS qui vous  a été envoyé');
    }, (e) => {
      this.snack.error('une erreur s\'est produite lors de la réitialisation de votre mot de passe');
    });
  }

}
