import { Component, OnInit, Inject } from '@angular/core';
import { DialogData } from '../job-questions/job-questions.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'app-job-image-suggession-component',
  templateUrl: './job-image-suggession-component.component.html',
  styleUrls: ['./job-image-suggession-component.component.css']
})
export class JobImageSuggessionComponentComponent implements OnInit {
  imageUrls: string[] = [];
  public imageBaseUrl: string = environment.imageBaseUrl;
  imageId: number;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<JobImageSuggessionComponentComponent>) { }

  ngOnInit(): void {
    console.log(this.data);
    this.buildImageUrl();
  }

  private buildImageUrl(): void {
    for (let index = 1; index < 4; index++) {
      const element = this.imageBaseUrl + 'categoriesImages/' + this.data + '_' + index + '.jpeg';
      this.imageUrls.push(element);
    }
  }

  public submit(): void {
    const element = this.imageUrls[this.imageId];
    this.dialogRef.close(element);
  }

  public activateClass(index: number): void {
    this.imageId = index;
  }

}
