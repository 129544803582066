<div style="background: white">
  <div fxLayout="row nowrap" fxFlexFill fxLayoutAlign="space-between stretch" class="cgu-header">
    <div class="navbar-title" fxFlex="1 1" fxFlexFill>
      <h1> Politique sur les Données Personnelles </h1>
    </div>
  </div>
  <div style="margin: 0 10px 0 30px;">
    <p>En visitant notre site Web et en utilisant nos services, vous comprenez et acceptez la fa&ccedil;on dont nous traitons les donn&eacute;es personnelles conform&eacute;ment &agrave; notre politique de confidentialit&eacute;. Nous respectons votre vie priv&eacute;e et le droit de contr&ocirc;ler vos donn&eacute;es personnelles. Nos principes directeurs sont simples. Nous allons &ecirc;tre ouverts avec quelles donn&eacute;es nous collectons et pourquoi. Veuillez prendre un moment pour les lire. C'est important !</p>
    <h3><strong>Politique de confidentialit&eacute; et cookies</strong></h3>
    <p>RECADE EMPLOYMENT SOLUTIONS respecte votre vie priv&eacute;e et le droit de contr&ocirc;ler vos donn&eacute;es personnelles. Nos principes directeurs sont simples. Nous serons ouverts &agrave; vous informer sur les donn&eacute;es que nous collectons et pourquoi. Nous prot&eacute;geons vos donn&eacute;es comme si c'&eacute;tait notre plus grand secret commercial.</p>
    <p>La politique de confidentialit&eacute; de RECADE EMPLOYMENT SOLUTIONS ("Soci&eacute;t&eacute;") d&eacute;crit quelles donn&eacute;es nous collectons, le but du traitement, comment vous pouvez contr&ocirc;ler vos propres donn&eacute;es et comment nous contacter. La pr&eacute;sente politique de confidentialit&eacute; s'applique lorsque vous utilisez des applications, des pages Web et des services qui sont inclus dans une application ou sur un site Web g&eacute;r&eacute; par la Soci&eacute;t&eacute;. Le DPO de RECADE EMPLOYMENT SOLUTIONS est une personne personnellement responsable conform&eacute;ment &agrave; ces conditions et est responsable du traitement conform&eacute;ment &agrave; la l&eacute;gislation en vigueur.</p>
    <p>Voici un r&eacute;sum&eacute; de ce &agrave; quoi vous pouvez vous attendre en visitant notre site Web:</p>
    <ul>
      <li>Nous suivons votre activit&eacute; web &agrave; des fins de marketing</li>
      <li>Nous recueillons des renseignements personnels &agrave; des fins de marketing et de communication.</li>
      <li>Nous traitons les renseignements personnels en les envoyant &agrave; des syst&egrave;mes int&eacute;gr&eacute;s de courriel et de gestion de la relation client (CRM) &agrave; des fins de marketing et de relations clients.</li>
      <li>Nous sauvegardons les informations personnelles dans nos bases &agrave; des fins d'analyse.</li>
      <li>Votre comportement et votre activit&eacute; sur le Web peuvent &ecirc;tre utilis&eacute;s &agrave; des fins de marketing comme le ciblage et l'analyse marketing.</li>
    </ul>
    <p>Quelles donn&eacute;es personnelles collectons-nous?</p>
    <p>RECADE EMPLOYMENT SOLUTIONS recueille les informations principalement aupr&egrave;s de vous en tant que client. Des exemples de donn&eacute;es personnelles que nous pourrions collecter sont le nom de votre entreprise, votre nom et d'autres d&eacute;tails de contact tels que le num&eacute;ro de t&eacute;l&eacute;phone, l'adresse e-mail ou l'adresse physique. RECADE EMPLOYMENT SOLUTIONS peut mettre &agrave; jour vos informations &agrave; partir d'autres dossiers publics si cela est n&eacute;cessaire pour maintenir un bon entretien du r&eacute;f&eacute;rentiel de donn&eacute;e.</p>
    <p>RECADE EMPLOYMENT SOLUTIONS enregistre les donn&eacute;es personnelles en relation avec:</p>
    <ol>
      <li>a) Notification d'un service offert par le biais des diff&eacute;rentes plateformes offertes par la Soci&eacute;t&eacute;.</li>
      <li>b) Lorsque vous entrez votre adresse e-mail ou votre num&eacute;ro de t&eacute;l&eacute;phone portable sur le site.</li>
      <li>d) Si vous nous contactez par courriel, par t&eacute;l&eacute;phone, par nos sites Web et par les m&eacute;dias sociaux.</li>
      <li>e) Si vous vous inscrivez &agrave; un abonnement par courriel.</li>
      <li>f) Votre visite de sur nos sites Web et lors de l'acc&egrave;s &agrave; notre application, y compris, mais sans s' y limiter, les donn&eacute;es de trafic, les donn&eacute;es de localisation, les blogs et d'autres donn&eacute;es de communication, telles que l'adresse IP, le type de p&eacute;riph&eacute;rique, le syst&egrave;me d'exploitation et le type de navigateur utilis&eacute; pour la visite.</li>
    </ol>
    <p>Collecte avec consentement</p>
    <p>En cliquant sur le bouton "Fermer", vous consentez &agrave; ce que les donn&eacute;es personnelles (soci&eacute;t&eacute;, nom, num&eacute;ro de t&eacute;l&eacute;phone et adresse &eacute;lectronique) que vous entrez dans des formulaires &agrave; diff&eacute;rents endroits sur ce site puissent &ecirc;tre utilis&eacute;es &agrave; des fins de marketing pour les services auxquels vous &ecirc;tes expos&eacute; lorsque vous &ecirc;tes invit&eacute; &agrave; remplir vos informations personnelles. Nous recueillons &eacute;galement votre adresse IP, votre position g&eacute;ographique et vos int&eacute;r&ecirc;ts afin de mieux adapter notre marketing &agrave; vos besoins.</p>
    <p>En cliquant sur le bouton "Fermer", vous acceptez &eacute;galement que nous puissions ajouter un cookie tiers sur votre ordinateur &agrave; partir de la Soci&eacute;t&eacute;. Ce cookie vous suivra, ainsi que votre comportement en ligne sur d'autres sites Web, afin de vous proposer du marketing bas&eacute; sur votre comportement en ligne.</p>
    <p>Comment nous utilisons les informations collect&eacute;es</p>
    <p>La Soci&eacute;t&eacute; traite les donn&eacute;es personnelles principalement aux fins indiqu&eacute;es ci-dessous et &agrave; toute autre fin mentionn&eacute;e lors de la collecte:</p>
    <ul>
      <li>Ex&eacute;cuter les fonctionnalit&eacute;s &agrave; travers l'une des plateformes offertes par la Soci&eacute;t&eacute;;</li>
      <li>Permettre un bon service &agrave; la client&egrave;le, comme la gestion de vos demandes, la correction d'informations incorrectes ou l'envoi d'informations que vous avez demand&eacute;es, par exemple la newsletter, des livres blancs, de la documentation;</li>
      <li>Pour g&eacute;rer les profils clients, r&eacute;aliser des analyses et des &eacute;tudes de march&eacute;;</li>
      <li>Pour l'administration du syst&egrave;me et pour obtenir des donn&eacute;es statistiques sur le comportement et les usages de nos utilisateurs;</li>
      <li>Pour d&eacute;velopper, livrer et am&eacute;liorer nos produits et services par l'analyse de votre comportement sur nos sites web et nos applications.</li>
      <li>Les donn&eacute;es personnelles sont &eacute;galement utilis&eacute;es &agrave; des fins d'analyse pour r&eacute;aliser des segmentations et ciblages, afin de d&eacute;velopper des offres et des services personalis&eacute;s.</li>
      <li>Pour vous envoyer des informations et des promotions par SMS, email, app, push ou d'autres voies de contact num&eacute;riques telles que les m&eacute;dias sociaux puisque vous avez une relation client active avec nous;</li>
      <li>Pour vous contacter via l'application, SMS, email ou courrier sur d'autres offres, promotions ou services que nous pensons &ecirc;tre d'int&eacute;r&ecirc;t pour vous, veuillez noter que vous pouvez refuser cette promotion &agrave; tout moment;</li>
      <li>Vous fournir des recommandations pertinentes, des offres et des services personnalis&eacute;s bas&eacute;s sur les besoins d'autres personnes ayant des comportements similaires aux v&ocirc;tres ;</li>
      <li>Pour nous aider &agrave; d&eacute;velopper notre site et notre application afin d'&ecirc;tre plus utiles et d'am&eacute;liorer votre exp&eacute;rience utilisateur des services offerts par la Soci&eacute;t&eacute; en personnalisant l'affichage des services &agrave; votre profil;</li>
      <li>Envoyer des messages importants en tant que communication sur les changements dans nos mentions l&eacute;gales;</li>
    </ul>
    <p>Nous pouvons partager des informations avec d'autres soci&eacute;t&eacute;s qui traitent des donn&eacute;es en notre nom afin d'ex&eacute;cuter nos services, c'est-&agrave;-dire l'analyse, la distribution ou d'autres services pour maintenir et appliquer nos conditions d'utilisation et de livraison. Cependant, le traitement des donn&eacute;es personnelles garantit toujours une s&eacute;curit&eacute; et une confidentialit&eacute; &eacute;lev&eacute;es.</p>
    <p>La soci&eacute;t&eacute; ne collabore qu'avec des partenaires qui traitent des donn&eacute;es &agrave; caract&egrave;re personnel au sein de l'UE/EEE ou avec des entreprises qui maintiennent le m&ecirc;me niveau de protection que dans l'UE/EEE, par exemple en adh&eacute;rant &agrave; ce que l'on appelle le Privacy Shield Agreement entre l'UE et les &Eacute;tats-Unis.</p>
    <p>&Agrave; propos des cookies</p>
    <p>Lorsque vous utilisez notre site et notre application, des donn&eacute;es personnelles peuvent &ecirc;tre collect&eacute;es par le biais de cookies. Cela ne peut se faire qu'avec un consentement distinct. Ceci stockera les informations sur votre utilisation et les pages visit&eacute;es. Il peut s'agir d'informations techniques sur votre appareil et votre connexion Internet telles que le syst&egrave;me d'exploitation, la version du navigateur, l'adresse IP, les cookies et les identifiants uniques. Lorsque vous visitez nos sites Web o&ugrave; nos services sont pr&eacute;sent&eacute;s, diff&eacute;rentes techniques peuvent &ecirc;tre utilis&eacute;es pour vous reconna&icirc;tre afin d'en apprendre davantage sur nos utilisateurs. Cela peut se faire directement ou au moyen d'une technologie tierce.</p>
    <p>Droit &agrave; l'oubli, stockage et divulgation des donn&eacute;es personnelles</p>
    <p>Vous pouvez &agrave; tout moment retirer votre consentement en nous en faisant la demande via la fonction "nous contacter"</p>
    <p>Le traitement est conforme &agrave; la l&eacute;gislation en vigueur et signifie que les donn&eacute;es personnelles ne sont pas conserv&eacute;es pendant une p&eacute;riode plus longue que n&eacute;cessaire aux fins du traitement. La Soci&eacute;t&eacute; conservera vos donn&eacute;es personnelles tant que vous serez en relation commerciale avec nous. A des fins de marketing, les informations datant de plus de 4 ans ne sont pas utilis&eacute;es. Cela implique que les donn&eacute;es seront effac&eacute;es lorsqu'elles ne sont plus pertinentes ou n&eacute;cessaires &agrave; des fins d'analyse ou de marketing direct aux fins pour lesquelles elles ont &eacute;t&eacute; collect&eacute;es. Certaines informations peuvent &ecirc;tre conserv&eacute;es plus longtemps au besoin en raison d'autres exigences l&eacute;gales. Cependant, le traitement des donn&eacute;es personnelles garantit toujours une s&eacute;curit&eacute; et une confidentialit&eacute; &eacute;lev&eacute;es.</p>
    <p>Vos droits et vos choix</p>
    <p>Vous avez des droits sur vos donn&eacute;es personnelles et vous pouvez demander la modification ou destruction de vos donn&eacute;es sauvegard&eacute;es. La Soci&eacute;t&eacute; rectifiera, de sa propre initiative ou &agrave; l'initiative du client, les donn&eacute;es jug&eacute;es incorrectes. Vous pouvez &eacute;galement demander &agrave; tout moment que vos donn&eacute;es soient effac&eacute;es ou que leur utilisation soit limit&eacute;e en nous contactant (voir la rubrique "Comment nous contacter"). Si vous pensez que nous ne respectons pas vos droits, veuillez contacter la Soci&eacute;t&eacute; ou contacter l'Autorit&eacute; de Protection des Donn&eacute;es de votre pays, &agrave; http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_fr. htm.</p>
    <p>Vous pouvez &agrave; tout moment vous abstenir de recevoir des communications marketing de notre part en mettant &agrave; jour les param&egrave;tres de votre compte en cliquant sur "Se d&eacute;sinscrire" dans l' e-mail et les communications SMS que nous vous envoyons. Vous &ecirc;tes toujours les bienvenus pour contacter notre service client&egrave;le pour vous aider &agrave; stopper nos communications.</p>
    <p>Liens vers d'autres sites</p>
    <p>Dans le cas o&ugrave; notre site contient des liens vers des sites Web de tiers, des sites Web ou des documents publi&eacute;s par des tiers, ces liens sont fournis &agrave; titre d'information seulement. &Eacute;tant donn&eacute; que la Soci&eacute;t&eacute; n' a aucun contr&ocirc;le sur le contenu de ces sites Web ou de son mat&eacute;riel, nous ne sommes pas responsables de leur contenu. La Soci&eacute;t&eacute; n'est pas non plus responsable des dommages ou pertes qui pourraient r&eacute;sulter de l'utilisation de ces liens.</p>
    <p>Comment nous contacter</p>
    <p>Pour plus d'informations sur la gestion des donn&eacute;es personnelles ou si vous avez des questions, n'h&eacute;sitez pas &agrave; nous contacter:</p>
    <p>RECADE EMPLOYMENT SOLUTIONS - OTAF, 30 RUE PIERRE DUHEM 33000 BORDEAUX | contact@gotaf.fr</p>
    <p>Cette politique de confidentialit&eacute; s'applique &agrave; partir du 01/05/2021</p>
  </div>
</div>
<br>
<mat-toolbar-row fxLayout="row wrap" fxLayoutAlign="space-evenly center" class="footer-toolbar-row" id="footerNav">
  <div class="row" fxLayout="row wrap">
    <div class="left-navbar-bloc" style="text-align: left" fxFlex>
      <span> Nous croyons dans le potentiel de chacun<br>Retrouvez-nous sur les réseaux sociaux</span>
    </div>
  </div>
  <span class="navbar-spacer"></span>
  <div class="row" fxLayout="row wrap">
    <div class="right-navbar-bloc" fxFlex fxLayoutAlign="flex-end center">
      <a target="_blank" href="https://www.facebook.com/gotafemploi" style="text-decoration: none;">
        <mat-icon svgIcon="icon_facebook"></mat-icon>
      </a>
      <a target="_blank" href="https://www.instagram.com/gotafemplois/" style="text-decoration: none;">
        <mat-icon svgIcon="icon_instagram"></mat-icon>
      </a>
      <a target="_blank" href="https://youtu.be/I5WrwtfknxE"
         style="text-decoration: none;">
        <mat-icon svgIcon="icon_youtube"></mat-icon>
      </a>
      <a target="_blank" href="https://www.tiktok.com/@gotaf21?lang=fr" style="text-decoration: none;">
        <mat-icon svgIcon="icon_tiktok"></mat-icon>
      </a>
      <a target="_blank" href="https://www.twitch.tv/gotaf21" style="text-decoration: none;">
        <mat-icon svgIcon="icon_twitch"></mat-icon>
      </a>
      <a target="_blank" href="https://www.linkedin.com/company/gotaf" style="text-decoration: none;">
        <mat-icon svgIcon="icon_linkedin"></mat-icon>
      </a>
    </div>
  </div>
</mat-toolbar-row>
