import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-search',
  templateUrl: './category-search.component.html',
  styleUrls: ['./category-search.component.css', './../../../../../node_modules/bootstrap/dist/css/bootstrap.css']
})
export class CategorySearchComponent implements OnInit {
  @Input() icon: string;
  @Input() name: string;
  @Input() audioUrl: string;

  constructor() { }

  ngOnInit(): void {
  }

}
