import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import Speech from 'speak-tts'; // es6
// import { TNSTextToSpeech, SpeakOptions } from 'nativescript-texttospeech';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { Platform } from '@angular/cdk/platform';

function _window(): any {
  return window;
}
@Injectable({
  providedIn: 'root'
})
export class TextToSpeechService {
  private deviceInfo = null;
  speech: any;
  onEndCallbacks = [];
  // private tts = new TNSTextToSpeech();
  // speakOptions: SpeakOptions = {
  //   text: '', /// *** required ***
  //   speakRate: 0.5, // optional - default is 1.0
  //   pitch: 1.0, // optional - default is 1.0
  //   volume: 1.0, // optional - default is 1.0
  //   locale: 'fr-FR', // optional - default is system locale,
  //   finishedCallback: Function // optional
  // };

  constructor(private deviceService: DeviceDetectorService, private tts: TextToSpeech, private plt: Platform) {
    this.speech = new Speech();
    this.speech.setLanguage('fr-FR');
    // will throw an exception if not browser supported
    if (this.speech.hasBrowserSupport()) { // returns a boolean
      // console.log('speech synthesis supported');
      this.speech.init({
        volume: 1,
        lang: 'fr-FR',
        rate: 1,
        pitch: 1,
        voice: 'Thomas',
        splitSentences: true,
        listeners: {
          onvoiceschanged: (voices) => {
            // console.log('Event voiceschanged', voices);
          }
        }
      }).then((data) => {
        // The "data" object contains the list of available voices and the voice synthesis params
        // console.log('Speech is ready, voices are available', data);
      }).catch(e => {
        // console.error('An error occured while initializing : ', e);
      });
    }
  }

  onEnd(callback) {
    this.onEndCallbacks.push(callback);
  }

  play(texte: string): any {
    if ((this.plt.IOS ) && (this.plt.BLINK == false && this.plt.EDGE == false && this.plt.FIREFOX == false && this.plt.SAFARI == false && this.plt.TRIDENT == false)) {
    // if (this.plt.IOS ) {
      _window().TTS
        .speak({
          text: texte,
          locale: 'fr-FR',
          rate: 1.6,
          voice: 'Thomas',
        }, (success) => { }, (error) => { });
    } else if ((this.plt.ANDROID ) && (this.plt.BLINK == false && this.plt.EDGE == false && this.plt.FIREFOX == false && this.plt.SAFARI == false && this.plt.TRIDENT == false)) {
      _window().TTS
        .speak({
          text: texte,
          locale: 'fr-FR',
          rate: 0.8,
          voice: 'Thomas',
        }, (success) => { }, (error) => { });
    } else {
      this.speech.pause();
      this.speech.speak({
        text: texte,
        queue: false, // current speech will be interrupted,
        listeners: {
          onstart: () => {
          },
          onend: () => {
            this.onEndCallbacks.forEach(c => c());
          },
          onresume: () => {
          },
          onboundary: (event) => {
          }
        }
      }).then(() => {
        // console.log('Success !');
      }).catch(e => {
        // console.error('An error occurred :', e);
      });
    }
  }

  pause() {
    this.speech.pause();
  }
}
