import { UserService } from '../../../service/user/user.service';
import { environment } from '../../../../environments/environment.prod';
import { User } from '../../../model/user';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { AudioFileService } from '../../../service/audiofile.service';
import { AudioService } from '../../../service/audio.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JobCategory } from '../../../model/jobCategory';
import { JobCategoriesService } from '../../../service/jobCategories/job-categories.service';
import { SnackService } from '../../../service/snack/snack.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import { Role } from '../../../model/role';

@Component({
  selector: 'app-company-profile-candidate',
  templateUrl: './company-profile-candidate.component.html',
  styleUrls: ['./company-profile-candidate.component.css']
})
export class CompanyProfileCandidateComponent implements OnInit {


  public user =
    {
      id: 1, companyId: 'Jean-Muriel Azonhoume 1', username: 'Mon offre 1', firstName: 'Mon ',
      lastName: 'offre', email: 'Monoffre@gmail.com', phone: '988574969552', image: 'votre-offre.jpg',
      address: 'Gopal Palace', experience: '97'
    };
  public imageBaseUrl: string = environment.imageBaseUrl;
  public userResume;
  public audioSource: string;
  public categories: any[] = [];
  public cvForm: FormGroup;
  public audioFile: File;
  public chart;
  public yAxe = [];
  public xAxe = [];
  private experience: any[] = [];
  data: any[] = [];
  dataset: any[] = [];

  public jobCategories: JobCategory[] = [];
  public experiencesLevel: any[] = [];
  public qualification;
  public experienceSelect;
  public changeAudio = false;
  selectedIndex: number[] = [];
  public selectedCategorie: any[] = [];
  public changeCategorie = false;
  public changeExperience = false;
  public candidatId: number;
  public showPhone = false;

  constructor(
    private authenticationService: AuthenticationService,
    private userProfileService: UserService,
    private audioService: AudioService,
    private audioFileService: AudioFileService,
    private formBuilder: FormBuilder,
    private jobCategoriesService: JobCategoriesService,
    private snack: SnackService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.candidatId = this.route.snapshot.params.userId;
    console.log(this.candidatId);
    this.getUserResume(this.candidatId);
  }

  ngOnInit(): void {
    // this.getUserResume(this.candidatId);
    // console.log(this.candidatId);
  }


  public playAudio(audioToPlay: string): void {
    // this.audioFileService.addToPlayer(job);
    // this.audioSource = audioToPlay;
    // this.audioService.getAudioFile(audioName);
    this.audioService.playStream(this.imageBaseUrl + audioToPlay).subscribe(events => {
    });
  }

  public getUserResume(id): void {
    this.userProfileService.getResume(id).subscribe((data) => {
      this.userResume = data;

      this.userResume.categories = JSON.parse(data.categories);
      this.userResume.experienceLevel = JSON.parse(data.experienceLevel);
      this.userResume.qualification = JSON.parse(data.qualification);
      this.qualification = this.userResume.qualification;
      this.userResume.experience = JSON.parse(data.experience);
      console.log(this.userResume);
      this.getExperienceLevel();
    });
  }

  private getExperienceLevel(): void {
    this.userProfileService.getExperienceLevel().subscribe((data) => {
      console.log(data);
      this.experiencesLevel = data;
      this.experiencesLevel.forEach((element) => {
        this.yAxe.push(element.id);
        this.xAxe.push(element.name);
      });
      const ctx = document.getElementById('expChart');
      this.experience = this.userResume.experienceLevel;
      console.log('exp level', this.userResume.experienceLevel.id);
      for (let i = 0; i < this.experiencesLevel.length; i++) {
        if (i === Number(this.userResume.experienceLevel.id) - 1) {
          this.data.push({
            label: this.xAxe[i],
            backgroundColor: '#b9edc1',
            borderColor: '#f1ce49',
            borderWidth: 2,
            data: [{
              x: i,
              y: 0.5,
              r: 10,
            }]
          });
        } else {
          this.data.push({
            label: this.xAxe[i],
            backgroundColor: '#6dbc97',
            data: [{
              x: i,
              y: 0.5,
              r: 10,
            }]
          });
        }
      }
      this.chart = new Chart(ctx, {
        type: 'bubble',
        data: {
          datasets: this.data,
        },
        options: {
          tooltips: {
            callbacks: {
              label: (t, d) => {
                return d.datasets[t.datasetIndex].label;
              }
            }
          },
          offset: true,
          responsive: true,
          autoSkip: false,
          layout: {
            padding: {
              right: 30,
              left: 0
            }
          },
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          title: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                max: 1,
                min: 0,
                stepSize: 0.5
              },
              display: false,
              gridLines: {
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                fontFamily: 'Montserrat, sans-serif',
                autoSkip: false,
                callback: (value) => {
                  return this.xAxe[value];
                },
                maxRotation: 0,
                beginAtZero: true,
                fontColor: 'white',
                fontSize: 10
              },
              display: true,
              gridLines: {
                display: false
              }
            }]
          }
        }
      });
    }, (err) => {
      // console.log(err);
    });
  }

  setStateShowPhone() {
    this.showPhone = !this.showPhone;
  }

}
