<div class="filters">
  <!-- <div fxFlex="30"> -->
  <button mat-button (click)="getLastJobs(2)">
    <mat-icon style="width: 20px; height: 20px;" svgIcon="icon_tri"></mat-icon>
    <span fxShow="false" fxShow.sm="true" fxShow.md="true" fxShow.xl="true" fxShow.lg="true">Actualiser annonces</span>
    <span fxShow="false" fxShow.xs="true">Récent</span>
  </button>
  <!-- </div> -->

  <!-- <div fxFlex="30"> -->
  <button mat-button (click)="urgentJobClick()">
    <mat-icon style="width: 20px; height: 20px;" svgIcon="icon_last_jobs"></mat-icon>
    <span fxShow="false" fxShow.sm="true" fxShow.md="true" fxShow.xl="true" fxShow.lg="true">Afficher annonces urgentes</span>
    <span fxShow="false" fxShow.xs="true">Urgent</span>
  </button>
  <!-- </div> -->

  <!-- <div fxFlex="30"> -->
  <button mat-button [ngClass]="jobsService.mapButtonclicked ? 'active' : ''"
    (click)="setGoogleMap(); voirClick();handlePermission();">
    <mat-icon style="width: 20px; height: 20px;" svgIcon="icon_map"></mat-icon>
    <span fxShow="false" fxShow.sm="true" fxShow.md="true" fxShow.xl="true" fxShow.lg="true">Voir la carte</span>
    <span fxShow="false" fxShow.xs="true">Carte</span>
  </button>
  <!-- </div> -->


</div>
