<div class="job-question-modal-header">
  <h4 mat-dialog-title>Choisissez un template</h4>
  <!-- <button mat-button (click)="onNoClick()">x</button> -->
</div>
<div mat-dialog-content class="modal-body">
  <mat-list *ngIf="step === 'template_choice'"  dense>
    <mat-list-item *ngFor="let template of templates">
     <h3 (click)="chooseTemplate(template)" class="template_title" matLine>{{template.name}}</h3> 
     <p matLine>{{template.description}}</p>
     <button (click)="playTemplateAudio(template)" *ngIf="!template.playing" mat-icon-button >
      <mat-icon>play_circle_outline</mat-icon>
    </button>
     <button (click)="pauseTemplateAudio(template)" *ngIf="template.playing" mat-icon-button >
      <mat-icon>pause_circle_outline</mat-icon>
    </button>
    </mat-list-item>
  </mat-list>
  <div *ngIf="step === 'template_field_set'">
    <ng-form *ngIf="choosenTemplate.fields.includes('companyName')" [formGroup]="templateForm" fxLayout="column" >
      <mat-form-field appearance="outline" class="town-form-field">
        <mat-label>Nom de l'entreprise</mat-label>
        <input formControlName='companyName' matInput>
      </mat-form-field>
      <mat-form-field *ngIf="choosenTemplate.fields.includes('jobName')" appearance="outline" class="town-form-field">
        <mat-label>Nom de l'offre</mat-label>
        <input formControlName='jobName' matInput>
      </mat-form-field>
      <mat-form-field *ngIf="choosenTemplate.fields.includes('jobCategory')" appearance="outline" class="town-form-field">
        <mat-label>Catégorie de l'offre</mat-label>
        <input formControlName='jobCategory' matInput>
      </mat-form-field>
      <mat-form-field *ngIf="choosenTemplate.fields.includes('jobType')" appearance="outline" class="town-form-field">
        <mat-label>Type de contrat</mat-label>
        <input formControlName='jobType' matInput>
      </mat-form-field>

      <mat-form-field *ngIf="choosenTemplate.fields.includes('jobSalaire')" appearance="outline" class="town-form-field">
        <mat-label>Salaire</mat-label>
        <input formControlName='jobSalaire' matInput>
         
      </mat-form-field>
      <mat-form-field *ngIf="choosenTemplate.fields.includes('rtt')" appearance="outline" class="town-form-field">
        <mat-label>Réduction du temps de travail</mat-label>
        <input formControlName='rtt' matInput>
      </mat-form-field>
      <mat-form-field *ngIf="choosenTemplate.fields.includes('congeDay')" appearance="outline" class="town-form-field">
        <mat-label>Nombre de jour de congé</mat-label>
        <input formControlName='congeDay' matInput>
      </mat-form-field>
      
    </ng-form>
  </div>

</div>
<div mat-dialog-actions>
  <button mat-button>Fermer</button>
  <button (click)="submit()" *ngIf="step === 'template_field_set'" class="btn btn-success" mat-button>Valider</button>
</div>