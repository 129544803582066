import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudioFileService {
  private playList: any[] = [];
  private $playListSound = new Subject<any[]>();
  private $indexSoundWasPlay = new Subject<number>();

  public getPlayList(): Observable<any[]> {
    return this.$playListSound.asObservable();
  }

  public getSoundWasPlay(): Observable<number> {
    return this.$indexSoundWasPlay.asObservable();
  }


  public addToPlayer(data: any): void {
    console.log(data);
    if (data.audio !== null) {
      if (this.playList.length === 0) {
        this.playList.push(data);
        this.$playListSound.next(this.playList);
        this.$indexSoundWasPlay.next(0);
      } else {
        const result = this.playList.findIndex((element) => element.audio === data.audio);
        if (result === -1) {
          this.playList.push(data);
          this.$playListSound.next(this.playList);
          this.$indexSoundWasPlay.next(this.playList.length - 1);
        } else {
          // const audioFindedd = this.playList[result];
          // this.playList.splice(result, 1);
          // this.playList.push(audioFindedd);
          this.$playListSound.next(this.playList);
          this.$indexSoundWasPlay.next(result);
        }
      }
    }
  }

  public removeOnList(index): void {
    this.playList.splice(index, 1);
    this.$playListSound.next(this.playList);
  }
}
