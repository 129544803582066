import { Component, OnInit,ViewChild  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperComponent, SwiperDirective } from 'ngx-swiper-wrapper';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { CompanyService } from 'src/app/service/company/company.service';
import { JobsService } from 'src/app/service/jobs/jobs.service';
import { SwiperOptions } from 'swiper';
import { PaginationOptions } from 'swiper/types/components/pagination';
import { ScrollbarOptions } from 'swiper/types/components/scrollbar';
@Component({
  selector: 'app-company-user-compare',
  templateUrl: './company-user-compare.component.html',
  styleUrls: ['./company-user-compare.component.css']
})
export class CompanyUserCompareComponent implements OnInit {

 counter: number = 0;
  public user=[];
  otherUsers = [];

  jobId = null;
  job = null;
  company = null;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private jobService: JobsService,
    private authenticationService: AuthenticationService,
  ) { }
  isShow = false;
  isshowj=0;
  isshowjCounter=1;
  toggleDisplay() {
      this.isShow = !this.isShow;
      this.isshowj = this.isshowj != this.user.length - 1 ? this.isshowj + 1 : 0;
      this.isshowjCounter=this.isshowj+1;
  
  }
  ngOnInit(): void {
    this.getCompany();
    this.loadJobsData();
  }
  public getCompany(): void {
    this.companyService.getCompanyByUser(this.authenticationService.userValue.id).subscribe((data) => {
      this.company = data;
    }, ignore => {
      console.log('error when loading lastJobs'); // TODO: change here by a fancy snack to display error
    });
  }

  chooseCandidat(user) {
    user.choosen = true;
    this.companyService.chooseUserForJob(this.company.id, {jobId: this.jobId, userId: user.id})
      .subscribe(data => {
        console.log({data})
      })
  }

  loadJobsData() {
    this.jobId = this.activatedRoute.snapshot.params.jobId;
    this.companyService.getCompanyJobAppliedUsers(this.jobId)
        .subscribe(users => {
          users = users.map(u => {
            return {
              ...u,
              experienceLevel: JSON.parse(u.experienceLevel),
              categories: JSON.parse(u.categories)
            }
          });
          this.user = users.slice(0,2);
          this.otherUsers = users.slice(2);
    });
    this.jobService.getJob(this.jobId)
      .subscribe((data) => {
        console.log({data});
        this.job = data;
      })
  }
  public show: boolean = true;
  public slides = this.user;
  public type: string = 'component';
  public disabled: boolean = false;
  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
   // slidesPerView: 2,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    //loop:true,
  //  pagination: true,
 
   
    pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
      },
       breakpoints: {
    1200: {
      slidesPerView: 2,
      loopedSlides: 2,
      spaceBetween: 0
    },
    1024: {
      slidesPerView: 2,
      loopedSlides: 2,
      spaceBetween: 0
    },
    768: {
      slidesPerView: 1,
      loopedSlides: 1,
      spaceBetween: 0
    },
    675: {
      slidesPerView: 1,
      loopedSlides: 1,
      spaceBetween: 0,
    },
    }
  };

  private scrollbar: ScrollbarOptions = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true
  };

  private pagination: PaginationOptions = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;


  
  public toggleType(): void {
    this.type = (this.type === 'component') ? 'directive' : 'component';
  }

  public toggleDisabled(): void {
    this.disabled = !this.disabled;
  }

  public toggleDirection(): void {
    this.config.direction = (this.config.direction === 'horizontal') ? 'vertical' : 'horizontal';
  }

  public toggleSlidesPerView(): void {
    if (this.config.slidesPerView !== 1) {
      this.config.slidesPerView = 1;
    } else {
      this.config.slidesPerView = 2;
    }
  }

  public toggleOverlayControls(): void {
    if (this.config.navigation) {
      this.config.scrollbar = false;
      this.config.navigation = false;
      this.config.pagination = this.pagination;
    } else if (this.config.pagination) {
      this.config.navigation = false;
      this.config.pagination = false;
      this.config.scrollbar = this.scrollbar;
    } else {
      this.config.scrollbar = false;
      this.config.pagination = false;
      this.config.navigation = true;
    }

    if (this.type === 'directive' && this.directiveRef) {
      this.directiveRef.setIndex(0);
    } else if (this.type === 'component' && this.componentRef && this.componentRef.directiveRef) {
      this.componentRef.directiveRef.setIndex(0);
    }
  }

  public toggleKeyboardControl(): void {
    this.config.keyboard = !this.config.keyboard;
  }

  public toggleMouseWheelControl(): void {
    this.config.mousewheel = !this.config.mousewheel;
  }

  public onIndexChange(index: number): void {
    console.log('Swiper index: ', index);
  }

  public onSwiperEvent(event: string): void {
    console.log('Swiper event: ', event);
  }

 

}
