import { ModalCustomComponent } from './../../shared/modal-custom/modal-custom.component';
import { Injectable, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { Job } from '../../model/job';


@Injectable({
  providedIn: 'root'
})
export class ModalCustomService {

  constructor(private dialog: MatDialog) { }

  public open(options: any, template: TemplateRef<any> | ComponentType<any>, data: Job): void {
    this.dialog.open(template, { width: options.width, data }).afterClosed().subscribe((data) => {
      console.log('i am close');
    });
  }

}
