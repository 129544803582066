import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ReccordAudioService } from '../../service/reccord-audio/reccord-audio.service';
import { Plugins } from '@capacitor/core';
import { RecordingData, GenericResponse } from 'capacitor-voice-recorder';
import { Platform } from '@angular/cdk/platform';
import { AuthenticationService } from '../../service/authentication/authentication.service';
const { VoiceRecorder } = Plugins;

function _window(): any {
  return window;
}
@Component({
  selector: 'app-reccord-audio',
  templateUrl: './reccord-audio.component.html',
  styleUrls: ['./reccord-audio.component.css']
})
export class ReccordAudioComponent implements OnInit, OnDestroy {
  isRecording = false;
  recordedTime: string;
  blobUrl: any;
  @Output() audioBlob = new EventEmitter<Blob>();
  @Output() recordFile = new EventEmitter<any>();
  @Output() shows = new EventEmitter<boolean>();

  // tslint:disable-next-line: max-line-length
  constructor(public audioRecordingService: ReccordAudioService, private sanitizer: DomSanitizer, private plt: Platform, private authenticationService: AuthenticationService) {
    const fileName = /*this.authenticationService.userValue.phone +
      this.authenticationService.userValue.firstName
      + this.authenticationService.userValue.lastName +*/Date.now() +'_'+ Math.floor(Math.random() * 100000) + '.mp3';
    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.isRecording = false;
    });

    this.audioRecordingService.getReccordedTime().subscribe((time) => {
      this.recordedTime = time;
    });

    this.audioRecordingService.getReccordedBlob().subscribe((data) => {
        this.audioBlob.emit(data);
        this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data));
        this.recordFile.emit(this.blobUrl);
    });
    this.shows.emit(false);
  }

  ngOnInit(): void {
    if (this.audioRecordingService.subsVar==undefined) {
      this.audioRecordingService.subsVar = this.audioRecordingService.
      recordAudioComponentStopRecording.subscribe((name:string) => {
        this.stopRecording();
      });
    }
    // this.getInformations();
  }

  startRecording(): void {
    this.shows.emit(false);
    this.clearRecordedData();
    if (!this.isRecording) {
      this.isRecording = true;

    this.audioRecordingService.starReccording();
    this.audioRecordingService.$recordingTime.subscribe((data) => {
      console.log("in start recording");
        this.recordedTime = data;
      });
    }
  }

  public playAudio(play: boolean): void {
    const base64Sound = '...'; // from plugin
    const audioRef = new Audio(`data:audio/aac;base64,${base64Sound}`);
    audioRef.oncanplaythrough = () => audioRef.play();
    audioRef.load();
    this.recordFile.emit(this.blobUrl);
  }

  abortRecording(): void {
    if (this.isRecording) {
      this.isRecording = false;
      this.blobUrl = null;
      this.audioRecordingService.abbortReccording();
    }
  }

  stopRecording(): void {
    if (this.isRecording) {
      this.isRecording = false;
      this.clearRecordedData();
      this.audioRecordingService.stopReccording();
    }
  }

  clearRecordedData(): void {
    this.blobUrl = null;
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }
}
