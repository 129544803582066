<div class="job-question-modal-header">
  <h4 mat-dialog-title>Choisissez vos critères</h4>
  <!-- <button mat-button (click)="onNoClick()">x</button> -->
</div>
<div mat-dialog-content class="modal-body">
  <ng-form [formGroup]="sugestionForm" fxLayout="column" class="job-question-form">
    <mat-form-field appearance="outline" class="town-form-field">
      <mat-label>Catégories</mat-label>
      <mat-select formControlName='categorieId' (selectionChange)="onChange(sugestionForm.controls.categorieId.value)">
        <mat-option *ngFor="let categorie of categories; let i = index" [value]="i">
          {{categorie.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="town-form-field">
      <mat-label>Catégorie pole emploi</mat-label>
      <mat-select formControlName='souscategorieId'
        (selectionChange)="onLastChange(sugestionForm.controls.souscategorieId.value)">
        <mat-option *ngFor="let ctg of categoriePoleEmploie; let i = index" [value]="i">
          {{ctg.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="town-form-field">
      <mat-label>Sous catégorie pole emploi</mat-label>
      <mat-select formControlName='souscategoriePoleEmploiCode'
        (selectionChange)="setCategorieCode(sugestionForm.controls.souscategoriePoleEmploiCode.value)">
        <mat-option *ngFor="let ctg of sousCategoriePoleEmploie; let i = index" [value]="ctg.code">
          {{ctg.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-form>
</div>
<div mat-dialog-actions>
  <button mat-button>Fermer</button>
  <button mat-button type="button" class="btn btn-outline-dark" cdkFocusInitial (click)="submit()">Valider</button>
</div>