<div class="main-header-banner">
    <div class="header-banner">
        <img src="assets/image/compare-bg.jpg">
        <div class="on-header-img">
            <h1>Comparez 2 candidats<br>
                et sélectionnez la perle rare</h1>
        </div>
    </div>
</div>
<div class="compare-emp">
    <div class="container">
       <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill>
            <div class="compare-two-emp" fxFlex="75%" fxFlex.xs="100%" fxFlex.sm="100%">


                 <div class="content-container">
                  <!--   <div fxLayout="row wrap" fxLayout.xs="row wrap" fxLayout.sm="row wrap" fxFlexFill   > -->
                    <swiper *ngIf="type == 'component' && show" class="swiper-container" fxFlex="auto" [config]="config" [disabled]="disabled" (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')" (swiperTransitionEnd)="onSwiperEvent('transitionEnd')" fxLayout="row wrap" fxLayout.xs="row wrap" fxLayout.sm="row wrap" fxFlexFill >

                        <!--   <div fxLayout="row wrap" fxLayout.xs="row wrap" fxLayout.sm="row wrap" fxFlexFill   >   -->
                        <!-- <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%"  *ngFor="let slide of subjobApply;" class="swiper-slide" > -->
                            <div  *ngFor="let userDetail of user;" class="swiper-slide">
                                <div class="applied-cv-list" >
                                    <div class="applied-list-profile-row">
                                        <div class="profile-list">
                                            <div class="profile-in-list">
                                                  <div class="profile-in-img">
                                                <img src="assets/image/{{ userDetail.avatar }}">
                                            </div>
                                                <mat-icon class="audio-icon" svgIcon="audio"></mat-icon>
                                            </div>
                                        </div>
                                        <ul class="reting-candi rating-in-list">
                                            <li>
                                                <div class="rating-count">
                                                <span>{{userDetail.score}}</span>/100
                                                </div>
                                            </li>
                                            <li>
                                            <div class="stars-apply">
                                                <label class="active">
                                                    <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                                </label>
                                                <label class="active">
                                                    <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                                </label>
                                            </div>
                                            <span>{{userDetail.experienceLevel.name}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="applied-list-info">
                                        <h3>{{ userDetail.firstName}} {{userDetail.lastName}} </h3>
                                        <label>{{userDetail.address}}</label>
                                    </div>
                                    <div class="applied-list-btn">
                                        <div class="candidt-btm-btns" fxLayout="row">
                                            <button *ngFor="let cat of userDetail.categories" class="default-button-style">
                                                <mat-icon class="restauration-icon" svgIcon="{{cat.image}}"></mat-icon>
                                                {{cat.name}}</button>

                                        </div>
                                    </div>
                                    <div class="compare-info">
                                        <div class="compare-title">
                                            <mat-icon class="likestar-icon" svgIcon="icon_likeStar"></mat-icon>
                                            <label>Infos</label>
                                        </div>
                                        <p>Infos user </p>
                                    </div>
                                    <div class="divider"><mat-divider></mat-divider></div>
                                    <div class="compare-que">
                                        <div class="compare-title">
                                            <mat-icon class="question-icon" svgIcon="icon_question"></mat-icon>
                                            <label>Réponses au questionnaire</label>
                                        </div>
                                        <ul>
                                            <li>
                                                <div class="main-question">
                                                    <label>Question 1</label>
                                                    <p>Libelle question 1?</p>
                                                </div>
                                                <div class="question-yn">
                                                    <button class="question-btn active">OUI</button>
                                                    <button class="question-btn">NON</button>
                                                </div>
                                            </li>

                                        </ul>
                                        <ul class="compare-points">
                                            <li>
                                                100 / 100
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="divider"><mat-divider></mat-divider></div>

                                    <div class="compare-info candidt-info-btns-main">
                                        <div class="candidt-info-btns" fxLayout="column" fxLayout.xs="row" fxLayout.sm="row" fxLayoutAlign="center center">
                                            <button (click)="userDetail.showPhone = true;$event.stopPropagation();" *ngIf="!userDetail.showPhone && userDetail.phone" class="button-grad">
                                                <mat-icon class="call-icon" svgIcon="icon_call"></mat-icon>
                                                <span class="hide-mobile">Contacter {{userDetail.firstName ? userDetail.firstName : 'l\'utilisateur' }}</span>
                                                <span class="hide-web">Contacter {{userDetail.firstName ? userDetail.firstName : 'l\'utilisateur' }}</span>
                                            </button>

                                            <a [href]="'tel:' + userDetail.phone" *ngIf="userDetail.showPhone" class="button-border">
                                                <mat-icon class="call-icon" svgIcon="icon_call"></mat-icon>
                                                <span class="hide-mobile">{{userDetail.phone}}</span>
                                                <span class="hide-web">{{userDetail.phone}}</span>
                                            </a>

                                            <button *ngIf="job?.filledJob == 0 && !userDetail.choosen"
                                                    (click)="chooseCandidat(userDetail); $event.stopPropagation();"
                                                    class="button-border">
                                                <mat-icon class="plus-icon" svgIcon="icon_plus"></mat-icon>
                                                <span class="hide-mobile">Sélectionner le candidat</span>
                                                <span class="hide-web">Sélectionner le candidat</span>
                                            </button>
                                            <button *ngIf="job?.filledJob == 1 && userDetail.choosen" (click)="$event.stopPropagation();" class="button-border">
                                                <mat-icon>check_circle</mat-icon>
                                                <span class="hide-mobile">&nbsp;&nbsp; Candidat sélectionné</span>
                                                <span class="hide-web"> &nbsp;&nbsp;   Candidat sélectionné</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                    </swiper>



                </div>

            </div>
            <div class="sidebar-con hide-mobile" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%">
                <div class="side-widget">
                    <h4 class="side-widget-title">Votre offre</h4>
                    <div class="widget-card">
                        <div class="card-img">
                            <img src="{{job?.image}}">
                            <div class="office-logo">
                                <img src="assets/image/office-logo.jpg">
                            </div>
                        </div>
                        <div class="card-info">
                            <div class="d-flex-between">
                                <div class="card-info-title">
                                    <h3>{{job?.jobName}}</h3>
                                    <label>{{job.jobType}} - {{job.address}}</label>
                                </div>
                                <div class="card-audio">
                                    <mat-icon class="audio-icon" svgIcon="audio"></mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="side-widget">
                    <h4 class="side-widget-title">Les actualités</h4>
                    <div class="widget-card">
                        <div class="card-img">
                            <img src="assets/image/les-actualites.jpg">
                        </div>
                        <div class="card-info">
                            <div class="card-inr">
                                <h3>Titre article</h3>
                                <p>Contenu de l'article.</p>
                                <span class="publis-date">publié il y a 2 jours</span>
                            </div>
                        </div>
                    </div>
                    <div class="widget-card">
                        <div class="card-img">
                            <img src="assets/image/les-actualites.jpg">
                        </div>
                        <div class="card-info">
                            <div class="card-inr">
                                <h3>Titre article</h3>
                              <p>Contenu de l'article.</p>
                                <span class="publis-date">publié il y a 2 jours</span>
                            </div>
                        </div>
                    </div>
                    <div class="widget-card">
                        <div class="card-img">
                            <img src="assets/image/les-actualites.jpg">
                        </div>
                        <div class="card-info">
                            <div class="card-inr">
                                <h3>Titre article</h3>
                              <p>Contenu de l'article.</p>
                                <span class="publis-date">publié il y a 2 jours</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="voter-offer bg-dark-gray">
    <div class="container">
        <div class="voter-offer-title">
            <h2>découvrez les autres candidats qui ont répondu <br>
                à votre offre d'emploi - {{job?.jobName}} - </h2>
        </div>
        <div class="voter-offer-inr">
            <div fxLayout="row wrap" fxLayout.xs="row wrap" fxLayout.sm="row wrap" fxFlexFill fxLayoutGap="2%" fxLayoutGap.sm="4%" fxLayoutGap.xs="4%">
                <div fxFlex="32%" *ngFor="let user of otherUsers" fxFlex.xs="46%" fxFlex.sm="46%">
                    <div class="applied-cv-list">
                        <div class="applied-list-profile-row">
                            <div class="profile-list">
                                <div class="profile-in-list">
                                    <div class="profile-in-img">
                                        <img src="{{user.avatar}}">
                                    </div>
                                    <mat-icon class="audio-icon" svgIcon="audio"></mat-icon>
                                </div>
                            </div>
                            <ul class="reting-candi rating-in-list">
                                <li>
                                    <div class="rating-count">
                                    <span>{{user.score}}</span>/100
                                    </div>
                                </li>
                                <li>
                                <div class="stars-apply">
                                    <label class="active">
                                        <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                    </label>
                                    <label class="active">
                                        <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                    </label>
                                </div>
                                <span>{{user.experienceLevel.name}}</span>
                                </li>

                            </ul>
                        </div>
                        <div class="applied-list-info">
                            <h3>{{user.firstName}} {{user.lastName}} </h3>
                            <label>{{user.address}}</label>
                        </div>
                        <div class="applied-list-btn">
                            <div class="candidt-btm-btns" fxLayout="row">
                                <button *ngFor="let cat of user.categories" class="default-button-style">
                                    <mat-icon class="restauration-icon" svgIcon="{{cat.image}}"></mat-icon>
                                {{cat.name}}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>
