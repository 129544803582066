import { Component, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent, SwiperDirective } from 'ngx-swiper-wrapper';
import { SwiperOptions } from 'swiper';
import { PaginationOptions } from 'swiper/types/components/pagination';
import { ScrollbarOptions } from 'swiper/types/components/scrollbar';
import { CompanyService } from 'src/app/service/company/company.service';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackService } from '../../../service/snack/snack.service';
@Component({
  selector: 'app-company-subscriptions',
  templateUrl: './company-subscriptions.component.html',
  styleUrls: ['./company-subscriptions.component.css']
})
export class CompanySubscriptionsComponent implements OnInit {

  public subscriptionList: any = [];
  isShow = false;
  isshowj = 0;
  isshowjCounter = 1;
  public imageBaseUrl: string = environment.imageBaseUrl;

  public show: boolean = true;
  public slides = this.subscriptionList;
  public type: string = 'component';
  public disabled: boolean = false;
  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    // slidesPerView: 3,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    // loop: true,
    pagination: false,


    /*pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
    },*/
    breakpoints: {
      1200: {
        slidesPerView: 3,
        loopedSlides: 3,
        spaceBetween: 20
      },
      1024: {
        slidesPerView: 3,
        loopedSlides: 3,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 3,
        loopedSlides: 3,
        spaceBetween: 20
      },
      675: {
        slidesPerView: 1,
        loopedSlides: 1,
        spaceBetween: 20,
      },
    }
  };

  private scrollbar: ScrollbarOptions = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true
  };

  private pagination: PaginationOptions = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;

  toggleDisplay() {
    this.isShow = !this.isShow;
    this.isshowj = this.isshowj != this.subscriptionList.length - 1 ? this.isshowj + 1 : 0;
    this.isshowjCounter = this.isshowj + 1;

  }
  constructor(private compService: CompanyService, private activatedRoute: ActivatedRoute, private router: Router, private snack: SnackService) { }

  ngOnInit(): void {
    this.compService.getSubscriptionPlans().subscribe((subscriptions) => {
      this.subscriptionList = subscriptions;
    });
  }
  public toggleType(): void {
    this.type = (this.type === 'component') ? 'directive' : 'component';
  }

  public toggleDisabled(): void {
    this.disabled = !this.disabled;
  }

  public toggleDirection(): void {
    this.config.direction = (this.config.direction === 'horizontal') ? 'vertical' : 'horizontal';
  }

  public toggleSlidesPerView(): void {
    if (this.config.slidesPerView !== 1) {
      this.config.slidesPerView = 1;
    } else {
      this.config.slidesPerView = 2;
    }
  }

  public toggleOverlayControls(): void {
    if (this.config.navigation) {
      this.config.scrollbar = false;
      this.config.navigation = false;
      this.config.pagination = this.pagination;
    } else if (this.config.pagination) {
      this.config.navigation = false;
      this.config.pagination = false;
      this.config.scrollbar = this.scrollbar;
    } else {
      this.config.scrollbar = false;
      this.config.pagination = false;
      this.config.navigation = true;
    }

    if (this.type === 'directive' && this.directiveRef) {
      this.directiveRef.setIndex(0);
    } else if (this.type === 'component' && this.componentRef && this.componentRef.directiveRef) {
      this.componentRef.directiveRef.setIndex(0);
    }
  }

  public toggleKeyboardControl(): void {
    this.config.keyboard = !this.config.keyboard;
  }

  public toggleMouseWheelControl(): void {
    this.config.mousewheel = !this.config.mousewheel;
  }

  public onIndexChange(index: number): void {
    console.log('Swiper index: ', index);
  }

  public onSwiperEvent(event: string): void {
    console.log('Swiper event: ', event);
  }


  submit(subscription) {
    const compagntId = +this.activatedRoute.snapshot.params.compagnyId;
    this.compService.conpanySubscribe(compagntId, { subscriptionPlanId: subscription.id, name: subscription.name }).subscribe((result) => {
      if (result.success === true) {
        this.router.navigate(['/company-job-applied']);
        this.snack.valid('Vous avez souscrit avec succès');
      }
      else {
        this.snack.valid('Une erreur est survenus lors de votre souscription');
      }
    });
  }
}
