<div style="height: 56px; background: linear-gradient(232deg,#bbedc2,#99e6a9 15%,#73c59c 75%,#458076);;"></div>

<div class="crsr-box">
    <div class="container">
        <div class="box-container" fxLayout="column">
            <div class="horizontal-container" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="stretch"
                fxFlex="auto">

                <div class="vertical-container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="100%" fxFlex.xs="100%"
                    fxFlex.sm="100%">

                    <div class="content-container" fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                        <swiper *ngIf="type == 'component' && show" class="swiper-container" fxFlex="auto"
                            [config]="config" [disabled]="disabled" (indexChange)="onIndexChange($event)"
                            (swiperTransitionStart)="onSwiperEvent('transitionStart')"
                            (swiperTransitionEnd)="onSwiperEvent('transitionEnd')"
                            (swiperClick)="getCompanyJobAppliedUsers($event, null)">
                            <div *ngFor="let slide of jobs;let i=index;" class="swiper-slide">
                                <div class="slideContent">
                                    <div class="crsr-box-main" (click)="myFunc(slide,i);"
                                        [ngClass]="{'active': isActive(i)}">
                                        <div>
                                            <div class="slideBackground"
                                                style="background-image: url({{imageBaseUrl+slide.jobImage}});background-size: cover;">

                                            </div>
                                            <div fxFlex="column" class="info">
                                                <div class="crsr-box-title">
                                                    <div class="crsr-box-title-inr">
                                                        <h4>{{ slide.jobName}}</h4>
                                                        <label>{{ slide.jobType }} - {{ slide.address }}</label>
                                                    </div>
                                                    <button routerLink="/job/{{slide.id}}" class="crsr-box-edit-btn">
                                                        <mat-icon class="edit-icon" svgIcon="icon_publish"></mat-icon>
                                                    </button>
                                                </div>
                                                <div class="crsr-box-btn">
                                                    <button class="default-button-style">
                                                        <mat-icon class="btp-icon"
                                                            svgIcon="{{ slide.jobCategoryImage}}">
                                                        </mat-icon>
                                                        {{ slide.jobCategoryName}}
                                                    </button>
                                                    <label style="color: white">{{getCandidateNumberOfJob(slide.id)}}
                                                        candidatures</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </swiper>


                        <div *ngIf="type == 'directive' && show" class="swiper-container" [disabled]="disabled"
                            [swiper]="config" (indexChange)="onIndexChange($event)"
                            (swiperTransitionStart)="onSwiperEvent('transitionStart')"
                            (swiperTransitionEnd)="onSwiperEvent('transitionEnd')"
                            (swiperClick)="getCompanyJobAppliedUsers($event, null)">
                            <div class="swiper-wrapper ">
                                <div *ngFor="let slide of jobs" class="swiper-slide">
                                    <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                                        <div *ngFor="let slide of jobs" class="swiper-slide">

                                            <div class="crsr-box-main">
                                                <div class="crsr-box-title">
                                                    <div class="crsr-box-title-inr">
                                                        <h4>{{ slide.jobName}}</h4>
                                                        <label>{{ slide.jobType }} - {{ slide.address }}</label>
                                                    </div>
                                                    <button routerLink="/job/{{slide.id}}" class="crsr-box-edit-btn">
                                                        <mat-icon class="edit-icon" svgIcon="icon_publish"></mat-icon>
                                                    </button>
                                                </div>
                                                <div class="crsr-box-btn">
                                                    <button class="default-button-style">
                                                        <mat-icon class="btp-icon"
                                                            svgIcon="{{ slide.jobCategoryImage }}"></mat-icon>
                                                        {{ slide.jobCategoryName }}
                                                    </button>
                                                    <label style="color: white">{{slide.totalApplied}} 10
                                                        candidatures</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="swiper-scrollbar" [hidden]="config.scrollbar === false"></div>
                            <div class="swiper-pagination" [hidden]="config.pagination === false"></div>

                            <div class="swiper-button-prev" [hidden]="config.navigation === false"></div>
                            <div class="swiper-button-next" [hidden]="config.navigation === false"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="apply-canddt">
    <div class="container">
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill>
            <div class="sidebar-filter hide-mobile" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%">
                <div>
                    <h4 class="filter-ttl">Filtres </h4>
                    <span *ngIf="filterOn" (click)="resetFilters()" class="button-border reset-filter">
                        <mat-icon>clear</mat-icon>
                        <span >Annuler filtre</span>
                    </span>
                </div>
                <mat-divider></mat-divider>
                <div class="apply-stars">
                    <div>
                        <h5>Niveau d'expérience</h5>
                        <!-- <button mat-icon-button class="close-button" (click)="closePlayer()">
                            <mat-icon class="close-icon" color="warn">close</mat-icon>
                        </button> -->
                    </div>

                    <div class="char-container">
                        <canvas id="expChart" style="align-self: center;"></canvas>
                    </div>
                    <!-- <ul>
                        <li>
                            <div class="stars-apply">
                                <label class="active">
                                    <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                </label>
                                <label class="">
                                    <mat-icon class="starblanck-icon" svgIcon="icon_starBlanck"></mat-icon>
                                </label>
                                <label class="">
                                    <mat-icon class="starblanck-icon" svgIcon="icon_starBlanck"></mat-icon>
                                </label>
                            </div>
                            <label>débutant</label>
                        </li>
                        <li>
                            <div class="stars-apply">
                                <label class="active">
                                    <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                </label>
                                <label class="active">
                                    <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                </label>
                                <label class="">
                                    <mat-icon class="starblanck-icon" svgIcon="icon_starBlanck"></mat-icon>
                                </label>
                            </div>
                            <label>CONFIRMÉ</label>
                        </li>
                        <li>
                            <div class="stars-apply">
                                <label class="active">
                                    <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                </label>
                                <label class="active">
                                    <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                </label>
                                <label class="active">
                                    <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                </label>
                            </div>
                            <label>EXPERT </label>
                        </li>
                    </ul> -->
                </div>

                <mat-divider></mat-divider>
                <div class="sidebar-wid">
                    <h5 class="sidebar-wid-ttl">Score</h5>
                    <mat-slider (change)="onChange($event)" thumbLabel step="5" min="0" max="100"
                        [(ngModel)]="slidderDefaultValue"></mat-slider>
                </div>
            </div>
            <div fxFlex="75%" fxFlex.xs="100%" fxFlex.sm="100%">
                <div class="applied-cv">
                    <label class="candidats-offer" [routerLink]="['/job/', currentJob?.id]"
                        routerLinkActive="router-link-active">Offre {{currentJob?.jobName}} > Candidats</label>
                    <h2>{{ filterRedList.length }} candidats ont répondu <br>à votre offre d'emploi</h2>
                    <div *ngFor="let user of filterRedList | slice:0:1;" fxLayout="row" class="cendidate-box"
                        routerLink="/company-profile-candidate/{{user.id}}">
                        <div class="candidt-img">
                            <label>
                                <mat-icon class="blankstar-icon" svgIcon="icon_blanckStar"></mat-icon>
                                top candidat
                            </label>
                            <img src="{{ imageBaseUrl + user.avatar }}">
                            <div class="candidt-audio"

                                style="cursor: pointer;">
                                <mat-icon (click)="$event.stopPropagation();playAudio(user.firstname + ' ' + user.lastName + '-' + activeJobId, user)" class="audio-icon" svgIcon="audio"></mat-icon>
                            </div>
                        </div>
                        <div class="candidt-info" fxLayout="row" fxLayout.xs="column">
                            <div class="candidt-info-left">
                                <h3>{{ user.firstName}} {{ user.lastName}}</h3>
                                <label>{{user.address === null ? 'non renseigné' : user.address}}</label>
                                <ul class="reting-candi">
                                    <li>
                                        <div class="stars-apply">
                                            <label class="active">
                                                <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                            </label>
                                            <label class="active">
                                                <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                            </label>
                                            <label class="active">
                                                <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                            </label>
                                        </div>
                                        <span>EXPERT</span>
                                    </li>
                                    <li>
                                        <div class="rating-count">
                                            <span>{{ user.score }}</span>/100
                                        </div>
                                    </li>
                                </ul>
                                <div class="candidt-btm-btns" fxLayout="row">
                                    <!--<button class="default-button-style">
                                        <mat-icon class="restauration-icon" svgIcon="category_restauration"></mat-icon>
                                        restauration
                                    </button>
                                    <button class="default-button-style">
                                        <mat-icon class="btp-icon" svgIcon="category_btp"></mat-icon>
                                        BTP
                                    </button>-->
                                    <button (click)="$event.stopPropagation();" class="default-button-style" *ngFor="let categorie of user.categories">
                                        <mat-icon class="restauration-icon" svgIcon="{{categorie.image}}"></mat-icon>
                                        {{categorie.name}}
                                    </button>
                                </div>
                            </div>
                            <div class="candidt-info-btns" fxLayout="column" fxLayout.xs="row" fxLayout.sm="row"
                                fxLayoutAlign="center center">
                                <button *ngIf="!showPhone" [ngClass]="{'button-border': showPhone, 'button-grad': !showPhone}"
                                    (click)="setStateShowPhone();$event.stopPropagation();">
                                    <mat-icon class="call-icon" svgIcon="icon_call"></mat-icon>
                                    <span class="hide-mobile">
                                        <a href="tel:{{user.phone}}"></a>
                                        {{showPhone === false ? 'Contacter' : ''}}
                                        {{showPhone === false ? user.firstName + user.lastName : user.phone}}</span>
                                    <span class="hide-web">{{showPhone === false ? 'Contacter' : ''}} {{showPhone ===
                                        false ?
                                        user.firstName + user.lastName : user.phone}}</span>
                                </button>
                                <button (click)="$event.stopPropagation();" *ngIf="showPhone" class="button-border"
                                    >
                                    <mat-icon   class="call-icon" svgIcon="icon_call"></mat-icon>
                                    <span class="hide-mobile">
                                        <a  style="color: #03334F;" href="tel:{{user.phone}}"> {{user.phone}}</a>
                                    </span>
                                    <span class="hide-web"><a style="color: #03334F;" href="tel:{{user.phone}}"> {{user.phone}}</a></span>
                                </button>
                                <button *ngIf="currentJob.filledJob == 0 && !user.choosen" (click)="chooseCandidat(currentJob, user); $event.stopPropagation();" class="button-border">
                                    <mat-icon class="plus-icon" svgIcon="icon_plus"></mat-icon>
                                    <span class="hide-mobile">Sélectionner le candidat</span>
                                    <span class="hide-web">Sélectionner le candidat</span>
                                </button>
                                <button *ngIf="currentJob.filledJob == 1 && user.choosen" (click)="$event.stopPropagation();" class="button-border">
                                    <mat-icon>check_circle</mat-icon>
                                    <span class="hide-mobile">&nbsp;&nbsp; Candidat sélectionné</span>
                                    <span class="hide-web"> &nbsp;&nbsp;   Candidat sélectionné</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayout.xs="row wrap" fxLayout.sm="row wrap" fxFlexFill fxLayoutGap="2%"
                        fxLayoutGap.sm="4%" fxLayoutGap.xs="4%">
                        <div fxFlex="31.33%" fxFlex.xs="46%" fxFlex.sm="46%"
                            *ngFor="let user of filterRedList | slice:1"
                            routerLink="/company-profile-candidate/{{user.id}}">
                            <div class="applied-cv-list">
                                <div class="applied-list-profile-row">
                                    <div class="profile-list">
                                        <div class="profile-in-list">
                                            <div class="profile-in-img">
                                                <img src="{{ imageBaseUrl + user.avatar }}">
                                            </div>
                                            <mat-icon class="audio-icon" svgIcon="audio"
                                                (click)="$event.stopPropagation();playAudio(user.firstname + ' ' + user.lastName + '-' + activeJobId, user)"
                                                style="cursor: pointer;"></mat-icon>
                                        </div>
                                    </div>
                                    <ul class="reting-candi rating-in-list">
                                        <li>
                                            <div class="rating-count">
                                                <span>{{ user.score }}</span>/100
                                            </div>
                                        </li>
                                        <li>
                                            <div class="stars-apply">
                                                <label class="active">
                                                    <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                                </label>
                                                <label class="active">
                                                    <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                                </label>
                                            </div>
                                            <span>CONFIRMÉ</span>
                                        </li>

                                    </ul>
                                </div>
                                <div class="applied-list-info">
                                    <h3>{{ user.firstName}} {{ user.lastName}}</h3>
                                    <label>{{user.address === null ? 'non renseigné' : user.address}}</label>
                                    <a *ngIf="!user.showPhone && user.phone" style="padding: 1px 1px; margin: 0px; margin-bottom: 5px;" class="button-grad"
                                    (click)="user.showPhone = true;$event.stopPropagation();">
                                    <mat-icon class="call-icon" svgIcon="icon_call"></mat-icon>
                                    <span class="hide-mobile">
                                        Contacter {{user.firstName ? user.firstName : 'Utilisateur' }}</span>
                                    <span class="hide-web">Contacter {{user.firstName + ' '  + user.lastName}}</span>
                                    </a>
                                    <a *ngIf="user.showPhone"  style="padding: 1px 1px;margin-bottom: 5px;" (click)="$event.stopPropagation();"  class="button-border"
                                    >
                                    <mat-icon   class="call-icon" svgIcon="icon_call"></mat-icon>
                                    <span class="hide-mobile">
                                        <a  style="color: #03334F;" href="tel:{{user.phone}}"> {{user.phone}}</a>
                                    </span>
                                    <span class="hide-web"><a style="color: #03334F;" href="tel:{{user.phone}}"> {{user.phone}}</a></span>
                                </a>
                                <button *ngIf="currentJob.filledJob == 0 && !user.choosen" (click)="chooseCandidat(currentJob, user); $event.stopPropagation();" class="button-border">
                                    <mat-icon class="plus-icon" svgIcon="icon_plus"></mat-icon>
                                    <span class="hide-mobile">Sélectionner le candidat</span>
                                    <span class="hide-web">Sélectionner le candidat</span>
                                </button>
                                <button *ngIf="currentJob.filledJob == 1 && user.choosen" (click)="$event.stopPropagation();" class="button-border">
                                    <mat-icon>check_circle</mat-icon>
                                    <span class="hide-mobile">&nbsp;&nbsp; Candidat sélectionné</span>
                                    <span class="hide-web"> &nbsp;&nbsp;   Candidat sélectionné</span>
                                </button>
                                </div>
                                <div class=" applied-list-btn">
                                    <div class="candidt-btm-btns" fxLayout="row">
                                        <!--<button class="default-button-style">
                                            <mat-icon class="restauration-icon" svgIcon="category_restauration">
                                            </mat-icon>
                                            restauration
                                        </button>
                                        <button class="default-button-style">
                                            <mat-icon class="btp-icon" svgIcon="category_btp"></mat-icon>
                                            BTP
                                        </button>-->
                                        <button (click)="$event.stopPropagation();" class="default-button-style" *ngFor="let categorie of user.categories">
                                            <mat-icon class="restauration-icon" svgIcon="{{categorie.image}}">
                                            </mat-icon>
                                            {{categorie.name}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <!-- <div fxLayout="row" class="cendidate-box">
                        <div class="candidt-img">
                            <label>
                                <mat-icon class="blankstar-icon" svgIcon="icon_blanckStar"></mat-icon>
                                top candidat
                            </label>
                            <img src="assets/image/candidate.jpg">
                            <div class="candidt-audio">
                                <mat-icon class="audio-icon" svgIcon="audio"></mat-icon>
                            </div>
                        </div>
                        <div class="candidt-info" fxLayout="row" fxLayout.xs="column">
                            <div class="candidt-info-left">
                                <h3>Jean-Muriel Azonhoume </h3>
                                <label>Bordeaux (33) - Nouvelle Aquitaine</label>
                                <ul class="reting-candi">
                                    <li>
                                        <div class="stars-apply">
                                            <label class="active">
                                                <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                            </label>
                                            <label class="active">
                                                <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                            </label>
                                            <label class="active">
                                                <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                                            </label>
                                        </div>
                                        <span>EXPERT</span>
                                    </li>
                                    <li>
                                        <div class="rating-count">
                                            <span>100</span>/100
                                        </div>
                                    </li>
                                </ul>
                                <div class="candidt-btm-btns" fxLayout="row">
                                    <button class="default-button-style">
                                        <mat-icon class="restauration-icon" svgIcon="category_restauration"></mat-icon>
                                        restauration
                                    </button>
                                    <button class="default-button-style">
                                        <mat-icon class="btp-icon" svgIcon="category_btp"></mat-icon>
                                        BTP
                                    </button>
                                </div>
                            </div>
                            <div class="candidt-info-btns" fxLayout="column" fxLayout.xs="row" fxLayout.sm="row"
                                fxLayoutAlign="center center">
                                <button class="button-grad">
                                    <mat-icon class="call-icon" svgIcon="icon_call"></mat-icon>
                                    <span class="hide-mobile">Contacter Jean-Muriel</span>
                                    <span class="hide-web">Contacter</span>
                                </button>
                                <button class="button-border">
                                    <mat-icon class="plus-icon" svgIcon="icon_plus"></mat-icon>
                                    <span class="hide-mobile">Ajouter à ma liste de candidat</span>
                                    <span class="hide-web">Ajouter à ma liste</span>
                                </button>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
