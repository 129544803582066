<h2 mat-dialog-title>Confirmation</h2>
<h3 mat-dialog-title>Merci de patienter, vous aller recevoir un code par SMS. </h3>
<h3 mat-dialog-title>Saisir le code dans la zone ci dessous, puis valider </h3>
<mat-dialog-content class="mat-typography">
  <mat-form-field appearance="none" style="width: 100%;">
    <input class="login-input" matInput placeholder="Code" [(ngModel)]="code" name="code">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-button cdkFocusInitial (click)="submit()">Envoyer</button>
</mat-dialog-actions>
<ngx-loading [show]="loading"
  [config]="{animationType: ngxLoadingAnimationTypes.circle, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}">