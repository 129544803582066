<h1 matDialogTitle>{{data.title}}</h1>

<mat-dialog-content>
    {{data.message}}
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button matDialogClose>Fermer</button>
    <!-- <button mat-button>data.confirmText</button> -->

</mat-dialog-actions>
