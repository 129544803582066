import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Job } from '../../model/job';
import { environment } from '../../../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-social-shared',
  templateUrl: './social-shared.component.html',
  styleUrls: ['./social-shared.component.css']
})
export class SocialSharedComponent implements OnInit {

  public jobId: number;
  public jobUrl;
  public jobName: string;
  public jobImg: string;
  public description: string;
  public job: Job;
  public backendURL;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Job, private titleService: Title) {
    this.job = data;
    this.jobId = data.id;
    this.backendURL = environment.backendUrl;
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.job.jobName);
    var jobUrl = location.origin+'/job/' + this.job.id;
    var jobName = this.job.jobName;
    var jobImg = environment.imageBaseUrl + this.job.image;
    this.jobUrl = jobUrl.split('/').join('__');
    this.jobName = jobName.split('/').join('__');
    this.jobImg = jobImg.split('/').join('__');
    
  }

}
