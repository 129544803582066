<form class="search-form py-3">
    <div class="container">
      <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div class="" fxFlex="31" fxFlex.lt-md="100">
          <button mat-button class="search-form-field btn-service-la">
            <span class="icon-before">
              <mat-icon [svgIcon]="icon"></mat-icon>
            </span>
            {{name}}
            <span class="icon-after">
              <mat-icon matSuffix svgIcon="audio"></mat-icon>
            </span>
          </button>
        </div>
        <div class="" fxFlex="32" fxFlex.lt-md="100">
          <mat-form-field appearance="none" class="search-form-field">
            <input class="form-control" matInput placeholder="Métier, compétence, mot clé">
            <span class="icon-before">
              <mat-icon svgIcon="icon_metier"></mat-icon>
            </span>
            <span class="icon-after">
              <mat-icon matSuffix svgIcon="icon_micro"></mat-icon>
            </span>
          </mat-form-field>
        </div>
        <div class="" fxFlex="23" fxFlex.lt-md="100">
          <mat-form-field appearance="none" class="search-form-field">
            <input class="form-control" matInput placeholder="Saisissez une ville">
            <span class="icon-before">
              <mat-icon svgIcon="icon_localisation"></mat-icon>
            </span>
            <span class="icon-after">
              <mat-icon matSuffix svgIcon="icon_micro"></mat-icon>
            </span>
          </mat-form-field>
        </div>
        <button mat-fab color="secondary" class="search-icon btn-search">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
  </form>
