import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { AudioService } from '../../service/audio.service';
import { AudioFileService } from '../../service/audiofile.service';
import { MatTableDataSource } from '@angular/material/table';
import { JobsService } from 'src/app/service/jobs/jobs.service';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { SnackService } from 'src/app/service/snack/snack.service';
import { User } from '../../model/user';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.css', './../../../../node_modules/bootstrap/dist/css/bootstrap.css'],

})
export class AudioPlayerComponent implements OnInit {
  public user: User;
  currentFileName: string;
  files: Array<any> = [];
  state: any;
  displayedColumns: string[] = ['play', 'PostesApourvoir', 'Statut', 'Secteurs', 'Entreprises', 'Lieux', 'Temps', 'action'];
  currentFile: any = {};
  currentFileId: number;
  public dataSource = new MatTableDataSource<any>([]);
  value: any;
  screenHeight: number;
  screenWidth: number;
  currentPlayListLength = 0;
  currentSoundPlayingIndex = 0;

  public audioUrl: string = environment.imageBaseUrl;
  constructor(public audioService: AudioService, public audioFile: AudioFileService,
              private router: Router, private jobService: JobsService,
              private authenticationService: AuthenticationService,
              private snack: SnackService,
  ) {
    this.getScreenSize();
    this.authenticationService.user.subscribe(x => this.user = x);
    this.audioService.getState().subscribe(state => {
      this.state = state;
    });
    audioFile.getPlayList().subscribe((data) => {
      // if (this.currentPlayListLength === 0 && data.length !== 0) {
      //   this.currentPlayListLength = data.length;
      //   const file = data[data.length - 1];
      //   this.openFile(file, data.length - 1);
      // } else if (this.currentPlayListLength < data.length) {
      //   const file = data[data.length - 1];
      //   this.openFile(file, data.length - 1);
      // } else {
      //   this.audioService.stop();
      //   this.currentFileName = '';
      //   this.state.playing = false;
      //   this.state.readableCurrentTime = '';
      //   this.state.readableDuration = '';
      //   this.state.duration = undefined;
      //   this.state.currentTime = undefined;
      //   this.state.canplay = false;
      //   this.state.error = false;
      //   this.state.muted = false;
      // }
      audioFile.getSoundWasPlay().subscribe((index) => {
        this.currentSoundPlayingIndex = index;
        this.openFile(data[index], index);
      });
      this.currentPlayListLength = data.length;
      this.dataSource.data = data;
    });
  }
  showplaylist = false;
  val = true;
  filenew = ''; selected: any;
  ngOnInit(): void {
    // // this.openFile(this.files[0] , 1);
    // this.audioService.setaudiofile.subscribe((file) => {
    //   console.log(file);
    //   if (file) {
    //     // const order = Math.floor(Math.random() * this.dataSource.data.length);
    //     this.openFile(this.dataSource.data.find((value) => value.jobName === file || value.name === file),
    //       this.dataSource.data.findIndex((value) => value.jobName === file));
    //     this.currentFileName = file;
    //     // console.log(this.currentFileName);
    //   }

    // });

  }
  // tslint:disable-next-line:typedef
  isActive(i) {

    // console.log('hihii');
    return this.selected === this.dataSource.data[i];
  }


  public isFirstPlaying(): boolean {
    return this.currentFile.index === 0;
  }
  public isLastPlaying(): boolean {
    return this.currentFile.index === this.dataSource.data.length - 1;
  }

  public playStream(url): void {
    this.audioService.playStream(url).subscribe(events => {
      if (events.type === 'ended') {
        if (this.currentSoundPlayingIndex + 1 === this.dataSource.data.length) {
          console.log(this.currentSoundPlayingIndex);
          console.log(this.dataSource.data.length);
          stop();
        } else {
          this.currentSoundPlayingIndex += 1;
          this.audioService.playStream('assets/audio/defaultJingle.mp3')
            .subscribe((eventss: any) => {
              if (eventss.type === 'ended') {
                this.openFile(this.dataSource.data[this.currentSoundPlayingIndex], this.currentSoundPlayingIndex);
              }
            });
        }
      }
    });
  }

  public openFile(file, index): void {
    if (file.address === 'local'){
      this.playStream(file.audio);
    } else {
      this.audioService.stop();
      this.currentFile = { index, file };
      this.currentSoundPlayingIndex = index;
      this.currentFileName = file.jobName === undefined ? file.name : file.jobName;
      this.currentFileId = file.id;
      // console.log(JSON.parse(file.audio)[0].download_link);

      if (file.qualification) {
        this.playStream(this.audioUrl + file.audio);
      } else if (file.audio instanceof Array) {
        this.playStream(this.audioUrl + JSON.parse(file.audio)[0].download_link);
      }
      else if (file.audio[0] === '[') {
        this.playStream(this.audioUrl + JSON.parse(file.audio)[0].download_link);
      } else {
        this.playStream(this.audioUrl + file.audio);
      }
    }
    this.selected = this.dataSource.data[index];
  }

  public pause(): void {
    this.audioService.pause();
  }

  public play(): void {
    if (this.currentPlayListLength === 0) {
      this.audioService.playStream('assets/audio/intro.wav').subscribe(events => { });
    } else {
      this.audioService.play();
    }
  }

  public stop(): void {
    this.audioService.stop();
  }

  public next(): void {
    const index = this.currentFile.index + 1;
    const file = this.dataSource.data[index];
    this.openFile(file, index);
  }

  public previous(): void {
    const index = this.currentFile.index - 1;
    const file = this.dataSource.data[index];
    this.openFile(file, index);
  }

  public onSliderChangeEnd(change): void {
    this.audioService.seekTo(change.value);
  }

  public onVolumeChangeEnd(change): void {
    this.audioService.volume(change.value);
    // console.log(change.value);
  }

  public showPlayList(): void {
    this.showplaylist = !this.showplaylist;
  }

  public repeat(): void {
    this.play();
  }

  public mute(): void {
    this.val = !this.val;
    this.audioService.mute(!this.val);
    // console.log(!this.val);
  }

  public removeOnList(index): void {
    this.audioFile.removeOnList(index);
  }

  public applyJob(): void {
    this.router.navigate([`/job-application/${this.currentFileId}`]);
  }

  public abbJobToFavorite(jobId: number): void {
    this.jobService.addJobToFavorite(jobId, this.authenticationService.userValue.id).subscribe((data) => {
      // console.log(data);
      this.snack.info('Job ajouté à vos favoris');
    }, (err) => {
      console.log(err);
    });
  }



  @HostListener('window:size', ['$event'])
  private getScreenSize(event?): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    // console.log(this.screenHeight, this.screenWidth);
  }
}
