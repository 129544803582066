<!-- Footer -->
<footer class="footer footer-section">
    <div class="page-container" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutAlign.lt-md="center center" style="padding-top: 20px;">
        <div class="" fxFlex="16" fxFlexOrder.lt-md="2" fxFlex.lt-md="none">
            <a href="javascript:void(0);" class="footer-logo">
                <img src="assets/image/gotaf.svg" alt="Logo" class="" width="120px">
            </a>
            <p><a style="text-decoration: none; margin-left:20px; color: white;" href="https://corp-gotaf.fr" target="_blank">Site Corporatif</a></p>
        </div>
        <div class="footer-address" fxFlex="28" fxFlex.lt-md="100">
            <h5><a style="text-decoration: none; color: inherit;" href="javascript:void(0)" (click)="contactForm()">NOUS CONTACTER</a></h5>
            <p>30 rue Pierre Duhem<br>
                <br> 33000 Bordeaux<br>
            </p>
        </div>
        <div class="" fxFlex="28" fxFlexOrder.lt-md="3" fxFlex.lt-md="none">
            <div class="footer-nav-top">
                <h5>NAVIGATION</h5>
                <p><a style="text-decoration: none; color: inherit;" href="job-applied">Espace candidat</a><br>
                    <a style="text-decoration: none; color: inherit;" href="company-job-applied">Espace recruteur</a><br>
                    <a style="text-decoration: none; color: inherit;" href="cgv">Conditions générale de ventes</a><br>
                    <a style="text-decoration: none; color: inherit;" href="mentions-legales">Mentions Légales</a><br>
                    <a style="text-decoration: none; color: inherit;" href="presse">Presse</a></p>
            </div>
        </div>
        <div class="footer-newsletter" fxFlexOrder.lt-md="1" fxFlex="28" fxFlex.lt-md="100">
            <h5>NEWSLETTER</h5>
            <p>Inscrivez-vous à notre newsletter et restez à l'écoute</p>
            <form [formGroup]="newsLetterForm">
                <div class="form-group-icon">
                    <input formControlName="email" placeholder="e-mail" name="email" class="form-control">
                    <button (click)="registerToNewLetter()" matSuffix class="" mat-button>OK</button>
                </div>
            </form>

        </div>

    </div>

</footer>
