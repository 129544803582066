import { Component, OnInit, ViewChild } from '@angular/core';

import { SwiperComponent, SwiperDirective } from 'ngx-swiper-wrapper';
import Swiper, { SwiperOptions } from 'swiper';
import { PaginationOptions } from 'swiper/types/components/pagination';
import { ScrollbarOptions } from 'swiper/types/components/scrollbar';
import { CompanyService } from '../../../service/company/company.service';
import { AuthenticationService } from '../../../service/authentication/authentication.service';
import { environment } from '../../../../environments/environment.prod';
import { AudioFileService } from '../../../service/audiofile.service';
import { AudioService } from '../../../service/audio.service';
import { HostListener } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { Chart } from 'chart.js';
import { UserService } from '../../../service/user/user.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-company-job-applied',
  templateUrl: './company-job-applied.component.html',
  styleUrls: ['./company-job-applied.component.css']
})
export class CompanyJobAppliedComponent implements OnInit {
  public company: any;
  public jobs = [];
  public users = [];
  filterRedList = [];
  private allJobsInformations = [];
  public imageBaseUrl: string = environment.imageBaseUrl;
  public activeJobId = 0;


  public subjobApply = [
    { id: 1, user: 'Jean-Muriel Azonhoume 1', job: 'Mon offre 1', score: '100' },
    { id: 2, user: 'Ricky Ponting', job: 'Mon offre 2', score: '98' },
    { id: 3, user: 'Adam Gilchrist', job: 'Mon offre 3', score: '97' },
    { id: 4, user: 'Steve Smith', job: 'Mon offre 4', score: '96' },
    { id: 5, user: 'Andr Russle', job: 'Mon offre 5', score: '95' },
    { id: 6, user: 'Ravindra jadeja', job: 'Mon offre 6', score: '94' },
    { id: 7, user: 'Virat Kohli', job: 'Mon offre 7', score: '93' }
  ];

  public show: boolean = true;

  public slides = this.subjobApply;

  public type: string = 'component';

  public disabled: boolean = false;

  public isClass: boolean = false;
  selected: any;
  public innerWidth: any;
  filterOn = false;
  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: 4,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false
  };

  private scrollbar: ScrollbarOptions = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true
  };

  private pagination: PaginationOptions = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };
  public currentJob;
  slidderDefaultValue = 0;

  public chart;
  public yAxe = [];
  public xAxe = [];
  private experience: any[] = [];
  public experiencesLevel: any[] = [];
  data: any[] = [];
  dataset: any[] = [];
  filter = {
    experience: -1,
    score: 0
  };
  public showPhone = false;

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private authenticationService: AuthenticationService,
    private audioFileService: AudioFileService,
    private audioservice: AudioService,
    private userService: UserService,
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.getCompany();
    this.getExperienceLevel();
    // this.getCompanyAppliedJobs(this.company.id);
  }

  @HostListener('window:resize', ['$event'])
  public getScreenSize(event?): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 599) {
      this.config.slidesPerView = 2;
    } else {
      this.config.slidesPerView = 4;
    }
  }
  public toggleType(): void {
    console.log(this.type);
    this.type = (this.type === 'component') ? 'directive' : 'component';
  }

  public toggleDisabled(): void {
    this.disabled = !this.disabled;
  }

  public toggleDirection(): void {
    this.config.direction = (this.config.direction === 'horizontal') ? 'vertical' : 'horizontal';
  }

  public toggleSlidesPerView(): void {
    if (this.config.slidesPerView !== 1) {
      this.config.slidesPerView = 1;
    } else {
      this.config.slidesPerView = 2;
    }
  }

  public toggleOverlayControls(): void {
    if (this.config.navigation) {
      this.config.scrollbar = false;
      this.config.navigation = false;

      this.config.pagination = this.pagination;
    } else if (this.config.pagination) {
      this.config.navigation = false;
      this.config.pagination = false;

      this.config.scrollbar = this.scrollbar;
    } else {
      this.config.scrollbar = false;
      this.config.pagination = false;

      this.config.navigation = true;
    }

    if (this.type === 'directive' && this.directiveRef) {
      this.directiveRef.setIndex(0);
    } else if (this.type === 'component' && this.componentRef && this.componentRef.directiveRef) {
      this.componentRef.directiveRef.setIndex(0);
    }
  }

  public toggleKeyboardControl(): void {
    this.config.keyboard = !this.config.keyboard;
  }

  public toggleMouseWheelControl(): void {
    this.config.mousewheel = !this.config.mousewheel;
  }

  public onIndexChange(index: number): void {
    console.log('Swiper index: ', index);
  }

  public onSwiperEvent(event: string): void {
    console.log('Swiper event: ', event);
  }

  public myFunc(job, i) {
    this.currentJob = job;

    // document.getElementById('lblName').innerHTML = 'Offre ' + jobName + ' > Candidats';

    // alert(this.class);
    this.isClass = !this.isClass;
    this.selected = this.slides[i];

  }
  isActive(i) {
    return this.selected === this.slides[i];
  }



  /**
   * get company
   */
  public getCompany(): void {
    this.companyService.getCompanyByUser(this.authenticationService.userValue.id).subscribe((data) => {
      this.company = data;
      this.getCompanyAppliedJobs(this.company?.id);
    }, ignore => {
      console.log('error when loading lastJobs'); // TODO: change here by a fancy snack to display error
    });
  }

  /**
   * get company jobs
   */
  public getCompanyAppliedJobs(companyId): void {
    this.companyService.getCompanyAppliedJobs(companyId).subscribe((data) => {
      this.jobs = data['companyJobs'];
      this.allJobsInformations = data['userJobs'];
      this.myFunc(this.jobs[0], 0);
      this.componentRef.directiveRef.update();
      this.getCompanyJobAppliedUsers(null, this.jobs[0].id);
    }, ignore => {
      this.jobs = [];
      console.log('error when loading lastJobs'); // TODO: change here by a fancy snack to display error
    });
  }


  public getCandidateNumberOfJob(jobId: number): number {
    let result: any;
    result = this.allJobsInformations.filter((value) => value.jobId === jobId);
    return Number(result[0].totalApplied);
  }


  /**
   * get last jobs
   */
  public getCompanyJobAppliedUsers(e: [Swiper, TouchEvent], jobId: number): void {

    this.slidderDefaultValue = 0;

    if (jobId === null) {
      this.activeJobId = this.jobs[e[0].clickedIndex].id;
      this.companyService.getCompanyJobAppliedUsers(this.jobs[e[0].clickedIndex].id).subscribe((data) => {
        this.users = data;
        this.users.forEach(user => {
          user.categories = JSON.parse(user.categories);
        });
        this.filterRedList = this.users;
        // console.log(this.users);
      }, ignore => {
        this.users = [];
        this.filterRedList = [];
        console.log('error when loading lastJobs'); // TODO: change here by a fancy snack to display error
      });
    } else {
      this.activeJobId = jobId;
      this.companyService.getCompanyJobAppliedUsers(jobId).subscribe((data) => {
        this.users = data;
        this.users.forEach(user => {
          user.categories = JSON.parse(user.categories);
        });
        this.filterRedList = this.users;
        // console.log(this.users);
      }, ignore => {
        this.users = [];
        console.log('error when loading lastJobs'); // TODO: change here by a fancy snack to display error
      });
    }
    // console.log(this.users);
  }

  public playAudio(audioName: string, user: any): void {
    this.audioFileService.addToPlayer(user);
    // this.audioSource = audioToPlay;
    this.audioservice.getAudioFile(audioName);
  }

  onChange(e: MatSliderChange): void {
    this.filter.score = e.value;
    // this.filterRedList = this.users.filter((user) => user.score >= e.value);
    this.filterByScore(this.filter);
    this.filterOn = true;
  }

  private getExperienceLevel(): void {
    this.userService.getExperienceLevel().subscribe((data) => {
      console.log(data);
      this.experiencesLevel = data;
      // this.experiencesLevel.push({ id: -1, name: 'Tous' });
      // this.experiencesLevel = [this.experiencesLevel];
      this.experiencesLevel.forEach(element => {
        this.yAxe.push(element.id);
        this.xAxe.push(element.name);
      });
      const ctx = document.getElementById('expChart');
      this.experience = this.experiencesLevel[0];
      for (let i = 0; i < this.experiencesLevel.length; i++) {

        this.data.push({
          label: this.xAxe[i],
          backgroundColor: '#6dbc97',
          data: [{
            x: i,
            y: 0.5,
            r: 13,
          }],
        });
      }
      this.chart = new Chart(ctx, {
        type: 'bubble',
        data: {
          datasets: this.data,
        },
        options: {
          onClick: (e) => {
            const element = this.chart.getElementAtEvent(e);
            if (element.length > 0) {
              data = element[0]._datasetIndex;
              this.filterOn = true;
              this.updateChart(data);
            }
          },
          tooltips: {
            callbacks: {
              label: (t, d) => {
                return d.datasets[t.datasetIndex].label;
              }
            }
          },
          offset: true,
          responsive: true,
          autoSkip: false,
          layout: {
            padding: {
              right: 30,
              left: 0,
              top: 10
            }
          },
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          title: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                max: 1,
                min: 0,
                stepSize: 0.5
              },
              display: false,
              gridLines: {
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                fontFamily: 'Montserrat, sans-serif',
                autoSkip: false,
                callback: (value) => {
                  return this.xAxe[value];
                },
                maxRotation: 0,
                beginAtZero: true
              },
              display: true,
              gridLines: {
                display: false
              }
            }]
          }
        }
      });
    }, (err) => {
      // console.log(err);
    });
  }

  private updateChart(element: any): void {
    for (let index = 0; index < this.experiencesLevel.length; index++) {
      if (index === element) {
        this.chart.config.data.datasets[index].borderColor = '#f1ce49';
        this.chart.config.data.datasets[index].borderWidth = 2;
        this.experience = this.experiencesLevel[element];
        this.filter.experience = this.experiencesLevel[element].id;
      } else {
        this.chart.config.data.datasets[index].borderColor = 'rgba(0, 0, 0, 0.1)';
        this.chart.config.data.datasets[index].borderWidth = 0;
      }
    }
    this.chart.update();
    this.filterByExperience(this.filter);
  }

  public filters(element: any): void {
    if (element.experience === null) {
      this.filterRedList = this.users.filter((user) => user.score >= element.score);
    } else if (element.experience !== null && element.score !== 0) {
      this.filterRedList = this.users.filter((user) => JSON.parse(user.experienceLevel).id === element.experience);
    }
  }

  public loadPageDetail(page) {
    this.router.navigate([page])
  }

  public filterByExperience(element): void {
    console.log(element);
    this.filterRedList = this.users.filter((user) => JSON.parse(user.experienceLevel).id === element.experience);
    console.log(this.filterRedList);
  }

  resetFilters() {
    this.slidderDefaultValue = 0;
    this.filterRedList = this.users;
    for (let index = 0; index < this.experiencesLevel.length; index++) {
      this.chart.config.data.datasets[index].borderColor = 'rgba(0, 0, 0, 0.1)';
      this.chart.config.data.datasets[index].borderWidth = 0;
    }
    this.chart.update();
    this.filterOn = false;
  }

  chooseCandidat(job, user) {
    console.log({job, user});
    user.choosen = true;
    this.companyService.chooseUserForJob(this.company.id, {jobId: job.id, userId: user.id})
      .subscribe(data => {
        console.log({data})
      })
  }

  filterByScore(element): void {
    console.log(element);
    if (element.experience !== null) {
      this.filterRedList = this.users.filter((user) => user.score >= element.score);
    }
  }
  setStateShowPhone() {
    this.showPhone = !this.showPhone;
  }
}
