<div class="job-question-modal-header">
    <h4 mat-dialog-title>Ajouter des questions d'emploi</h4>
    <!-- <button mat-button (click)="onNoClick()">x</button> -->
</div>
<div mat-dialog-content class="modal-body">
    <ng-form [formGroup]="addQuestion" fxLayout="column" class="job-question-form">
        <div fxLayout="column" formArrayName="questionObjects"
            *ngFor="let orderForm of addQuestion.get('questionObjects')['controls'];let i = index;">
            <div fxLayout="column" class="job-question-column" [formGroupName]="i">
                <div fxLayout="row " class="col-lg-12 col-md-12 px-0 col-sm-12   form-conent">
                    <mat-form-field appearance="none" class="town-form-field">
                        <input type="text" formControlName="question" class="search-town" value="" matInput
                            placeholder="Question" #div>
                    </mat-form-field>
                    <span class="icon-after">
                        <mat-icon matSuffix svgIcon="icon_micro" (click)="voiceSearch(i)"></mat-icon>
                    </span>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" fxFlex="100">
                    <mat-form-field appearance="none" class="town-form-field" fxFlex="50">
                        <input type="text" formControlName="oui" value="" class="search-town" matInput
                            placeholder="Oui">
                    </mat-form-field>
                    <mat-form-field appearance="none" class="town-form-field" fxFlex="50">
                        <input type="text" formControlName="non" value="" class="search-town" matInput
                            placeholder="Non">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <button class="btn mbtnee blue btn-oval job-modal-button" fxFlex="25" (click)="addNewBloc();">
            <span class="botton-text">
                Ajouter une nouvelle question
            </span>
        </button>
    </ng-form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-button type="button" class="btn btn-outline-dark" cdkFocusInitial
        (click)="onNoClick();onSubmit()">Suivant</button>
</div>