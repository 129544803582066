<!-- Email subscribe -->
<mat-card class="emailer-card mb-4 text-center" fxHide.lt-md>
  <mat-card-content class="emailer-card-content">
    <h6>Recevez par e-mail les offres correspondant à votre recherche dès leur publications</h6>
    <mat-form-field appearance="none" class="email-form-field mb-3">
      <input class="form-control" matInput placeholder="e-mail">
    </mat-form-field>
    <button mat-button class="btn-send mb-4">
      <mat-icon svgIcon="icon_send"></mat-icon> Recevoir les offres
    </button>
    <mat-checkbox class="emailer-checkbox">Recevoir occasionnellement par email des Newsletters et
      informations
      de
      Gotaf, et des opportunités de carrière ciblées.</mat-checkbox>
    <mat-checkbox class="emailer-checkbox">J'ai lu et accepté la politique de confidentialité de
      Gotaf, ses
      conditions d'utilisation et son utilisation des cookies.</mat-checkbox>
  </mat-card-content>
</mat-card>

<!-- Article list -->
<div class="">
  <h4 class="text-center mb-3 right-side-title">Les actualités</h4>
<!--  <mat-card class="article-card mb-3">-->
<!--    &lt;!&ndash;                        <img mat-card-image src="assets/image/gotaf/article-image.jpg" alt="Article image"&ndash;&gt;-->
<!--    &lt;!&ndash;                            class="mat-card-image">&ndash;&gt;-->
<!--    &lt;!&ndash;                        <mat-card-content class="article-card-content">&ndash;&gt;-->
<!--    <a class="twitter-timeline" href="https://twitter.com/jadx36/lists/gouv-emploi-18374?ref_src=twsrc%5Etfw">A-->
<!--      Twitter List-->
<!--      by jadx36</a>-->
<!--    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>-->
<!--    &lt;!&ndash;                        </mat-card-content>&ndash;&gt;-->
<!--    &lt;!&ndash;                      <blockquote class="twitter-tweet">&ndash;&gt;-->
<!--    &lt;!&ndash;                        <p lang="en" dir="ltr">Sunsets don&#39;t get much better than this one over <a&ndash;&gt;-->
<!--    &lt;!&ndash;                          href="https://twitter.com/GrandTetonNPS?ref_src=twsrc%5Etfw">@GrandTetonNPS</a>. <a&ndash;&gt;-->
<!--    &lt;!&ndash;                          href="https://twitter.com/hashtag/nature?src=hash&amp;ref_src=twsrc%5Etfw">#nature</a>&ndash;&gt;-->
<!--    &lt;!&ndash;                          <a&ndash;&gt;-->
<!--    &lt;!&ndash;                            href="https://twitter.com/hashtag/sunset?src=hash&amp;ref_src=twsrc%5Etfw">#sunset</a>&ndash;&gt;-->
<!--    &lt;!&ndash;                          <a href="http://t.co/YuKy2rcjyU">pic.twitter.com/YuKy2rcjyU</a>&ndash;&gt;-->
<!--    &lt;!&ndash;                        </p>&mdash; US Department&ndash;&gt;-->
<!--    &lt;!&ndash;                        of the Interior (@Interior) <a&ndash;&gt;-->
<!--    &lt;!&ndash;                        href="https://twitter.com/Interior/status/463440424141459456?ref_src=twsrc%5Etfw">May 5,&ndash;&gt;-->
<!--    &lt;!&ndash;                        2014</a>&ndash;&gt;-->
<!--    &lt;!&ndash;                      </blockquote>&ndash;&gt;-->
<!--    &lt;!&ndash;                      <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>&ndash;&gt;-->
<!--  </mat-card>-->
  <mat-card class="article-card mb-3">
    <!--                        <img mat-card-image src="assets/image/gotaf/article-image.jpg" alt="Article image"-->
    <!--                            class="mat-card-image">-->
    <!--                        <mat-card-content class="article-card-content">-->
    <iframe src="https://open.spotify.com/embed-podcast/show/5C3GyTl0VnCeFNUj1dUiE3" width="100%" height="232"
      frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    <!--                        </mat-card-content>-->
  </mat-card>
<!--  <mat-card class="article-card mb-3">-->
<!--    &lt;!&ndash;                        <img mat-card-image src="assets/image/gotaf/article-image.jpg" alt="Article image"&ndash;&gt;-->
<!--    &lt;!&ndash;                            class="mat-card-image">&ndash;&gt;-->
<!--    &lt;!&ndash;                        <mat-card-content class="article-card-content">&ndash;&gt;-->
<!--    <iframe src="https://anchor.fm/jean-muriel-azonhoume/embed/episodes/Offres-demploi-gOtaf-es74h3" height="100%"-->
<!--      width="100%" frameborder="0" scrolling="no"></iframe>-->
<!--    &lt;!&ndash;                        </mat-card-content>&ndash;&gt;-->
<!--  </mat-card>-->
  <mat-card class="article-card mb-3">
    <!--                        <img mat-card-image src="assets/image/gotaf/article-image.jpg" alt="Article image"-->
    <!--                            class="mat-card-image">-->
    <!--                        <mat-card-content class="article-card-content">-->
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/IdF2atLLmrM" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    </iframe>
    <!--                        </mat-card-content>-->
  </mat-card>
</div>
