<div *ngIf='!editMode' (click)='editMode=true' class='cell' [innerHTML]="data"></div>
<input autofocus *ngIf="editMode && type === 'text' || type === 'number' && editMode " [(ngModel)]='data' name="data"
  class='cellInput' [type]="type">

<textarea *ngIf="editMode && type === 'textLong'" cols="5" rows="5" [(ngModel)]='data' name="data"
  class='cellInput'></textarea>


<select autofocus *ngIf="type === 'select' && editMode" [(ngModel)]="data" name="data"
  (focusout)="onFocusOut();editMode=false">
  <option>selectionner un item</option>
  <option *ngFor="let item of datas" [value]="item.id">{{item.name}}</option>
</select>

<!--<textarea autofocus *ngIf="editMode && type === 'textarea'" (focusout)="onFocusOut();editMode=false" [(ngModel)]='data' name="data"-->
<!--  class='cellInput'>-->
<!--</textarea>-->
<angular-editor autofocus *ngIf="editMode && type === 'textarea'" (focusout)="onFocusOut();editMode=false"
  [(ngModel)]="data" name="data" class='cellInput' [config]="config"></angular-editor>

<button *ngIf="editMode && type === 'text' || editMode && type === 'textLong' || type === 'number' && editMode "
  (click)="onFocusOut(); editMode=false">Valider</button>