<div>
  <div class="main-header-banner">
    <div class="header-banner">
      <img src="assets/image/compare-bg.jpg">
    </div>
  </div>
  <div class="apply-empl-pro-sec">
    <div class="container">
      <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="apply-emp-profile-box">
        <div fxFlex="45%" fxFlex.xs="100%" fxFlex.sm="100%">
          <div class="apply-emp-profile-main">
            <div class="apply-emp-profile">
              <img src="{{imageBaseUrl + user.image}}">

            </div>
            <div class="apply-emp-profile-info">
              <div class="apply-emp-dtil" style="text-align: center; display: grid;">
                <h3>{{ user.firstName}} {{ user.lastName}} </h3>
                <label>{{user.address}}</label>
                <!--<ul>
                  <li class="text-center">
                      <div class="blackstar" style="display: flex;">
                        <label [ngClass]="{'active': userResume?.experienceLevel.id>=1}" >
                          <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                        </label>
                        <label [ngClass]="{'active': userResume?.experienceLevel.id>=2}">
                          <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                        </label>
                        <label [ngClass]="{'active': userResume?.experienceLevel.id>=3}">
                          <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                        </label>
                      </div>
                    <app-edit-field style="text-transform: uppercase" [data]="cvForm.controls.experienceLevel.value" [type]="'select'" [datas]="experiencesLevel"
                                    (finalValue)="assignValue($event, 'experienceLevel')">
                    </app-edit-field>
                  </li>
              </ul>
              <span style="color: white;">{{userResume?.experienceLevel.name | uppercase}}</span>-->
                <!--experience chart-->
                <div class="char-container">
                  <canvas id="expChart" style="align-self: center;"></canvas>
                </div>
                <!--end experience chart-->
              </div>
              <div class="apply-emp-star">
                <ul class="reting-candi">
                  <li>
                    <div class="stars-apply">
                      <label>
                        <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                      </label>
                      <label>
                        <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                      </label>
                      <label>
                        <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                      </label>
                    </div>
                    <span style="color: #126154; text-transform: uppercase">expert</span>
                    <!--<app-edit-field style="text-transform: uppercase" [data]="cvForm.controls.experienceLevel.value" [type]="'select'" [datas]="experiencesLevel"
                                  (finalValue)="assignValue($event, 'experienceLevel')">
                  </app-edit-field>-->
                  </li>
                </ul>
                <div class="candidt-btm-btns" fxLayout="column" fxLayout.xs="row" fxLayout.sm="row">
                  <div *ngIf="!changeCategorie" class="categoryUser">
                    <button class="default-button-style" *ngFor="let categorie of userResume?.categories">
                      <mat-icon class="restauration-icon" svgIcon="{{categorie.image}}"></mat-icon>
                      {{categorie.name}}
                    </button>
                    <button mat-icon-button (click)="changeCategorieStatut()">
                      <mat-icon [ngClass]="{'icon-link': changeCategorie}"> edit</mat-icon>
                    </button>
                  </div>
                  <div *ngIf="changeCategorie" class="changeUserCategory">
                    <p class="swiper-div">
                      <mat-icon class="swipe-icon"> touch_app</mat-icon>
                    </p>
                    <div class="category-row">
                      <div class="bloc-category">
                        <div fxFlex="90px" fxFlex.xs="90px" fxFlex.sm="90px" fxFlex.md="90px" class="bloc-mat-tile"
                          *ngFor="let jobCategory of jobCategories; let i = index">
                          <button [ngClass]="{'selected-card': isSelect(i)}" class="mt-sm-0" mat-button
                            (click)="addToSelected(jobCategory, i)">
                            <p style="font-size: 8px; color: #2e4057; font-weight: 600; margin: 0"> {{
                              jobCategory.name }} </p>
                            <mat-icon [svgIcon]="jobCategory.image"></mat-icon>
                          </button>
                          <img class="category-audio-icon"
                            (click)="$event.stopPropagation();playAudioCat(imageBaseUrl+jobCategory.audio)"
                            src="assets/image/picto_audio_medium.png">
                        </div>
                      </div>
                    </div>
                  </div>
                  <button *ngIf="changeCategorie" mat-icon-button (click)="changeCategorieStatut()">
                    <mat-icon [ngClass]="{'icon-link': changeCategorie}"> edit</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="55%" fxFlex.xs="100%" fxFlex.sm="100%">
          <div class="candidt-info-main">
            <div class="candidt-info-left">
              <h3>Infos</h3>
              <!--<p style="overflow-x: auto;
text-overflow: ellipsis; ">{{userResume?.summary}} </p>-->
              <p style="overflow-x: auto;text-overflow: ellipsis;">
                <app-edit-field [data]="cvForm?.controls?.summary?.value" [type]="'textLong'"
                  (finalValue)="assignValue($event, 'summary')">
                </app-edit-field>
              </p>
            </div>
            <div class="candidt-info-right">
              <img src="assets/image/pexels-barthy-bonhomme.jpg">
              <div class="candidt-info-audio" style="display: inline">
                <mat-icon class="audio-icon" *ngIf="!changeAudio" svgIcon="audio"
                  (click)="playAudio(user.firstName,userResume?.audio, userResume)">
                </mat-icon>
                <div class="audio-reccord" *ngIf="changeAudio">
                  <app-reccord-audio (audioBlob)="buildAudioFile($event)"
                    (recordFile)="playRecordFile($event)" (show)="stopRec($event)">
                  </app-reccord-audio>
                </div>

                <div class="audio-reccord-delete-button" *ngIf="audioFile && changeAudio">
                  <button mat-raised-button (click)="deleteRecordFile()" class="action-button">Supprimer</button>
                </div>
                <div class="edit-audio">
                  <button mat-icon-button (click)="changeAudioStatut()" style="color: white;">
                    <mat-icon [ngClass]="{'icon-link': changeAudio}"> edit</mat-icon>
                    <!-- {{changeAudio === true ? 'Anuller' : 'Change cv Audio'}} -->
                  </button>
                </div>

                <!-- <mat-slide-toggle style="color: white;" [(ngModel)]="changeAudio">Change cv Audio</mat-slide-toggle> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- playAudio(user.name, imageBaseUrl + userResume.audio, userResume);-->
  <div class="pro-canddt">
    <div class="container">
      <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill fxLayoutGap="2%">
        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" class="hide-mobile">
          <div class="prfl-canddt">
            <h3 class="prfl-canddt-title">
              <mat-icon class="likeStar-icon" svgIcon="icon_likeStar"></mat-icon>
              <label>Avis employeurs</label>
            </h3>
            <div class="card">
              <div class="compare-likestar">
                <!-- TODO: integrer un slider horizontal de 4 éléments qui n'en affiche qu'un seul à la fois -->
                <p>" Recommandation laissée par un ancien employeur du candidat." </p>
                <ul>
                  <li>Marc, dirigeant <br>
                    entreprise X</li>
                  <li class="text-center">
                    <div class="blackstar">
                      <label>
                        <mat-icon class="blackStar-icon" svgIcon="icon_blackStar"></mat-icon>
                      </label>
                      <label>
                        <mat-icon class="blackStar-icon" svgIcon="icon_blackStar"></mat-icon>
                      </label>
                      <label>
                        <mat-icon class="blackStarblank-icon" svgIcon="icon_blackStarBlanck">
                        </mat-icon>
                      </label>
                    </div>
                    <span>2/3</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="prfl-canddt">
            <h3 class="prfl-canddt-title">
              <mat-icon class="qualifications-icon" svgIcon="icon_qualifications"></mat-icon>
              <label>Dernier diplôme</label>
            </h3>
            <div class="card">
              <div class="qualifications">

                <h3 style="width: min-content;">
                  <!--Avril<br>2010-->
                  <app-edit-field [data]="cvForm?.controls?.qualification?.value" [type]="'text'"
                    (finalValue)="assignValue($event, 'qualification')">
                  </app-edit-field>
                </h3>
                <div class="qualifications-info">
                  <h3>
                    <!--University of California, à BERKELEY-->
                    <app-edit-field [data]="cvForm?.controls.qualificationUniversity.value" [type]="'text'"
                      (finalValue)="assignValue($event, 'qualificationUniversity')">
                    </app-edit-field>
                  </h3>
                  <p>
                    <app-edit-field [data]="cvForm?.controls.qualificationDescription.value" [type]="'text'"
                      (finalValue)="assignValue($event, 'qualificationDescription')">
                    </app-edit-field>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="75%" fxFlex.xs="100%" fxFlex.sm="100%" class="hide-mobile">
          <div class="prfl-canddt">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h3 class="prfl-canddt-title">
                      <mat-icon class="experiences-icon" svgIcon="icon_experiences"></mat-icon>
                      <label>Expériences professionnelles</label>
                    </h3>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="card">
                  <div class="experiences" *ngFor="let experience of userResume?.experience;  let index = index">
                    <div class="exprync-img" [ngClass]="{'experienceDisplay': showCategoryExperience[index]}">
                      <img src="assets/image/rockwood-bordeaux-logo.png">
                      <mat-icon *ngIf="!showCategoryExperience[index]" class="restauration-icon" style="cursor: pointer"
                        svgIcon="{{experience.jobCategory.image}}" (click)="showCategoryExperience[index] = true">
                      </mat-icon>
                      <div *ngIf="showCategoryExperience[index]" class="experienceCategory">
                        <p class="swiper-div">
                          <mat-icon class="swipe-icon"> touch_app</mat-icon>
                        </p>
                        <div class="category-row">
                          <div class="bloc-category">
                            <div fxFlex="80px" fxFlex.xs="80px" fxFlex.sm="80px" fxFlex.md="80px" class="bloc-mat-tile"
                              *ngFor="let jobCategory of jobCategories; let i = index">
                              <button [ngClass]="{'selected-card': isSelect(i)}" class="mt-sm-0" mat-button
                                (click)="selectExpeCategory(jobCategory, index)" style="width: 70px; height: 70px;">
                                <p style="font-size: 8px; color: #2e4057; font-weight: 600; margin: 0px;"> {{
                                  jobCategory.name }} </p>
                                <mat-icon style="width: 25px; height: 25px;" [svgIcon]="jobCategory.image"></mat-icon>
                              </button>
                              <img class="category-audio-icon" style="width: 25px; height: 25px; left: 22px;"
                                (click)="$event.stopPropagation();playAudioCat(imageBaseUrl+jobCategory.audio)"
                                src="assets/image/picto_audio_medium.png">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="qualifications-info">
                      <h3>
                        <!--{{experience.title}}-->
                        <app-edit-field [data]="userResume.experience[index].title" [type]="'text'"
                          (finalValue)="assignValue($event, 'title'+index)">
                        </app-edit-field>
                        <span>
                          <!--{{experience.company}} {{experience.address}}-->
                          <app-edit-field [data]="userResume.experience[index].company" [type]="'text'"
                            (finalValue)="assignValue($event, 'company'+index)">
                          </app-edit-field> &nbsp;à&nbsp;
                          <app-edit-field [data]="userResume.experience[index].address" [type]="'text'"
                            (finalValue)="assignValue($event, 'address'+index)">
                          </app-edit-field>
                        </span>
                      </h3>
                      <label class="time-date" style="display: flex">
                        <!--{{experience.startAt}}-->
                        <app-edit-field [data]="userResume.experience[index].startAt" [type]="'text'"
                          (finalValue)="assignValue($event, 'startAt'+index)">
                        </app-edit-field>
                        &nbsp;à&nbsp;
                        <app-edit-field [data]="userResume.experience[index].endAt" [type]="'text'"
                          (finalValue)="assignValue($event, 'endAt'+index)">
                        </app-edit-field>
                        <!--{{experience.endAt}} - 1 an 4 mois-->
                      </label>
                      <p>
                        <!--{{experience.description}}-->
                        <app-edit-field [data]="userResume.experience[index].description" [type]="'textarea'"
                          (finalValue)="assignValue($event, 'description'+index)">
                        </app-edit-field>
                      </p>
                    </div>
                  </div>
                  <div style="text-align: center">
                    <button mat-raised-button class="action-button" style="background-color: #8ddca5"
                      (click)="submit()">
                      <span class="botton-text">
                        Sauvegarder
                      </span>
                    </button>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>