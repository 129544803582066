<div>
    <div fxLayout="row nowrap" fxFlexFill fxLayoutAlign="space-between stretch" class="homepage-header"
        fxShow.xs="false">
        <div class="navbar-title" fxFlex="1 1" fxFlexFill fxShow.xs="false">
        </div>
    </div>
    <div class="route-history">
    </div>
    <div class="jd-bg" style="margin-bottom: 5rem;">
        <div class="bounds">
            <div class="content" fxLayout="row wrap" fxLayout.xs="column" fxFlexFill fxLayoutAlign="space-between">
                <div class="jd-acco" fxFlex="4" fxShow="false" fxShow.xs="true">
                    <div style="margin-top: 15px; font-size: 12px; color: #03334F;">
                    </div>
                </div>
                <div class="jd-ttl" fxFlex="6" fxShow="false" fxShow.xs="true" style="font-size: 23px; color: #03334F;">
                </div>
                <!-- premiere column -->
                <div fxFlex="15" fxFlex.md="15" fxFlex.sm="35" class="sec1 jd-img pic-upload" fxFlex.xs="30"
                    style="background-image: url({{ url ? url : 'assets/image/votre-offre.jpg' }})">
                    <button class="btn mbtn blue btn-oval job-modal-button" (click)="openSuggestionDialog();">
                        <span class="botton-text">
                            Suggestions d'image
                        </span>
                    </button>
                    <div class="apply-emp-profile">
                        <label class="hoverable" for="fileInput">
                            <!-- <img [src]="url ? url :'assets/image/votre-offre.jpg' "> -->
                            <div class="hover-text">Choisir une image</div>
                            <div class="background"></div>
                        </label>
                        <br />
                        <input id="fileInput" type='file' (change)="onSelectFile($event)" accept="image/*">
                        <button *ngIf="url" mat-raised-button (click)="delete()" class="action-button">Suppimer</button>
                    </div>
                </div>
                <div class="jd-job-ttl" fxLayout="row" fxLayoutAlign="space-between" fxFlex="10" fxShow="false"
                    fxShow.xs="true">
                    <div fxFlex="20" fxLayoutAlign="center">
                        <img style="cursor: pointer;" src="{{ imageBaseUrl + company?.logo }}" width="130px">
                    </div>
                    <div fxFlex="60" fxLayoutAlign="start" fxLayout="column">
                        <div fxFlex="40" fxLayoutAlign="start">
                            <div class="text-truncate" style="font-weight: bold; font-size: 20px; color: #42475E">
                            </div>
                        </div>
                        <div fxFlex="40">
                            <div style="font-size: 11px; color: #42475E; text-transform: capitalize;"
                                class="text-truncate">
                                <app-edit-field #jobType value="select Type de Job">{{ jobType?.value }}
                                </app-edit-field>
                                <app-edit-field #address
                                    value="Define address and update marker in mat tab automatically">{{ address?.value
                                    }}</app-edit-field>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="20" fxLayoutAlign="center">
                        <!--         TODO: replace by the recorder   <img class="job-audio-icon" src="assets/image/picto_audio_medium.png"-->
                        <!--                 (click)="playAudio(job?.jobName, job?.audio, job);">-->
                    </div>
                </div>
                <!-- second column -->
                <!-- <div fxFlex="26" fxFlex.md="26" class="sec2" fxFlex.sm="60" fxShow="true" fxShow.xs="false" fxShow.sm="true" -->
                <div fxFlex="26" fxFlex.md="26" class="sec2" fxFlex.sm="60" fxShow="true" fxShow.sm="true"
                    fxShow.lg="true" fxShow.md="true">
                    <!-- column to contain third part into second column  -->
                    <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="100">
                        <!-- first row imagee/facebook/linkedin with space between -->
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="22">
                            <div fxFlex="30" fxLayoutAlign="start">
                                <img class="companyIcon" style="cursor: pointer; height: 44px; width: 44px;"
                                    src="{{ imageBaseUrl + company?.logo }}">
                            </div>
                            <div fxFlex="30" fxLayoutAlign="end">
                                <button mat-icon-button>
                                    <a target="_blank" rel="noopener noreferrer" disabled>
                                        <mat-icon svgIcon="facebook"></mat-icon>
                                    </a>
                                </button>
                                <button mat-icon-button>
                                    <a target="_blank" rel="noopener noreferrer" disabled>
                                        <mat-icon svgIcon="icon_linkedin"></mat-icon>
                                    </a>
                                </button>
                            </div>
                        </div>
                        <!-- second row but he must contain two different bloc -->
                        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="25">
                            <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="100">
                                <div fxFlex="65" fxLayoutAlign="start">
                                    <div class="text-truncate"
                                        style="font-weight: bold; font-size: 20px; color: #42475E">
                                        <app-edit-field [data]="jobForm.controls.name.value" [type]="'text'"
                                            (finalValue)="assignValue($event, 'name')">
                                        </app-edit-field>
                                    </div>
                                </div>
                                <div fxFlex="30" fxLayoutAlign="end">
                                    <div
                                        style="font-weight: bold; font-size: 16px; color: #42475E; word-wrap: break-word;">
                                        <app-edit-field [data]="jobForm.controls.salary.value" [type]="'number'"
                                            (finalValue)="assignValue($event, 'salary')">
                                        </app-edit-field>
                                    </div>
                                </div>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="100">
                                <div fxFlex="65" fxLayoutAlign="start">
                                    <div style="font-size: 11px; color: #42475E; text-transform: capitalize; text-align: left;"
                                        class="text-truncate">
                                        <app-edit-field [data]="jobForm.controls.typeJob.value" [type]="'select'"
                                            [datas]="types" (finalValue)="assignValue($event, 'typeJob')">
                                        </app-edit-field>
                                        <app-edit-field [data]="jobForm.controls.address.value" [type]="'text'"
                                            (finalValue)="assignValue($event, 'address')">
                                        </app-edit-field>
                                    </div>
                                </div>
                                <div fxFlex="40" fxLayoutAlign="end" style="text-align: right;">
                                    <strong>Net / Mois ou Heures </strong>
                                </div>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="100">
                                <!-- <button class="button" fxFlex="35"> -->
                                <strong style="font-weight: bold;">
                                    <app-edit-field [data]="jobForm.controls.JobCategoryId.value" [type]="'select'"
                                        [datas]="jobCategories" (finalValue)="assignValue($event, 'JobCategoryId')">
                                    </app-edit-field>
                                </strong>
                            </div>
                        </div>

                        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="45">
                            <div fxLayout="row" fxLayoutAlign="space-evenly center" fxFlex="40">
                                <app-reccord-audio (audioBlob)="buildAudioFile($event)"
                                    (recordFile)="playRecord($event)" (show)="stop($event)"></app-reccord-audio>
                                <div>
                                    <button (click)="isOrderedJob = true" [ngClass]="{'ordered_audio': isOrderedJob}"
                                        title="Commander un audio" mat-mini-fab color="secondary"
                                        class="btn-headphone-fab" style="width: 5em;
                                        height: 5em; border-radius: 50%;">
                                        <mat-icon>add_shopping_cart</mat-icon>
                                    </button>
                                </div>
                                <div>
                                    <button (click)="openTemplateAudioDialog()" title="Template audio" mat-mini-fab
                                        color="secondary" class="btn-headphone-fab" style="width: 5em;
                                    height: 5em;border-radius: 50%;">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                            width="24px" fill="#000000">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path
                                                d="M9 21c0 .5.4 1 1 1h4c.6 0 1-.5 1-1v-1H9v1zm3-19C8.1 2 5 5.1 5 9c0 2.4 1.2 4.5 3 5.7V17c0 .5.4 1 1 1h6c.6 0 1-.5 1-1v-2.3c1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7z" />
                                        </svg> </button>
                                </div>
                            </div>
                            <button class="btn mbtn blue btn-oval job-modal-button" fxFlex="25" (click)="openDialog();">
                                <span class="botton-text">
                                    Ajouter des questions d'emploi
                                </span>
                            </button>

                            <button class="btn mbtn blue btn-oval" fxFlex="25" (click)="submit()">
                                <span class="botton-text">
                                    Publier l'offre
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- thrird column -->
                <div fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="100" class="sec3" fxLayoutAlign="center">
                    <mat-tab-group class="remove-border-bottom">
                        <mat-tab label="Mission">
                            <ng-template class="mat-design" mat-tab-label>
                                <mat-icon class="tab-icon-space" svgIcon="icon_mission"></mat-icon>
                                <div style="text-align: start;">Missions</div>
                            </ng-template>
                            <div style="text-align: start;">
                                <app-edit-field [data]="jobForm.controls.mission.value" [type]="'textarea'"
                                    (finalValue)="assignValue($event, 'mission')">
                                </app-edit-field>
                            </div>
                        </mat-tab>
                        <mat-tab label="Localisation">
                            <ng-template class="mat-design" mat-tab-label>
                                <mat-icon class="tab-icon-space" svgIcon="icon_map"></mat-icon>
                                <div style="text-align: start;">Localisation</div>
                            </ng-template>
                            <div style="text-align: start;">
                                <google-map height="185px" width="100%" [zoom]="zoom" [center]="center"
                                    [options]="options">
                                    <map-marker [position]="center" [options]="markerOptions"></map-marker>
                                    <!-- <map-marker [position]="position" [label]="marker.label" -->

                                </google-map>

                            </div>
                        </mat-tab>
                        <mat-tab label="Profil Recherché">
                            <ng-template mat-tab-label>
                                <mat-icon class="tab-icon-space" svgIcon="icon_profile"></mat-icon>
                                Profil Recherché
                            </ng-template>

                            <div style="text-align: start;">
                                <app-edit-field [data]="jobForm.controls.profile.value" [type]="'textarea'"
                                    (finalValue)="assignValue($event, 'profile')">
                                </app-edit-field>
                            </div>
                        </mat-tab>
                        <mat-tab label="L'entreprise">
                            <ng-template mat-tab-label>
                                <div>
                                    <mat-icon class="tab-icon-space" svgIcon="icon_company"></mat-icon>
                                    <span class="my-custom-class">L'entreprise</span>
                                </div>
                            </ng-template>
                            <div style="text-align: start;" [innerHTML]="company?.summary" disabled></div>
                        </mat-tab>
                    </mat-tab-group>
                </div>

                <div class="jd-btn" fxFlex="20" fxShow="false" fxShow.xs="true" fxLayout.xs="column" fxLayout.sm="row"
                    fxLayoutAlign="space-between">
                    <button class="btn mbtn blue" fxFlex="45" fxFlex.sm="45">
                        <mat-icon class="tab-icon-space" svgIcon="icon_applied"></mat-icon>
                        <span class="botton-text">
                            Candidature Simplifiée
                        </span>
                    </button>
                   <!--<button class="btn mbtnee blue btn-ova" fxFlex="45" fxFlex.sm="45">
                        <mat-icon class="tab-icon-space" svgIcon="icon_contact"></mat-icon>
                        <span style="color: #68827e;" class="botton-text">Contacter l'employeur</span>
                    </button>-->
                    <!--          <div (click)="getCompanyJobsOnJobs()" fxFlex="45" fxFlex.sm="45"-->
                    <!--            style="color: #42475E; text-decoration: underline; font-size: 14px; font-weight: 500; cursor: pointer; text-align: center;">-->
                    <!--            Voir les toutes les offres proposées par {{ job?.companyName }}-->
                    <!--          </div>-->
                </div>

            </div>
        </div>
    </div>

</div>
<!--audio player-->
<div class="playerDiv" *ngIf="showPlayer">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="closePlayer()">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
    <app-audio-player></app-audio-player>
</div>
<!--end audio player-->
