import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class ItineraryOptionsService {
public timeDistenceRequest:any;

  constructor() { }

  public getVehicle(transportMode, currentLatitude, currentLongitude, destinationLatitude, destinationLongitude) {

    const origin = new google.maps.LatLng(currentLatitude, currentLongitude); // using google.maps.LatLng class
    const destination = destinationLatitude + ', ' + destinationLongitude; // using string
    
    if (transportMode == 'DRIVING') {
      this.timeDistenceRequest = {
        origin, // LatLng|string
        destination, // LatLng|string
        travelMode: google.maps.DirectionsTravelMode.DRIVING
      };
    }
    else if (transportMode == 'WALKING') {
      this.timeDistenceRequest = {
        origin, // LatLng|string
        destination, // LatLng|string
        travelMode: google.maps.DirectionsTravelMode.WALKING
      };
    }
    else if (transportMode == 'TRANSIT') {
      this.timeDistenceRequest = {
        origin, // LatLng|string
        destination, // LatLng|string
        travelMode: google.maps.DirectionsTravelMode.TRANSIT
      };
    }
    else {
      this.timeDistenceRequest = {
        origin, // LatLng|string
        destination, // LatLng|string
        travelMode: google.maps.DirectionsTravelMode.BICYCLING
      };
    }
    return this.directionsServiceFunction(this.timeDistenceRequest).then(data => {
      return data;
    });
  }

  public directionsServiceFunction(request): Promise<any> {
    var directionsService = new google.maps.DirectionsService();
    return new Promise((resolve, reject) => {
      directionsService.route(request,
        (response, status) => {
          if (status === 'OK') {
            var point = response.routes[0].legs[0];
            resolve(point.distance.text + ' / ' + point.duration.text);
          } else {
            // alert('Directions request failed due to ' + status);
            reject(new Error(status));
          }
        }
      );
    });
  }
}
