import { ModalCustomService } from './../service/dialog/modal-custom.service';
import { TextToSpeechService } from './../service/text-to-speech/text-to-speech.service';
import { ReccordAudioService } from './../service/reccord-audio/reccord-audio.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersComponent } from './components/filters/filters.component';
import { MatIconModule } from '@angular/material/icon';
import { JobsService } from '../service/jobs/jobs.service';
import { CategorySearchComponent } from './components/category-search/category-search.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { ReccordAudioComponent } from './reccord-audio/reccord-audio.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ModalCustomComponent } from './modal-custom/modal-custom.component';
import { MatDialogModule } from '@angular/material/dialog';
import { YoutubeComponent } from './youtube/youtube.component';
import { SocialSharedComponent } from './social-shared/social-shared.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { YtPlayerAngularModule } from 'yt-player-angular';
import { Meta } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EditFieldComponent } from './edit-field/edit-field.component';
import { FormsModule } from '@angular/forms';
import { EditTextComponent } from './edit-text/edit-text.component';
import { SharedServiceService } from './shared-service.service';
import {AngularEditorModule} from '@kolkov/angular-editor';



@NgModule({
  declarations: [FiltersComponent,
    CategorySearchComponent,
    ReccordAudioComponent,
    PaginationComponent,
    ModalCustomComponent,
    YoutubeComponent,
    SocialSharedComponent,
    EditFieldComponent,
    EditTextComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule,
    ShareButtonsModule,
    YtPlayerAngularModule,
    FlexLayoutModule,
    FormsModule,
    AngularEditorModule
  ],
  exports: [FiltersComponent, CategorySearchComponent, ReccordAudioComponent, PaginationComponent, EditFieldComponent],
  providers: [JobsService, ReccordAudioService, TextToSpeechService, ModalCustomService, Meta, SharedServiceService]
})
export class SharedModule { }
