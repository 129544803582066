import { Component, OnInit } from '@angular/core';
import {SharedServiceService} from '../../shared/shared-service.service';
import {environment} from '../../../environments/environment.prod';

@Component({
  selector: 'app-presse',
  templateUrl: './presse.component.html',
  styleUrls: ['./presse.component.css', '../../../../node_modules/bootstrap/dist/css/bootstrap.css', '../../shared/css/single-job.style.css']
})
export class PresseComponent implements OnInit {


  public showPlayer = false;
  public imageBaseUrl: string = environment.imageBaseUrl;
  constructor(
    private sharedService: SharedServiceService,
    ) { }

  ngOnInit(): void {
    this.sharedService.navigation.next(false);
  }

  /**
   * openDownloadLink
   */
  public openDownloadLink(): void {

    window.open('', 'blank');

  }

  /**
   * downloadFile
   * url: string
   */
  public downloadFile(url: string): void {
    const anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', '');
    document.body.appendChild(anchor);
    anchor.click();
    anchor.parentNode.removeChild(anchor);
  }

}
