import {Component, Inject} from '@angular/core';
import {Message} from './message';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

export enum TypeSnack {
  VALID,
  INFO,
  WARN,
  ERROR
}

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.css']
})
export class SnackBarComponent {
  public message: string;
  public valid: boolean = false;
  public info: boolean = false;
  public warn: boolean = false;
  public error: boolean = false;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Message) {
    this.message = data.message;
    this.valid = this.isType(TypeSnack.VALID, data.type);
    this.info = this.isType(TypeSnack.INFO, data.type);
    this.warn = this.isType(TypeSnack.WARN, data.type);
    this.error = this.isType(TypeSnack.ERROR, data.type);
  }

  private isType(type: number, id: number): boolean {
    if (type === id) {
      return true;
    }
  }
}
