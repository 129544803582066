import { Component, OnInit } from '@angular/core';
import { JobsService } from 'src/app/service/jobs/jobs.service';
import { SnackService } from 'src/app/service/snack/snack.service';
import {AuthenticationService} from '../../../service/authentication/authentication.service';
import {environment} from '../../../../environments/environment';
import {AudioService} from '../../../service/audio.service';
import {AudioFileService} from '../../../service/audiofile.service';

@Component({
  selector: 'app-job-applied',
  templateUrl: './user-job-applied.component.html',
  styleUrls: ['./user-job-applied.component.css', './../../../../../node_modules/bootstrap/dist/css/bootstrap.css']
})
export class UserJobAppliedComponent implements OnInit {
  public jobsApplied: any;
  public imageBaseUrl: string = environment.imageBaseUrl;
  public audioSource: string;
  constructor(
              private jobsService: JobsService,
              private snack: SnackService,
              private authenticationService: AuthenticationService,
              private audioService: AudioService,
              private audioFileService: AudioFileService,
  ) { }

  ngOnInit(): void {
    this.getAllJobApplied(this.authenticationService.userValue.id);
  }


  public getAllJobApplied(userId): void {
    this.jobsService.getJobApplied(userId).subscribe((data) => {
      this.jobsApplied = data;
    }, (err) => {
      this.snack.error('Erreur lors de la récupération des données.');
    });
  }

  public playAudio(audioName: string, audioToPlay: string, job: any, isUserJob:boolean = false): void {
    if(isUserJob && job.userJobAudio)
    {
      job = { audio : job.userJobAudio};
    }
    this.audioFileService.addToPlayer(job);
    this.audioSource = audioToPlay;
    this.audioService.getAudioFile(audioName);
  }

}
