import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { JobsService } from 'src/app/service/jobs/jobs.service';
import { SnackService } from 'src/app/service/snack/snack.service';
import { TextToSpeechService } from '../../../service/text-to-speech/text-to-speech.service';

interface UserScoreInterface {
  arrayIndex: number;
  score: number;
}
@Component({
  selector: 'app-job-application',
  templateUrl: './job-application.component.html',
  styleUrls: ['./job-application.component.css', '../../../../../node_modules/bootstrap/dist/css/bootstrap.css']
})
export class JobApplicationComponent implements OnInit {
  public jobId: number;
  public isFromExternalUrl: number;
  public firstScreen = true;
  public lastScreen = false;
  public showQuestion = false;
  public questionList: any[] = [];
  public compagnyName;
  public canWindowClosed: boolean;
  scoreUser = 100;
  private userScoreArray: UserScoreInterface[] = [];
  // public user: User;
  userConnected = JSON.parse(localStorage.getItem('user'));
  private audioUrl = 'assets/audio/first-screen-job-application.wav';
  private audio = new Audio();

  constructor(
    private route: ActivatedRoute,
    private snack: SnackService,
    private jobsService: JobsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private speech: TextToSpeechService,
  ) {
    if (this.route.snapshot.params.refresh === 1) {
      window.location.reload();
    }
    // this.authenticationService.user.subscribe(x => this.user = x
    // );
    // this.userConnected = this.user;
  }

  ngOnInit(): void {
    this.jobId = this.route.snapshot.params.job_id;
    this.isFromExternalUrl = this.route.snapshot.params.is_from_externurl ? this.route.snapshot.params.is_from_externurl : 0;
    this.canWindowClosed = true;

    if(this.userConnected)
      console.log(this.userConnected.jobIdsHaveAudio);
    console.log(this.jobId);
    console.log(this.route.snapshot.params.is_from_externurl);
    if ((this.userConnected && !this.userConnected.jobIdsHaveAudio.includes(+this.jobId)) || !this.userConnected) {
      this.router.navigate(['/user-cv-create'], { queryParams: { returnUrl: '/job-application/' + this.jobId + '/' + this.isFromExternalUrl } });
    } else {
      this.getJobQuestions(this.jobId);
    }
  }

  public getJobQuestions(id: number): void {
    this.jobsService.getJobQuestion(id).subscribe((data: any) => {
      this.compagnyName = data.companyName.name;
      if (data.questions !== null && data.questions.questions !== null) {
        this.questionList = JSON.parse(data.questions.questions);
        if (this.questionList.length === 0) {
          this.firstScreen = false;
          this.showQuestion = false;
          this.lastScreen = true;
          this.scoreUser = 100;
          this.submit();
        } else {
          this.firstScreen = true;
          this.showQuestion = false;
          this.lastScreen = false;
        }
      } else {
        this.firstScreen = false;
        this.showQuestion = false;
        this.lastScreen = true;
        this.scoreUser = 100;
        this.submit();
      }
    }, (err) => {
      this.snack.error('Erreur lors de la récupération des questions');
    });
  }

  public gotToNext(index: number): void {
    // if (index === this.questionList?.length) {
    //   this.submit();
    //   this.lastScreen = true;
    // }
  }

  public goToScreen(): void {
    this.firstScreen = false;
    this.showQuestion = true;
    this.lastScreen = false;
  }

  public calculeScore(value: number, i: number): void {
    const currentQuestionScore: UserScoreInterface = {
      arrayIndex: i,
      score: Number(value)
    };
    if (i + 1 === this.questionList?.length) {
      if (this.userScoreArray.length === 0) {
        this.userScoreArray.push(currentQuestionScore);
      } else {
        const isExist = this.userScoreArray.findIndex((data) => data.arrayIndex === i);
        if (isExist === -1) {
          this.userScoreArray.push(currentQuestionScore);
        } else {
          this.userScoreArray[isExist] = currentQuestionScore;
        }
      }
      this.submit();
      this.showQuestion = false;
      this.lastScreen = true;
    } else {
      if (this.userScoreArray.length === 0) {
        this.userScoreArray.push(currentQuestionScore);
      } else {
        const isExist = this.userScoreArray.findIndex((data) => data.arrayIndex === i);
        if (isExist === -1) {
          this.userScoreArray.push(currentQuestionScore);
        } else {
          this.userScoreArray[isExist] = currentQuestionScore;
        }
      }
    }
  }

  public submit(): void {
    if (this.userScoreArray.length !== 0) {
      this.scoreUser = this.userScoreArray.reduce((acc, item) => {
        return acc + item.score;
      }, 0);
    }

    this.jobsService.jobApplication({
      jobId: this.jobId,
      score: this.scoreUser
    }, this.userConnected.id).subscribe((data) => {
      this.snack.info('Bravo! Candidature envoyée pour ce taf!');
      this.lastScreen = true;
      this.showQuestion = false;
    }, (err) => {

    });
  }

  public play(): void {
    // tslint:disable-next-line: no-unused-expression
    this.audio.paused;
    this.audio.src = this.audioUrl;
    this.audio.load();
    this.audio.play();
  }


  public playQuestion(message): void {
    this.speech.play(message);
  }

  public closeWindow() {
    window.close();
    // if close not firing, show message to close manually

    setTimeout(() => {
      this.canWindowClosed = false;
    }, 200);
  }
}
