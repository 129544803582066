import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cgv',
  templateUrl: './cgv.component.html',
  styleUrls: ['./cgv.component.css', '../../../../../node_modules/bootstrap/dist/css/bootstrap.css', '../../../shared/css/single-job.style.css', '../../../shared/css/cgu.style.css']
})
export class CgvComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
