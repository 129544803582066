<div style="background: white">
  <div fxLayout="row nowrap" fxFlexFill fxLayoutAlign="space-between stretch" class="cgu-header">
    <div class="navbar-title" fxFlex="1 1" fxFlexFill>
      <h1> Cookies </h1>
    </div>
  </div>
  <div style="margin: 0 10px 0 30px;">

    <p> </p>
    <p>Ce site utilise des cookies afin d’améliorer votre expérience et optimiser nos sites et services.</p>
    <p>Un cookie est un petit fichier de texte transféré à partir d’un serveur web vers votre navigateur web ou votre disque dur lorsque vous visitez un site web. Si votre navigateur web est configuré pour accepter les cookies, ils seront stockés dans le navigateur web ou le disque dur jusqu’à ce que la date d’expiration soit dépassée ou jusqu’à ce que vous supprimez les cookies vous-même.</p>
    <p>Vous trouverez ci-dessous les explications de notre utilisation des cookies sur nos sites Web et la raison pour laquelle nous les utilisons.</p>
    <hr />
    <p> </p>
    <p> </p>
    <p> </p>
    <table>
      <tbody>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Cookie</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Détails</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Nom et échéance</p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Cookie information</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Ce cookie est utilisé par la charte de confidentialité pour éviter à l’utilisateur de les accepter à nouveau.</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>cookie_notice_accepted = toujours</p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Facebook Pixel</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Nous utilisons le pixel Facebook pour mesurer la performance de nos annonces Facebook. Pour en savoir plus, reportez-vous à l’adresse <a href="https://www.google.com/url?q=https://www.facebook.com/about/privacy/&amp;sa=D&amp;source=editors&amp;ust=1618444693303000&amp;usg=AOvVaw3EsHVlrVLJfNIYcb6wIkZI">https://www.facebook.com/about/privacy/</a></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Pour plus d’informations sur les cookies et leurs différentes dates d’expiration, veuillez consulter la politique de confidentialité de Facebook pour obtenir plus de détails.</p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Google Analytics</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Cookies de Google Analytics – Nous utilisons ces cookies pour recueillir des informations sur la façon dont les visiteurs utilisent nos sites web, y compris les détails du site que le visiteur a parcouru avant d’arriver sur notre site, et le nombre total de fois qu’un visiteur a visité notre site. Nous utilisons les informations pour optimiser nos sites web et nos services, et pour améliorer l’expérience de nos utilisateurs. Les cookies ne vous identifient pas personnellement. Ils se contentent de collecter de façon anonyme des informations qui sont transmises et stockées par Google sur des serveurs aux États-Unis conformément à ses pratiques de confidentialité. Pour en savoir plus sur les pratiques de confidentialité de Google Analytics, cliquez sur : <a href="https://www.google.com/url?q=https://support.google.com/analytics/answer/6004245&amp;sa=D&amp;source=editors&amp;ust=1618444693304000&amp;usg=AOvVaw2o073BWvnI8SPT1HgR43Mr">https://support.google.com/analytics/answer/6004245</a></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>__utma = 2 ans</p>
          <p>__utmb = 30 minutes</p>
          <p>__utmc = Fin de la session de navigateur</p>
          <p>__utmz = 6 mois</p>
          <p>__utmv = 2 ans</p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>HubSpot</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Avec HubSpot, nous analysons ce que vous visitez et comment vous visitez notre page Web. Plus d’informations sur : <a href="https://www.google.com/url?q=https://knowledge.hubspot.com/articles/kcs_article/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser&amp;sa=D&amp;source=editors&amp;ust=1618444693306000&amp;usg=AOvVaw002GNYwFA3MeogjdIAgQKz">https://knowledge.hubspot.com/articles/kcs_article/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser</a></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Pour plus d’informations sur les cookies avec différentes dates d’expiration, veuillez consulter la politique de confidentialité de HubSpot pour obtenir plus de détails.</p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>LinkedIn</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Nous utilisons la fonction Tag-insight de LinkedIn pour mesurer la performance de nos annonces LinkedIn. Pour en savoir plus, visitez <a href="https://www.google.com/url?q=https://www.linkedin.com/legal/cookie-table&amp;sa=D&amp;source=editors&amp;ust=1618444693307000&amp;usg=AOvVaw15zMn61sutv9UXIHo1W1SF">https://www.linkedin.com/legal/cookie-table</a>.</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Pour d’avantage d’informations sur les cookies avec différentes dates d’expiration, veuillez consulter la politique de confidentialité de LinkedIn pour obtenir plus de détails.</p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Localization</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Ce cookie stocke la version linguistique du site que l’utilisateur consulte.</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>_icl_current_language</p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Mailchimp</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Avec Mailchimp, nous analysons ce que vous visitez et comment vous visitez notre page Web après avoir cliqué sur un lien dans notre Newsletter. Plus d’information sur : <a href="https://www.google.com/url?q=https://mailchimp.com/legal/cookies/&amp;sa=D&amp;source=editors&amp;ust=1618444693309000&amp;usg=AOvVaw2dsmzDZSoG5XqcjMtqsETn">https://mailchimp.com/legal/cookies/</a>.</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>goal = 1 an</p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>GetQuanty</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Nous utilisons GetQuanty pour analyser comment vous visitez notre site Web pour améliorer les processus de marketing et de vente B2B. More information at: <a href="https://www.google.com/url?q=https://www.getquanty.com/.&amp;sa=D&amp;source=editors&amp;ust=1618444693311000&amp;usg=AOvVaw3kTqFnxzLDXpn_eq4sWex6">https://www.getquanty.com/.</a></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Un certain nombre de cookies avec différentes dates d’expiration, veuillez consulter la politique de confidentialité de GetQuanty pour obtenir plus de détails.</p>
        </td>
      </tr>
      </tbody>
    </table>
    <p>Vous pouvez activer ou désactiver les cookies en modifiant les paramètres dans votre navigateur. Découvrez comment procéder et trouvez plus d’informations sur les cookies en cliquant sur : <a href="https://www.google.com/url?q=http://www.allaboutcookies.org/&amp;sa=D&amp;source=editors&amp;ust=1618444693312000&amp;usg=AOvVaw1GlomEFtycFySN2IZLh3eP">www.allaboutcookies.org</a></p>
  </div>
</div>
<br>
<mat-toolbar-row fxLayout="row wrap" fxLayoutAlign="space-evenly center" class="footer-toolbar-row" id="footerNav">
  <div class="row" fxLayout="row wrap">
    <div class="left-navbar-bloc" style="text-align: left" fxFlex>
      <span> Nous croyons dans le potentiel de chacun<br>Retrouvez-nous sur les réseaux sociaux</span>
    </div>
  </div>
  <span class="navbar-spacer"></span>
  <div class="row" fxLayout="row wrap">
    <div class="right-navbar-bloc" fxFlex fxLayoutAlign="flex-end center">
      <a target="_blank" href="https://www.facebook.com/gotafemploi" style="text-decoration: none;">
        <mat-icon svgIcon="icon_facebook"></mat-icon>
      </a>
      <a target="_blank" href="https://www.instagram.com/gotafemplois/" style="text-decoration: none;">
        <mat-icon svgIcon="icon_instagram"></mat-icon>
      </a>
      <a target="_blank" href="https://youtu.be/I5WrwtfknxE"
         style="text-decoration: none;">
        <mat-icon svgIcon="icon_youtube"></mat-icon>
      </a>
      <a target="_blank" href="https://www.tiktok.com/@gotaf21?lang=fr" style="text-decoration: none;">
        <mat-icon svgIcon="icon_tiktok"></mat-icon>
      </a>
      <a target="_blank" href="https://www.twitch.tv/gotaf21" style="text-decoration: none;">
        <mat-icon svgIcon="icon_twitch"></mat-icon>
      </a>
      <a target="_blank" href="https://www.linkedin.com/company/gotaf" style="text-decoration: none;">
        <mat-icon svgIcon="icon_linkedin"></mat-icon>
      </a>
    </div>
  </div>
</mat-toolbar-row>
