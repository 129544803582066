<div class="container ngx-audioplayer py-2"
  [ngClass]="{'is-conncet-fixed-bottoms': user, 'is-disconnect-fixed-bottoms': !user, 'player-to-top': screenWidth < 600, 'player-to-bottom': screenWidth > 600}">
  <div class="media-footer row m-0 plyr-mobile">
    <div class="text-white col-6 col-md-6 col-lg-3 text-center">
      <p> {{ currentFileName}}</p>
    </div>
    <div class="col-4 col-md-5 col-lg-4">
      <mat-toolbar color="primary" class="mat-toolbar-icon small-icon">
        <mat-toolbar-row class="media-action-bar text-center justify-content-center align-items-center">
          <button mat-button class="no-mobile">
            <mat-icon mat-list-icon> shuffle</mat-icon>
          </button>
          <button mat-button [disabled]="isFirstPlaying()" (click)="previous()">
            <mat-icon mat-list-icon>skip_previous</mat-icon>
          </button>
          <button mat-button (click)="play()" [disabled]="state?.error" *ngIf="!state?.playing">
            <mat-icon mat-list-icon>play_circle_filled</mat-icon>
          </button>
          <button mat-button (click)="pause()" *ngIf="state?.playing">
            <mat-icon mat-list-icon>pause</mat-icon>
          </button>
          <button mat-button [disabled]="isLastPlaying()" (click)="next()">
            <mat-icon mat-list-icon>skip_next</mat-icon>
          </button>
          <button mat-button (click)="repeat()" class="no-mobile small-icon">
            <mat-icon mat-list-icon> repeat</mat-icon>
          </button>
        </mat-toolbar-row>

        <mat-toolbar-row class="mat-slider-in no-mobile">
          <div class="no-mobile">{{ state?.readableCurrentTime }}</div>
          <mat-slider class="time-slider w-100 text-white" min="0" [max]="state?.duration" step="1"
            [value]="state?.currentTime" (input)="onSliderChangeEnd($event)"
            [disabled]="state?.error || currentFile.index === undefined">
          </mat-slider>
          <div class="no-mobile">{{ state?.readableDuration }}</div>
        </mat-toolbar-row>

      </mat-toolbar>
    </div>
    <div class="col-5 col-md-2 no-mobile">
      <div class="d-flex align-items-center h-100 justify-content-center">
        <button class="bg-transparent btn border-left small-icon" (click)="applyJob()">
          <mat-icon class="playlist-icon" svgIcon="icon_postuler"> </mat-icon> <span class="text-white"> Postuler
          </span>
        </button>
      </div>
    </div>
    <div class="col-7 col-md-7 col-lg-2 no-mobile">
      <div class="d-flex align-items-center h-100 w-100">
        <button mat-button (click)="mute()" class="border-left d-inline-block small-icon">
          <mat-icon class="text-white" *ngIf="val" mat-list-icon> volume_up</mat-icon>
          <mat-icon class="text-white" *ngIf="!val" mat-list-icon> volume_off</mat-icon>
        </button>
        <mat-slider class="volume-slider w-50" min="0" [max]="1" step=".1" [value]="state?.volume"
          (input)="onVolumeChangeEnd($event)"></mat-slider>
      </div>
    </div>
    <div class="col-2 col-md-1 col-lg-1">
      <div class="d-flex align-items-center h-100 justify-content-center keyboard_arrow_list">
        <button (click)="showPlayList()" class="bg-transparent btn border-left">
          <mat-icon class="playlist-icon" style="color: white;">
            {{showplaylist ? ' keyboard_arrow_down' : 'keyboard_arrow_up'}}</mat-icon> <span class="text-white"></span>
        </button>
      </div>
    </div>
    <div class="col-12 moble-time-slide no-web">
      <mat-slider class="time-slider w-100 text-white" min="0" [max]="state?.duration" step="1"
        [value]="state?.currentTime" (input)="onSliderChangeEnd($event)"
        [disabled]="state?.error || currentFile.index === undefined">
      </mat-slider>
    </div>

  </div>
  <div class="content" *ngIf="showplaylist">
    <mat-list color="primary">
      <div class="no-web">
        <div class="d-flex justify-content-between align-items-center pl-4 pr-4 pt-2 pb-2 ttl-vlum">
          <mat-label class="text-white radiotaf-txt">Radiotaf</mat-label>
          <div class="d-flex align-items-center justify-content-end h-100 w-80">
            <button mat-button (click)="mute()" class="d-inline-block small-icon">
              <mat-icon class="text-white" *ngIf="val" mat-list-icon> volume_up</mat-icon>
              <mat-icon class="text-white" *ngIf="!val" mat-list-icon> volume_off</mat-icon>
            </button>
            <mat-slider class="volume-slider w-50" min="0" [max]="1" step=".1" [value]="state?.volume"
              (input)="onVolumeChangeEnd($event)"></mat-slider>
          </div>
        </div>
      </div>

      <p class="d-flex justify-content-between align-items-center px-4"> <span class="left no-mobile">
          <mat-form-field class="example-form-field">
            <mat-label class="text-white">Rechercher</mat-label>

            <input matInput type="text" [(ngModel)]="value">

          </mat-form-field>
        </span>
        <!-- <span class="right">
          <mat-icon (click)="showplayList()" class="text-white">keyboard_arrow_up</mat-icon>
        </span> -->
      </p>
      <!-- <p class="d-flex justify-content-between align-items-center px-5">
        <span class="d-flex text-white"> Postes à pourvoir</span>
        <span class="d-flex text-white"> Statut</span>
        <span class="d-flex text-white"> Secteurs</span>
        <span class="d-flex text-white">Entreprises</span>
        <span class="d-flex text-white">Lieux</span>
        <span class="d-flex text-white">Temps</span>
      </p>
      <div style="height: 150px; overflow: auto;">
        <mat-list-item *ngFor="let file of files; let i = index" (click)="openFile(file, i)">
          <mat-icon class="text-white" mat-list-icon>play_arrow</mat-icon>
          <h4 class="text-white d-inline" mat-line> <span> {{ file.jobName }}</span> <span> {{ file.artist }} </span></h4>
          <mat-icon class="text-white">favorite_border</mat-icon>
          <mat-icon class="text-white">keyboard_arrow_right</mat-icon>
          <h6 *ngIf="currentFile.index === i && state?.error">ERROR</h6>
          <mat-divider></mat-divider>
        </mat-list-item>
      </div> -->

      <div class="example-table-container no-mobile">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 text-whitr" style="width: 100%;">
          <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

          <ng-container matColumnDef="play">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <mat-icon class="text-white" mat-list-icon (click)="openFile(element, i)">play_arrow</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="PostesApourvoir">
            <th mat-header-cell *matHeaderCellDef> Postes à pourvoir </th>
            <td mat-cell style="color: white;" *matCellDef="let element" style="cursor: pointer;"
              routerLink="/job/{{element.id}}" routerLinkActive="router-link-active">
              {{element.jobName === undefined ? element.name : element.jobName}} </td>
          </ng-container>

          <ng-container matColumnDef="Statut">
            <th mat-header-cell *matHeaderCellDef> Statut </th>
            <td mat-cell *matCellDef="let element"> {{element.weight === undefined ? null : element.weight}} </td>
          </ng-container>

          <ng-container matColumnDef="Secteurs">
            <th mat-header-cell *matHeaderCellDef> Secteurs </th>
            <td mat-cell *matCellDef="let element"> {{element.jobCategoryName}} </td>
          </ng-container>

          <ng-container matColumnDef="Entreprises">
            <th mat-header-cell *matHeaderCellDef> Entreprises </th>
            <td mat-cell *matCellDef="let element"> {{ element.companyName === '' ? 'N/A' : element.companyName |
              uppercase }} </td>
          </ng-container>

          <ng-container matColumnDef="Lieux">
            <th mat-header-cell *matHeaderCellDef> Lieux </th>
            <td mat-cell *matCellDef="let element"> {{element.address}} </td>
          </ng-container>

          <ng-container matColumnDef="Temps">
            <th mat-header-cell *matHeaderCellDef> Temps </th>
            <td mat-cell *matCellDef="let element"> {{element.jobType}} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <mat-icon class="text-white" (click)="abbJobToFavorite(element.id)">
                {{element.jobFavorite == 1 ? 'favorite': 'favorite_border'}}</mat-icon>
              <mat-icon class="text-white" (click)="removeOnList(i)">close</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>

      <div class="no-web mobile-player-list-main pl-4 pr-4">
        <div class="mobile-player-list">

          <div class="player-icn" *ngFor="let element of dataSource.data; let i = index"
            [ngClass]="{active: isActive(i)}">
            <button mat-mini-fab color="secondary" class="btn-headphone-fab">
              <mat-icon svgIcon="headphone_audio" (click)="openFile(element, i)"></mat-icon>
            </button>
            <div class="player-ttl-desc">
              <h3> {{element.jobName === undefined ? element.name : element.jobName}} </h3>
              <p>{{element.address === undefined ? '' : element.address}}</p>
            </div>
            <div class="player-share-fav">
              <a class="card-bottom-icon share-icon">
                <mat-icon>share</mat-icon>
              </a>
              <a class="card-bottom-icon favorite-icon">
                <mat-icon>favorite_border</mat-icon>
              </a>
            </div>
          </div>

        </div>
      </div>
    </mat-list>
  </div>
</div>
