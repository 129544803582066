import { Component, Inject, OnInit } from '@angular/core';
import { CategoriePoleEmploi, categorieCommercePoleEmploie, categorieBtpPoleEmploie, categorieLogiquePoleEmploie, categorieServicePoleEmploie, categorieHotelRestoPoleEmploie } from '../../../shared/const/suggestions';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { SharedServiceService } from '../../../shared/shared-service.service';
import { Platform } from '@angular/cdk/platform';
// import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JobImageSuggessionComponentComponent } from '../job-image-suggession-component/job-image-suggession-component.component';
import { TextToSpeechService } from 'src/app/service/text-to-speech/text-to-speech.service';
import { Job } from 'src/app/model/job';
import { HttpClientService } from 'src/app/service/http-client/http-client.service';


interface DialogData {
  job: any
}
@Component({
  selector: 'app-job-template-audio',
  templateUrl: './job-template-audio.component.html',
  styleUrls: ['./job-template-audio.component.css']
})
export class JobTemplateAudioComponent implements OnInit {
  public categories: any[];
  public categoriePoleEmploie: any[] = [];
  public sousCategoriePoleEmploie: any[] = [];
  code: string;
  templates = [{
    id: 1,
    name: "Template 1",
    description: '',
    template: " L'entreprise {companyName} leader dans le dommaine de {categoryName} recherche un ou une {jobName} pour un contrat {jobType}",
    playing: false,
    fields: ['companyName', 'categoryName', 'jobName', 'jobType']
  }, {
    id: 2,
    name: "Template 2",
    description: '',
    template: "L'entreprise {companyName} recherche un ou une {jobName} pour un contrat {jobType} payé {jobSalaire} euros. Ce poste est ouvert aux handicapé et vous travaillez nombre d\'heure par semaine.",
    playing: false,
    fields: ['companyName', 'categoryName', 'jobName', 'jobSalaire', ]
  }, {
    id: 3,
    name: "Template 3",
    description: '',
    template: "{companyName} recherche un ou une {jobName} pour renforcer son équipe.  Vous serez payé {jobSalaire} pour nombre d'heure par semaine. Vous disposez de 12 RTT et de 20 jours de congés par an. Ce poste est ouvert aux handicapé et à tous les profils en reconversion professionnelles.",
    playing: false,
    fields: ['companyName', 'categoryName', 'jobName', 'jobSalaire', 'rtt', 'congeDay']
  }]
  choosenTemplate = null;
  templateForm : FormGroup = null;
  step = 'template_choice';
  constructor(private formBuilder: FormBuilder,
    private httpClient: HttpClientService,
    public dialog: MatDialog,
    public _dialogRef: MatDialogRef<JobTemplateAudioComponent>,
    private speech: TextToSpeechService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
    this.speech.onEnd(this.pauseAll.bind(this));
    this.templateForm = this.formBuilder.group({
      companyName: '',
      jobCategory: '',
      jobName: '',
      jobType: '',
      jobSalaire: '',
      rtt: '',
      congeDay: '',
    });
    if (this.data.job) {
      const job = this.data.job;
      this.templateForm.patchValue({
        companyName: job.companyName,
        jobCategory: job.jobCategoryName,
        jobName: job.jobName,
        jobType: job.jobType,
        jobSalaire: job.salary,
      })
      this.templates.forEach(t => {
        t.description = t.template;
        t.description = t.description.replace('{companyName}', job.companyName || 'x');
        t.description = t.description.replace('{categoryName}', job.jobCategoryName || 'x');
        t.description = t.description.replace('{jobName}', job.jobName || 'x');
        t.description = t.description.replace('{jobType}', job.jobType || 'x');
        t.description = t.description.replace('{jobSalaire}', job.salary || 'x');
      })
    }
  }

  chooseTemplate(template) {
    this.choosenTemplate = template;
    this.step = 'template_field_set';
  }

  submit() {
    const t = this.choosenTemplate;
    const data = this.templateForm.controls;
    t.description = t.template;
    t.description = t.description.replace('{companyName}', data.companyName.value);
    t.description = t.description.replace('{categoryName}', data.jobCategory.value);
    t.description = t.description.replace('{jobName}', data.jobName.value);
    t.description = t.description.replace('{jobType}', data.jobType.value);
    t.description = t.description.replace('{jobSalaire}', data.jobSalaire.value);
    t.description = t.description.replace('{rtt}', data.rtt.value);
    t.description = t.description.replace('{congeDay}', data.congeDay.value);
    console.log({dddd: t.description})
    this._dialogRef.close(t.description)
  }

  playTemplateAudio(template) {
    this.pauseAll();
    template.playing = true;
    this.speech.play(template.description)
  }
  pauseTemplateAudio(template) {
    template.playing = false;
    this.speech.pause();
  }
  pauseAll() {
    this.templates.forEach(t => t.playing = false);
  }

}
