import { Component, ElementRef, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-edit-field',
  templateUrl: './edit-field.component.html',
  styleUrls: ['./edit-field.component.css']
})
export class EditFieldComponent implements OnInit {
  @Input() data: string;
  @Input() size = '100px';
  @Input() type: string;
  @Input() datas: any[] = [];
  public config: AngularEditorConfig = {
    height: this.size,
    minHeight: '5rem',
    editable: true
  };

  @Output() finalValue: EventEmitter<string> = new EventEmitter<string>();
  editMode = false;
  constructor() { }

  ngOnInit() { }

  onFocusOut() {
    console.log(this.data);
    this.finalValue.emit(this.data);
  }
}
