<div class="main-header-banner">
  <div class="header-banner">
    <img src="assets/image/compare-bg.jpg">
  </div>
</div>
<form [formGroup]="companyInformation" (ngSubmit)="saveCompanyInformations()">
  <div class="apply-empl-pro-sec apply-dasbord">
    <div class="container">
      <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="apply-emp-profile-box">
        <div fxFlex="45%" fxFlex.xs="100%" fxFlex.sm="100%">
          <div class="apply-emp-profile-main">
            <div class="company-logo">
              <label class="hoverable" for="fileInput">
                <img style="object-fit: contain" [src]="url ? url : imageBaseUrl + company.logo">
                <div class="hover-text">Choisir une image</div>
                <div class="background"></div>
              </label>
              <br />
              <input id="fileInput" type='file' (change)="onSelectFile($event)" accept="image/*">
              <button *ngIf="url" mat-raised-button (click)="delete()" class="action-button">Suppimer</button>
              <!-- <button *ngIf="url" (click)="delete()">Suppimer</button> -->
              <!-- <img src="{{imageBaseUrl + user.image}}"> -->
            </div>
            <div class="apply-emp-profile-info">
              <!-- <div class="apply-emp-dtil">
                <h3>{{ user.name}} {{ user.lastName}} </h3>
                <label>{{user.address}}</label>
              </div> -->
              <div class="apply-emp-star">
                <!-- <ul class="reting-candi">
                  <li>
                    <div class="stars-apply">
                      <label class="active">
                        <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                      </label>
                      <label class="active">
                        <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                      </label>
                      <label class="active">
                        <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                      </label>
                    </div>
                  </li>
                </ul> -->
                <div class="candidt-btm-btns" fxLayout="column">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="55%" fxFlex.xs="100%" fxFlex.sm="100%">
          <div class="candidt-info-main">
            <mat-form-field appearance="none" style="width: 100%;">
              <h4>
                <mat-label>Nom</mat-label>
              </h4>
              <label>
                <input class="login-input" matInput placeholder="Nom" formControlName="name" type="text"
                       value="{{ company.name}}">
              </label>
<!--              <div *ngIf="!companyInformation.controls.name.valid"-->
<!--                   style="color:red;margin-top:10px;">-->
<!--                <div *ngIf="companyInformation.controls.name.hasError('required')">-->
<!--                  First Name Is Required-->
<!--                </div>-->
<!--              </div>-->
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dash-form">
    <div class="container">
      <div class="dash-form-box">
        <div [formGroup]="companyInformation" fxLayout="column" fxLayoutAlign="center start">
          <div class="dash-form-inr">
            <mat-form-field appearance="none">
              <h4>
                <mat-label>Contact</mat-label>
              </h4>
              <label>
                <input class="login-input" matInput placeholder="Contact" formControlName="phone" type="text"
                       value="{{ company.phone}}">
              </label>
<!--              <div *ngIf="!companyInformation.controls.phone.valid"-->
<!--                   style="color:red;margin-top:10px;">-->
<!--                <div *ngIf="companyInformation.controls.phone.hasError('required')">-->
<!--                  Contact Is Required-->
<!--                </div>-->
<!--                &lt;!&ndash; <div *ngIf="companyInformation.controls.email.hasError('required')">-->
<!--                  Email Is required-->
<!--                </div> &ndash;&gt;-->
<!--              </div>-->
            </mat-form-field>
            <mat-form-field appearance="none">
              <h4>
                <mat-label>Siret</mat-label>
              </h4>
              <label>
                <input class="login-input" matInput placeholder="Siret" formControlName="siret" type="text"
                       value="{{ company.siret}}">
              </label>
<!--              <div *ngIf="!companyInformation.controls.siret.valid"-->
<!--                   style="color:red;margin-top:10px;">-->
<!--                <div *ngIf="companyInformation.controls.siret.hasError('reuired')">-->
<!--                  Email Is Not Valid-->
<!--                </div>-->
<!--                &lt;!&ndash; <div *ngIf="companyInformation.controls.email.hasError('required')">-->
<!--                  Email Is required-->
<!--                </div> &ndash;&gt;-->
<!--              </div>-->
            </mat-form-field>
            <mat-form-field appearance="none">
              <h4>
                <mat-label>Adresse</mat-label>
              </h4>
              <label>
                <input class="login-input" matInput placeholder="Adresse" formControlName="address" type="text"
                       value="{{ company.address}}">
              </label>
<!--              <div *ngIf="!companyInformation.controls.address.valid"-->
<!--                   style="color:red;margin-top:10px;">-->
<!--                <div *ngIf="companyInformation.controls.address.hasError('required')">-->
<!--                  Phone Number Is Not Valid-->
<!--                </div>-->
<!--              </div>-->
            </mat-form-field>
            <mat-form-field appearance="none" class="input-with-icon">
              <h4>
                <mat-label>Site Internet</mat-label>
              </h4>
              <label>
                <input class="login-input" matInput placeholder="Site Internet" formControlName="website" type="text"
                       value="{{ company.website}}">
              </label>
              <!--              <div *ngIf="!companyInformation.controls.website.valid"-->
              <!--                   style="color:red;margin-top:10px;">-->
              <!--                <div *ngIf="companyInformation.controls.website.hasError('required')">-->
              <!--                  Phone Number Is Not Valid-->
              <!--                </div>-->
              <!--              </div>-->
            </mat-form-field>
          </div>
          <div class="dash-form-inr">
            <mat-form-field appearance="none">
              <h4>
                <mat-label>Facebook</mat-label>
              </h4>
              <label>
                <input class="login-input" matInput placeholder="Facebook" formControlName="facebook" type="text"
                       value="{{ company.facebook}}">
              </label>
<!--              <div *ngIf="!companyInformation.controls.facebook.valid"-->
<!--                   style="color:red;margin-top:10px;">-->
<!--                <div *ngIf="companyInformation.controls.facebook.hasError('required')">-->
<!--                  Phone Number Is Not Valid-->
<!--                </div>-->
<!--              </div>-->
            </mat-form-field>
            <mat-form-field appearance="none" class="input-with-icon">
              <h4>
                <mat-label>LinkedIn</mat-label>
              </h4>
              <label>
                <input class="login-input" matInput placeholder="linkedIn" formControlName="linkedIn" type="text"
                       value="{{ company.linkedIn}}">
              </label>
              <!--              <div *ngIf="!companyInformation.controls.linkedIn.valid"-->
              <!--                   style="color:red;margin-top:10px;">-->
              <!--                <div *ngIf="companyInformation.controls.linkedIn.hasError('required')">-->
              <!--                  Phone Number Is Not Valid-->
              <!--                </div>-->
              <!--              </div>-->
            </mat-form-field>
          </div>
          <div class="center-form-inr">
            <mat-form-field appearance="none">
              <h4>
                <mat-label>Description</mat-label>
              </h4>
              <label>
                <textarea style="height: 14em;" class="login-input" matInput placeholder="Description" formControlName="summary" type="text"
                          value="{{ company.summary}}"></textarea>
              </label>
<!--              <div *ngIf="!companyInformation.controls.summary.valid" style="color:red;margin-top:10px;">-->
<!--                <div *ngIf="companyInformation.controls.summary.hasError('required')">-->
<!--                  Phone Number Is Not Valid-->
<!--                </div>-->
<!--              </div>-->
            </mat-form-field>
          </div>
            <div class="dash-form-btn validate-btn">
              <!-- <button class="action-button" (click)="validateSecondBlock()">Validate</button> -->
              <!-- <button mat-raised-button (click)="savecompanyInformations()" class="action-button">Enregistrer</button> -->
              <button mat-raised-button type="submit" class="action-button">Enregistrer</button>
            </div>


        </div>
      </div>
    </div>
  </div>
  </form>
    <!-- <div class="apply-empl-pro-sec" ng-form class="login-form" [formGroup]="companyInformation">
  <div class="container">
    <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="apply-emp-profile-box">
      <div class="apply-emp-profile-main" fxFlex="45%" fxFlex.xs="100%" fxFlex.sm="100%">
        <div class="apply-emp-profile">
          <img src="{{imageBaseUrl + user.image}}">
          upload new image
          <input (change)="onPersonEdit($event)" accept="image/*" type="file" />
        </div>
        <div fxLayout="column" fxLayoutAlign="space-evenly center" style="margin: 1px;">
          <label class="form-label">Prénom</label>
          <input class="input-form" formControlName="firstName" type="text" value="{{ user.firstName}}">
        </div>

        <div fxLayout="column" fxLayoutAlign="space-evenly center" style="margin: 1px;">
          <label class="form-label">Nom</label>
          <input class="input-form" formControlName="lastName" type="text" value="{{ user.lastName}}">
        </div>

        <div fxLayout="column" fxLayoutAlign="space-evenly center" style="margin: 1px;">
          <label class="form-label">Email</label>
          <input class="input-form" formControlName="email" type="text" value="{{ user.email}}">
        </div>

        <div fxLayout="column" fxLayoutAlign="space-evenly center" style="margin: 1px;">
          <label class="form-label">Mot de passe</label>
          <input class="input-form" formControlName="password" type="password" value="{{ user.password}}">
        </div>

        <div fxLayout="column" fxLayoutAlign="space-evenly center" style="margin: 1px;">
          <label class="form-label">Tépléphone</label>
          <input class="input-form" formControlName="phone" type="text" value="{{ user.phone}}">
        </div>

        <div fxLayout="column" fxLayoutAlign="space-evenly center" style="margin: 1px;">
          <label class="form-label">Adresse</label>
          <input class="input-form" formControlName="adress" type="text" value="{{ user.address}}">
        </div>

        <div fxLayoutAlign="center center">
          <button mat-raised-button (click)="savecompanyInformations()">Enregistrer</button>
        </div>
      </div>
    </div>
  </div> -->
