import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { SnackBarComponent, TypeSnack } from '../../shared/components/snack-bar/snack-bar.component';
import { TextToSpeechService } from '../text-to-speech/text-to-speech.service';

function _window(): any {
  return window;
}
@Injectable({
  providedIn: 'root'
})
export class SnackService {
  constructor(protected snackBar: MatSnackBar,
    private ttsService: TextToSpeechService,
    private plt: Platform,
  ) {
  }

  public global(displayMessage: string, type?: TypeSnack): Observable<void> {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 3000,
      data: {
        message: displayMessage,
        type: type == null ? TypeSnack.INFO : type
      }
    }).afterOpened();
  }

  public warn(message: string): Observable<void> {
    if ((this.plt.ANDROID || this.plt.IOS) && (this.plt.EDGE == false && this.plt.FIREFOX == false && this.plt.SAFARI == false && this.plt.TRIDENT == false)) {
      this.warnshow(message);
    }
    else {
      this.ttsService.play(message);
      return this.global(message, TypeSnack.WARN);
    }
  }
  public warnshow(message: string) {
    _window().plugins.toast.showWithOptions(
      {
        message: message,
        duration: "short",
        position: "bottom",
        styling: {
          opacity: 0.75,
          backgroundColor: '#000000',
          textColor: '#03B49F',
          textSize: 13,
          cornerRadius: 16,
          horizontalPadding: 20,
          verticalPadding: 16
        }
      }
    );
  }
 
  public info(message: string): Observable<void> {
    if ((this.plt.ANDROID || this.plt.IOS) && (this.plt.EDGE == false && this.plt.FIREFOX == false && this.plt.SAFARI == false && this.plt.TRIDENT == false)) {
      this.show(message);
    }
    else {
      this.ttsService.play(message);
      return this.global(message, TypeSnack.INFO);
    }
  }
  public show(message: string) {
    _window().plugins.toast.showWithOptions(
      {
        message: message,
        duration: "short",
        position: "bottom",
        styling: {
          opacity: 0.75,
          backgroundColor: '#000000',
          textColor: '#03B49F',
          textSize: 13,
          cornerRadius: 16,
          horizontalPadding: 20,
          verticalPadding: 16
        }
      }
    );
  }

  public error(message: string): Observable<void> {
    if ((this.plt.ANDROID || this.plt.IOS) && (this.plt.EDGE == false && this.plt.FIREFOX == false && this.plt.SAFARI == false && this.plt.TRIDENT == false)) {
      this.errorshow(message);
    }
    else {
      this.ttsService.play(message);
      return this.global(message, TypeSnack.ERROR);
    }
  }

  public errorshow(message: string) {
    _window().plugins.toast.showWithOptions(
      {
        message: message,
        duration: "short",
        position: "bottom",
        styling: {
          opacity: 0.75,
          backgroundColor: '#000000',
          textColor: '#f90000',
          textSize: 13,
          cornerRadius: 16,
          horizontalPadding: 20,
          verticalPadding: 16
        }
      }
    );
  }
 
  public valid(message: string): Observable<void> {
    if ((this.plt.ANDROID || this.plt.IOS) && (this.plt.EDGE == false && this.plt.FIREFOX == false && this.plt.SAFARI == false && this.plt.TRIDENT == false)) {
      this.show(message);
    }
    else {
      this.ttsService.play(message);
      return this.global(message, TypeSnack.VALID);
    }
  }
}

