<div class="main-header-banner">
    <div class="header-banner">
        <img src="assets/image/plan-bg.jpg">
        <div class="on-header-img">
            <h1>Des offres conçues pour tous</h1>
            <p></p>
        </div>
    </div>
</div>



<div class="plan-main">
    <div class="container">
       <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between" class="pull-up">
       	<swiper *ngIf="type == 'component' && show" class="swiper-container" fxFlex="auto" [config]="config" [disabled]="disabled" (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')" (swiperTransitionEnd)="onSwiperEvent('transitionEnd')" fxLayout="row wrap" fxLayout.xs="row wrap" fxLayout.sm="row wrap" fxFlexFill >
       		<div *ngFor="let subscription of subscriptionList;">
	            <div fxFlex="294px" fxFlex.xs="100%" fxFlex.sm="100%" class="plan-inr swiper-slide">
	            	<mat-card>
	            		<div class="plan-card">
	            			<mat-icon class="card-audio-icon" svgIcon="audio"></mat-icon>
	            			<span class="plan-title">{{ subscription.name }}</span>
	            			<div class="plan-card-img">
	            				<img src="{{ imageBaseUrl + subscription.image }}" style="object-fit: cover;">
	            			</div>
	            			<strong>{{ subscription.price }}</strong>
	            			<label class="price-lbl">par publications</label>
	            			<div class="plan-card-desc">
	            				<ul>
	            					<li>3 mois de visibilité</li>
	            					<li>Sélection de 100 candidats</li>
	            					<li>Tableau de bord personnalisé</li>
	            				</ul>
	            			</div>
	            			<button class="button-grad" (click)="submit(subscription)">
	                            <mat-icon class="plan-icon" svgIcon="icon_plan"></mat-icon>
	                            <span>Je me lance</span>
	                        </button>
	            		</div>
	            	</mat-card>
	            </div>
        	</div>
        </swiper>
        <button class="button-grad btn-mob">
            <mat-icon class="plan-icon" svgIcon="icon_publier"></mat-icon>
            <span>Publier une offre</span>
        </button>


        </div>
    </div>
</div>


<div class="main-header-banner plan-main-app">
    <div class="header-banner">
        <img src="assets/image/plan-bg2.jpg">
        <div class="on-header-img mobile-apps">
	        <div class="container">
	        	<div fxLayout="row" fxLayout.xs="column" fxLayout.sm="row" fxLayoutAlign="space-between">
		            <div class="plan-inr" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="50%">
		            	<h1>Restez à l'écoute<br> des offres d'emploi<br> où que vous soyez</h1>
		            </div>
		            <div class="plan-inr" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="50%">
		            	<div class="app-download">
		            		<a href="" target="_blank"><mat-icon class="app-srore" svgIcon="app_store"></mat-icon></a>
		            		<a href="" target="_blank"><mat-icon class="play-srore" svgIcon="play_store"></mat-icon></a>
		            	</div>
		            </div>
		        </div>
		    </div>
        </div>
    </div>
</div>

<div class="plan-table" fxShow="true" fxShow.xs="false">
	<div class="container">
		<table width="100%">
			<thead>
				<tr>
					<th>Je compare les offres</th>
					<th *ngFor="let subscription of subscriptionList;">
						<div class="plan-table-img">
							<img src="{{ imageBaseUrl + subscription.image }}"> {{ subscription.name }}
						</div>
					</th>
					<!-- <th>
						<div class="plan-table-img">
							<img src="assets/image/gotaf-person/plan-avtar-1.png"> PRO
						</div>
					</th>
					<th>
						<div class="plan-table-img">
							<img src="assets/image/gotaf-person/plan-avtar-1.png"> PREMIUM
						</div>
					</th> -->
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>3 mois de visibilité</td>

					<td *ngFor="let subscription of subscriptionList; ">
					<mat-icon *ngIf="subscription.threeMonth==1" svgIcon="icon_tick"></mat-icon>
					<mat-icon *ngIf="subscription.threeMonth!=1" svgIcon="icon_cross"></mat-icon>
					</td><!--
					<td><mat-icon svgIcon="icon_tick"></mat-icon></td>
					<td><mat-icon svgIcon="icon_tick"></mat-icon></td> -->
				</tr>
				<tr>
					<td>Sélection de 100 candidats</td>
					<td *ngFor="let subscription of subscriptionList; ">
					<mat-icon *ngIf="subscription.hundredUser==1" svgIcon="icon_tick"></mat-icon>
					<mat-icon *ngIf="subscription.hundredUser!=1" svgIcon="icon_cross"></mat-icon>
					</td>

				</tr>
				<tr>
					<td>Tableau de bord personnalisé</td>
					<td *ngFor="let subscription of subscriptionList; ">
					<mat-icon *ngIf="subscription.dashboard==1" svgIcon="icon_tick"></mat-icon>
					<mat-icon *ngIf="subscription.dashboard!=1" svgIcon="icon_cross"></mat-icon>
					</td>
				</tr>
				<tr>
					<td>Audio by voix off PRO OTAF</td>
					<td *ngFor="let subscription of subscriptionList; ">
					<mat-icon *ngIf="subscription.orderedAudio==1" svgIcon="icon_tick"></mat-icon>
					<mat-icon *ngIf="subscription.orderedAudio!=1" svgIcon="icon_cross"></mat-icon>
					</td>
				</tr>
				<tr>
					<td>Modification gratuite de l'offre</td>
					<td *ngFor="let subscription of subscriptionList; ">
					<mat-icon *ngIf="subscription.freeModify==1" svgIcon="icon_tick"></mat-icon>
					<mat-icon *ngIf="subscription.freeModify!=1" svgIcon="icon_cross"></mat-icon>
					</td>
				</tr>
				<tr>
					<td>Publications illimitées</td>
					<td *ngFor="let subscription of subscriptionList; ">
					<mat-icon *ngIf="subscription.unlimitedPost==1" svgIcon="icon_tick"></mat-icon>
					<mat-icon *ngIf="subscription.unlimitedPost!=1" svgIcon="icon_cross"></mat-icon>
					</td>
				<tr>
					<td>Sélection illimitée de candidats</td>
					<td *ngFor="let subscription of subscriptionList; ">
					<mat-icon *ngIf="subscription.unlimitedUser==1" svgIcon="icon_tick"></mat-icon>
					<mat-icon *ngIf="subscription.unlimitedUser!=1" svgIcon="icon_cross"></mat-icon>
					</td>
				</tr>
				<tr>
					<td></td>
					<td><a href=""><mat-icon class="plan-icon" svgIcon="icon_plan"></mat-icon><span>Je me lance</span></a></td>
					<td><a href=""><mat-icon class="plan-icon" svgIcon="icon_plan"></mat-icon><span>Je me lance</span></a></td>
					<td><a href=""><mat-icon class="plan-icon" svgIcon="icon_plan"></mat-icon><span>Je me lance</span></a></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>


<div class="qus-ans">
	<div class="container">
		<h3 class="qus-ans-title">Questions fréquemment posées</h3>
		<ul>
			<li>
				<select class="qus-ans-select" style="background-image: url(assets/image/down-arrow.svg)">
					<option>Question xxxxxxxxxxxxxxxxx</option>
				</select>
			</li>
			<li>
				<select class="qus-ans-select" style="background-image: url(assets/image/down-arrow.svg)">
					<option>Question xxxxxxxxxxxxxxxxx</option>
				</select>
			</li>
			<li>
				<select class="qus-ans-select" style="background-image: url(assets/image/down-arrow.svg)">
					<option>Question xxxxxxxxxxxxxxxxx</option>
				</select>
			</li>
			<li>
				<select class="qus-ans-select" style="background-image: url(assets/image/down-arrow.svg)">
					<option>Question xxxxxxxxxxxxxxxxx</option>
				</select>
			</li>
		</ul>
	</div>
</div>


<div class="kone-sec">
	<div class="container">
		<h3 class="kone-title">Ils nous font confiance</h3>
		<p class="kone-sub-title">voir les <a href="">tous les témoignages de nos clients</a></p>
		<div class="kone-inr">
			<div class="one-col">
				<div class="kone-img">
					<img src="assets/image/office-logo.jpg">
				</div>
				<p>Entreprise nous ayant fait confiance.</p>
				<a href="" class="arrow-link"><mat-icon svgIcon="link_arrow"></mat-icon></a>
			</div>
			<div class="one-col">
				<div class="kone-img">
					<img src="assets/image/office-logo.jpg">
				</div>
				<p>Entreprise nous ayant fait confiance. </p>
				<a href="" class="arrow-link"><mat-icon svgIcon="link_arrow"></mat-icon></a>
			</div>
			<div class="one-col">
				<div class="kone-img">
					<img src="assets/image/office-logo.jpg">
				</div>
				<p>Entreprise nous ayant fait confiance.</p>
				<a href="" class="arrow-link"><mat-icon svgIcon="link_arrow"></mat-icon></a>
			</div>
		</div>
	</div>
</div>


<mat-toolbar-row class="footer-toolbar-row" id="footerNav">
    <div class="row" fxLayout>
      <div class="left-navbar-bloc" style="text-align: left" fxFlex>
        <span> Nous croyons dans le potentiel de chacun<br>Retrouvez-nous sur les réseaux sociaux</span>
      </div>
    </div>
    <span class="navbar-spacer"></span>
    <div class="row" fxLayout>
      <div class="right-navbar-bloc" fxFlex fxLayoutAlign="flex-end center">
        <a target="_blank" href="https://www.facebook.com/gotafemploi" style="text-decoration: none;">
          <mat-icon svgIcon="icon_facebook"></mat-icon>
        </a>
        <a target="_blank" href="https://www.instagram.com/gotafemplois/" style="text-decoration: none;">
          <mat-icon svgIcon="icon_instagram"></mat-icon>
        </a>
        <a target="_blank" href="https://youtu.be/I5WrwtfknxE"
           style="text-decoration: none;">
          <mat-icon svgIcon="icon_youtube"></mat-icon>
        </a>
        <a target="_blank" href="https://www.tiktok.com/@gotaf21?lang=fr" style="text-decoration: none;">
          <mat-icon svgIcon="icon_tiktok"></mat-icon>
        </a>
        <a target="_blank" href="https://www.twitch.tv/gotaf21" style="text-decoration: none;">
          <mat-icon svgIcon="icon_twitch"></mat-icon>
        </a>
        <a target="_blank" href="https://www.linkedin.com/company/gotaf" style="text-decoration: none;">
          <mat-icon svgIcon="icon_linkedin"></mat-icon>
        </a>
      </div>
    </div>
 </mat-toolbar-row>
