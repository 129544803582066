<div class="principal">
  <div *ngIf="firstScreen === true" class="center" fxLayout="column">
    <mat-icon class="audio-icon" svgIcon="audio-yellow" (click)="play()"></mat-icon>
    <div>
      <h2 class="element">
        Afin de valider votre candidature simplifiée<br>
        merci de bien vouloir répondre<br> aux questions suivantes.
      </h2>
    </div>
    <div class="element1">
      <button class="default-button-style button-color-white" mat-button (click)="goToScreen()">
        <!-- <mat-icon class="social-connect"></mat-icon> -->
        OK
      </button>
    </div>
  </div>
  <div *ngIf="showQuestion === true" class="stepper">
    <mat-horizontal-stepper #stepper class="centerQuestion container">
      <mat-step *ngFor="let item of questionList; let i=index">
        <h1 class="element" style="font-weight: bold;">
          Question {{i + 1}} <mat-icon class="question-audio-icon" svgIcon="audio" (click)="playQuestion(item.question)"
            fxShow="true" fxShow.xs="false">
          </mat-icon>
        </h1>
        <div>
          <h2 class="element">
            {{item.question}}
          </h2>

            <mat-icon class="audio-icon-step" svgIcon="audio" (click)="playQuestion(item.question)" fxShow="false"
              fxShow.xs="true">
            </mat-icon>

        </div>
        <div class="element1">
          <button matStepperNext class="default-button-style button-color-default" mat-button
            (click)="gotToNext(i+1); calculeScore(item.non, i)">
            <!-- <mat-icon class="social-connect"></mat-icon> -->
            Non
          </button>
          <button matStepperNext class="default-button-style button-color-white" mat-button
            (click)="gotToNext(i+1);calculeScore(item.oui, i)">
            <!-- <mat-icon class="social-connect"></mat-icon> -->
            Oui
          </button>
        </div>
        <div>
          <div *ngIf="i != 0" style="padding-top: 20px;">
            <button matStepperPrevious mat-button class="previous">
              <p style="margin: 0 !important">revenir à la question précédente </p>
            </button>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>

  <div *ngIf="lastScreen === true" class="center" fxLayout="column">
    <mat-icon class="audio-icon" svgIcon="audio"
      (click)="playQuestion('Félicitations! Votre candidature a bien été envoyée.')"></mat-icon>
    <div>
      <h2 class="element">
        Félicitations !<br>
        Votre candidature a bien été envoyée à<br>
        <strong>{{compagnyName}}</strong>
        <strong *ngIf="isFromExternalUrl == 1 && !canWindowClosed">
        . Vous pouvez maintenant fermer cette fenêtre.
        </strong>
      </h2>
    </div>
    <div class="element1">
      <button *ngIf="isFromExternalUrl == 0" class="default-button-style button-color-white" mat-button [routerLink]="['/job-applied']"
        routerLinkActive="router-link-active">
        <!-- <mat-icon class="social-connect"></mat-icon> -->
        Super
      </button>
      <button *ngIf="isFromExternalUrl == 1 && canWindowClosed" class="default-button-style button-color-white" mat-button (click)="closeWindow()"
      routerLinkActive="router-link-active">
      <!-- <mat-icon class="social-connect"></mat-icon> -->
      Super
    </button>
    </div>
  </div>

</div>
