import { Injectable, QueryList, ElementRef, ViewChildren } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
declare var webkitSpeechRecognition: any;
function _window(): any {
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class SpeechToTextService {

  public divs: QueryList<ElementRef<HTMLTextAreaElement>>;
  constructor(private plt: Platform) { }

  public voicesearch(objsIndex): void {
    const textareaElement = this.divs.find((div, i) => i === objsIndex);
    if (this.plt.IOS || this.plt.ANDROID) {
      _window().plugins.speechRecognition.isRecognitionAvailable(
        (isReady) => {
          if (isReady === true) {
            _window().plugins.speechRecognition.hasPermission((isAllow) => {
              if (isAllow === false) {
                _window().plugins.speechRecognition.requestPermission(result,
                  (error) => {
                    // alert(error);
                  });
              } else {
                _window().plugins.speechRecognition.startListening(
                  successCallback, errorCallback, {
                  language: 'fr-FR',
                });
              }
            }, (error) => {
              // alert(error);
            });
          } else {
            return;
          }
        }
        , (error) => {
          // alert(error);
        }
      );
    } else {
      if ('webkitSpeechRecognition' in window) {
        const vSearch = new webkitSpeechRecognition();
        vSearch.continuous = false;
        vSearch.interimresults = false;
        vSearch.lang = 'fr-FR';
        vSearch.start();
        const voiceHandler = textareaElement.nativeElement;
        vSearch.onresult = (e) => {
          voiceHandler.value = e.results[0][0].transcript;
          vSearch.stop();
        };
        vSearch.onerror = (e) => {
          textareaElement.nativeElement.value = 'Error,Please try after sometime!';
          textareaElement.nativeElement.style.color = 'red';
          setTimeout(() => {
            textareaElement.nativeElement.value = '';
            textareaElement.nativeElement.style.color = 'black';
          }, 5000);
          vSearch.stop();
        };
      } else {
        textareaElement.nativeElement.value = 'Your browser does not support this feature!';
        textareaElement.nativeElement.style.color = 'red';
        setTimeout(() => {
          textareaElement.nativeElement.value = '';
          textareaElement.nativeElement.style.color = 'black';
        }, 5000);
      }
    }

    function successCallback(results) {
      const voiceHandler = textareaElement.nativeElement;
      voiceHandler.value = results[0];
    }

    function errorCallback(results) {
      textareaElement.nativeElement.value = 'Your browser does not support this feature!';
      textareaElement.nativeElement.style.color = 'red';
      setTimeout(() => {
        textareaElement.nativeElement.value = '';
        textareaElement.nativeElement.style.color = 'black';
      }, 5000);
    }
    function result(results) {
      if (results) {
        _window().plugins.speechRecognition.startListening(
          successCallback, errorCallback, {
          language: 'fr-FR',
        });
      }
    }
  }
}
