import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { Job } from 'src/app/model/job';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnChanges {
  @Input() totalJobs: Job[] = [];
  @Input() jobsPerPage = 0;
  @Input() range: number = 3;
  @Output() onPageChange: EventEmitter<number> = new EventEmitter();
  public pages: number[] = [];
  activePage: number;

  constructor() { }

  ngOnChanges(): any {
    const pageCount = this.getPageCount();
    this.pages = this.getArrayOfPage(pageCount);
    this.activePage = 1;
    this.onPageChange.emit(1);
  }

  private getPageCount(): number {
    let totalPages = 0;
    if (this.totalJobs.length > 0 && this.jobsPerPage > 0) {
      const pageCount = this.totalJobs.length / this.jobsPerPage;
      const roundedPageCount = Math.floor(pageCount);

      totalPages = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
    }
    return totalPages;
  }

  private getArrayOfPage(pageCount: number): number[] {
    const pageArray = [];
    if (pageCount > 0) {
      for (let i = 1; i <= pageCount; i++) {
        pageArray.push(i);
      }
    }
    return pageArray;
  }

  onClickPage(pageNumber: number): void {
    if (pageNumber >= 1 && pageNumber <= this.pages.length) {
      this.activePage = pageNumber;
      this.onPageChange.emit(this.activePage);
    }
  }
}
