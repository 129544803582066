import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { HttpClientService } from '../http-client/http-client.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  private apiUrl: any;

  constructor(
    private http: HttpClientService,
  ) {
    this.apiUrl = environment.backendApiUrl;
  }

  public addContact(data): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'contact-us', data).pipe(map((result) => {
      return result;
    }));
  }
}
