<!-- <nav aria-label="exemple de navigation de page">
    <ul class="pagination">
        <li class="page-item" (click)="onClickPage (activePage - 1)"> <a class="page-link" href="javascript: void (0);">
                «
                Précédent </a> </li>
        <li class="page-item" [ngClass]="{'active': activePage === item}" *ngFor="let item of pages"
            (click)="onClickPage (item)"> <a class="page-link" href="javascript: void (0);"> {{item}} </a> </li>
        <li class="page-item" (click)="onClickPage (activePage + 1)"> <a class="page-link" href="javascript: void (0);">
                Suivant
                »</a> </li>
    </ul>
</nav> -->

<div style="justify-content: center; display: flex;" fxLayout="row" fxLayoutAlign="center center">
    <button mat-mini-fab class="btn-action" (click)="onClickPage (1)">
        <mat-icon>first_page</mat-icon>
    </button>
    <button mat-mini-fab class="btn-action" (click)="onClickPage (activePage - 1)">
        <mat-icon>arrow_back_ios</mat-icon>
    </button>
    <div class="max-pgn">
        <span class="indicator" *ngFor="let item of pages" (click)="onClickPage (item)"
            [ngClass]="{'active': activePage === item}">{{item}}</span>
    </div>
    <button mat-mini-fab class="btn-action" (click)="onClickPage (activePage + 1)">
        <mat-icon>arrow_forward_ios</mat-icon>
    </button>

    <button mat-mini-fab class="btn-action" (click)="onClickPage(pages.length)">
        <mat-icon>last_page</mat-icon>
    </button>
</div>

<!-- <div class="paginator" fxShow="true" fxShow.xs="false">
    <div class="paginator-grid" [ngClass]="{'active': activePage === item}" *ngFor="let item of pages" (click)="onClickPage (item)">
        <p><span style="cursor: pointer;">{{item}}</span></p>
    </div>
</div> -->