import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css', '../../../../../node_modules/bootstrap/dist/css/bootstrap.css', '../../../shared/css/single-job.style.css', '../../../shared/css/cgu.style.css']
})
export class CookiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
