import { Component, OnInit, Input, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { SpeechToTextService } from '../../../service/speech-to-text/speech-to-text.service';
import { JobsService } from '../../../service/jobs/jobs.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from '../../../service/snack/snack.service';
export interface DialogData {
  jobId: string;
}
@Component({
  selector: 'app-job-questions',
  templateUrl: './job-questions.component.html',
  styleUrls: ['./job-questions.component.css', '../../../../../node_modules/bootstrap/dist/css/bootstrap.css', '../../../../app/shared/css/single-job.style.css']
})
export class JobQuestionsComponent implements OnInit {
  public addQuestion: FormGroup;
  public questionObjects: FormArray;
  public isRecord = true;
  public array = [1, 2];
  public jobQuestionNumber: Number = 3;
  public jobQuestionArray = Array;

  @Input() jobId;
  constructor(
    private speechToText: SpeechToTextService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public jobService: JobsService,
    public dialogRef: MatDialogRef<JobQuestionsComponent>,
    @Inject(MAT_DIALOG_DATA) public jobData: DialogData,
    private snack: SnackService,
  ) { }

  ngOnInit(): void {
    this.jobId = this.jobData;
    this.addQuestion = this.formBuilder.group({
      questionObjects: this.formBuilder.array([this.createQuestion(), this.createQuestion(), this.createQuestion()])
    });
    this.getJobQuestion();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  addNewBloc() {
    const curent = this.addQuestion.get('questionObjects') as FormArray;
    const value = curent.value as Array<any>;
    const result = value.reduce((accumulator, current) => {
      return Number(accumulator) + (Number(current.oui) + Number(current.non));
    }, 0);
    if (curent.length < 5 && result < 100) {
      curent.push(this.createQuestion());
    } else {
      this.snack.error('Vous avez atteint la limite de score ou vous avez ajouté déjà 5 questions',);
    }
  }

  createQuestion(): FormGroup {
    return this.formBuilder.group({
      question: '',
      oui: '',
      non: ''
    });
  }
  public voiceSearch(objsIndex): void {
    this.isRecord = true;
    this.speechToText.voicesearch(objsIndex);
  }
  public onSubmit(): void {
    const curent = this.addQuestion.get('questionObjects') as FormArray;
    this.dialogRef.close(curent.value as Array<any>);
  }
  public getJobQuestion(): void {
    if (this.jobId !== '') {
      this.jobService.getJobQuestion(this.jobId).subscribe(data => {
        if (data.questions != null) {
          var datajsonParse = JSON.parse(data.questions.questions);
          console.log(datajsonParse);

          for (let questionIndex = 0; questionIndex < datajsonParse.length; questionIndex++) {
            if (datajsonParse.length > this.addQuestion.get('questionObjects')['controls'].length) {
              this.questionObjects = this.addQuestion.get('questionObjects') as FormArray;
              this.questionObjects.push(this.createQuestion());
            }
            this.setQuestionValue(this.addQuestion.get('questionObjects')['controls'][questionIndex]['controls'].question, datajsonParse[questionIndex].question)
            this.setQuestionValue(this.addQuestion.get('questionObjects')['controls'][questionIndex]['controls'].oui, datajsonParse[questionIndex].oui)
            this.setQuestionValue(this.addQuestion.get('questionObjects')['controls'][questionIndex]['controls'].non, datajsonParse[questionIndex].non)
          }
        }
      });
    }
  }
  public setQuestionValue = (controlName, controlValue): void => {
    return controlName.setValue(controlValue);
  }
}
