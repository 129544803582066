import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { whitebrandedconfig } from '../../../whitebranded/config';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class HttpClientService {

  headers: HttpHeaders;
  bheaders: BehaviorSubject<any>;
  bheadersObservable: Observable<any>;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('CompanyGroupId', ""+whitebrandedconfig.companiesgroup_id);
    this.headers = this.headers.append('Country', ""+whitebrandedconfig.default_country);
    this.bheaders = new BehaviorSubject<any>(this.headers);
    this.bheadersObservable = this.bheaders.asObservable();
  }

  modifyCountry(reference:string)
  {
    this.headers = this.headers.set('Country', reference);
    this.bheaders.next(this.headers);
  }

  get<p>(url) {

    this.bheadersObservable.subscribe(headers => {
      this.headers = headers;
    });

    return this.http.get<p>(url, {
      headers: this.headers
    });
  }

  post<p>(url, data, _headers=null) {

    this.bheadersObservable.subscribe(headers => {
      this.headers = headers;
    });

    if(_headers!=null)
    {
      console.log(_headers);
      _headers = _headers.set('CompanyGroupId', this.headers.get('CompanyGroupId'));
      _headers = _headers.set('Country', this.headers.get('Country'));
      this.headers = _headers;
    }

    return this.http.post<p>(url, data, {
      headers: this.headers
    });
  }

  delete(url) {

    this.bheadersObservable.subscribe(headers => {
      this.headers = headers;
    });

    return this.http.delete(url, {
      headers: this.headers
    });
  }
}
