import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { JobsService } from '../../../service/jobs/jobs.service';
import { Job } from '../../../model/job';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { environment } from '../../../../environments/environment';
import { User } from '../../../model/user';
import {SnackService} from '../../../service/snack/snack.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit {
  public jobs = [];
  public user: User;
  private userId = null;
  @Output() jobResult = new EventEmitter<any[]>();
  @Output() urgentJob = new EventEmitter<any[]>();
  @Output('currentLocation') currentLocation: EventEmitter<any> = new EventEmitter();

  mapButtonclicked: boolean = false;


  constructor(public jobsService: JobsService,
    private authenticationService: AuthenticationService,
    private snack: SnackService
  ) {
    this.authenticationService.user.subscribe(x => this.user = x);
    if (this.user) {
      this.userId = this.user.id;
    }
  }

  ngOnInit(): void {
    // this.getLastJobs(null);
  }


  public getLastJobs(limit: number): void {
    if (limit == null) {
      this.jobsService.getLastJobs(this.authenticationService.userValue === null
        ? null : this.authenticationService.userValue.id).subscribe(data => {
          this.jobs = data['lastJobs'];
          // console.log(data);
          this.jobResult.emit(data);
        }, ignore => {
          this.jobs = [];
          // this.snack.error('error when loading last Jobs');
        });
    } else {
      this.jobsService.getLastJobs(this.authenticationService.userValue === null
        ? null : this.authenticationService.userValue.id).subscribe(data => {
          this.jobs = data['lastJobs'];
          this.jobResult.emit(data);
        }, ignore => {
          this.jobs = [];
          // this.snack.error('error when loading last Jobs');
        });
    }
  }
  public setGoogleMap(): void {
    this.mapButtonclicked = !this.mapButtonclicked;
  }

  public voirClick(): void {

    if (this.jobsService.mapButtonclicked === true) {
      this.jobsService.mapButtonclicked = false;
      this.jobsService.isVoirCLick = true;
    }
    else {
      this.jobsService.mapButtonclicked = true;
      this.jobsService.isVoirCLick = false;
    }
  }

  public backClick(): void {
    this.jobsService.mapButtonclicked = false;
    this.jobsService.isVoirCLick = true;
  }

  public urgentJobClick(): void {
    this.jobsService.getUrgentJobs(this.userId).subscribe((data) => {
      this.jobs = data['urgentJobs'];
      this.urgentJob.emit(this.jobs);
      // tslint:disable-next-line: no-unused-expression
    }), (err) => {
      this.jobs = [];
      this.urgentJob.emit(this.jobs);
    };
  }

  public handlePermission(): void {
    this.currentLocation.emit();

  }
}
