import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { HttpClientService } from '../http-client/http-client.service';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { JobCategory } from '../../model/jobCategory';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobCategoriesService {
  private apiUrl: any;

  constructor(
    private http: HttpClientService
  ) {
    this.apiUrl = environment.backendApiUrl;
  }

  public getJobCategories(): Observable<any[]> {
    return this.http.get<JobCategory[]>(this.apiUrl + 'jobCategories')
      .pipe(map(jobCategories => {
        return jobCategories['jobCategories'];
      }));
  }

  public getJobCategory(categoryId: number): Observable<any[]> {
    return this.http.get<JobCategory[]>(this.apiUrl + 'jobCategory/' + categoryId)
      .pipe(map(jobCategory => {
        return jobCategory['jobCategory'];
      }));
  }

  public getCategoryJobs(categoryId: number, options = {page: 1},searchForm:string = '',searchcity:string =''): Observable<any[]> {
    return this.http.get<JobCategory[]>(this.apiUrl + 'jobCategory/' + categoryId + '/jobs?page=' + options.page + '&search=' + searchForm + '&city='+searchcity)
      .pipe(
        map((data: any) => {
          data['jobs'].map((job: any) => {
            const marker: any = {};
            const address = job.address;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({
              'address': address
            }, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                for (let i = 0; i < results[0].address_components.length; i++) {
                  const types = results[0].address_components[i].types;
                  if (types.indexOf('locality') !== -1) {
                    marker.address_level_2 = results[0].address_components[i].long_name;
                  }
                  if (types.indexOf('country') !== -1) {
                    marker.address_country = results[0].address_components[i].long_name;
                  }
                  if (types.indexOf('postal_code') !== -1) {
                    marker.address_zip = results[0].address_components[i].long_name;
                  }
                  if (types.indexOf('administrative_area_level_1') !== -1) {
                    marker.address_state = results[0].address_components[i].long_name;
                  }
                }
                if (results[0].geometry.location) {
                  marker.lat = Number(results[0].geometry.location.lat());
                  marker.lng = Number(results[0].geometry.location.lng());
                  marker.viewport = results[0].geometry.viewport;
                  marker.jobId = job.id;
                  marker.options = { icon: '../../../../assets/image/icon_metier_map.svg' };
                }
              } else {
              }
            });
            job.options = marker;
          });
          return data;
        })
      );
  }
}
