<div class="main-header-banner">
  <div class="header-banner">
    <img src="assets/image/compare-bg.jpg">
  </div>
</div>
<div class="apply-empl-pro-sec">
  <div class="container">
    <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="apply-emp-profile-box">
      <div fxFlex="45%" fxFlex.xs="100%" fxFlex.sm="100%">
        <div class="apply-emp-profile-main">
          <div class="apply-emp-profile">
            <img src="{{imageBaseUrl + userResume.avatar}}">

          </div>
          <div class="apply-emp-profile-info">
            <div class="apply-emp-dtil" style="text-align: center;">
              <h3>{{ userResume.firstName}} {{ userResume.lastName}} </h3>

              <label>{{ userResume.address }}</label>

              <div class="char-container">
                <canvas id="expChart" style="align-self: center;"></canvas>
              </div>
            </div>
            <div class="apply-emp-star">
              <ul class="reting-candi">
                <li>
                  <div class="stars-apply">
                    <label class="active">
                      <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                    </label>
                    <label class="active">
                      <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                    </label>
                    <label class="active">
                      <mat-icon class="star-icon" svgIcon="icon_star"></mat-icon>
                    </label>
                  </div>
                  <span>EXPERT</span>
                </li>
              </ul>
              <div class="candidt-btm-btns" fxLayout="column">
                <!--<button class="default-button-style">
                            <mat-icon class="restauration-icon" svgIcon="category_restauration"></mat-icon>
                            restauration</button>
                          <button class="default-button-style">
                            <mat-icon class="btp-icon" svgIcon="category_btp"></mat-icon>
                            BTP</button>-->
                <button class="default-button-style" *ngFor="let categorie of userResume.categories">
                  <mat-icon class="restauration-icon" svgIcon="{{categorie.image}}"></mat-icon>
                  {{categorie.name}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="55%" fxFlex.xs="100%" fxFlex.sm="100%">
        <div class="candidt-info-main">
          <div class="candidt-info-left">
            <h3>Infos</h3>
            <p>{{userResume.summary}}</p>
          </div>
          <div class="candidt-info-right">
            <img src="assets/image/pexels-barthy-bonhomme.jpg">
            <div class="candidt-info-audio">
              <mat-icon class="audio-icon" svgIcon="audio" (click)="playAudio(userResume.audio)"></mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pro-canddt">
  <div class="container">
    <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill fxLayoutGap="2%">
      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" class="hide-mobile">
        <div class="prfl-canddt">
          <h3 class="prfl-canddt-title">
            <mat-icon class="likeStar-icon" svgIcon="icon_likeStar"></mat-icon>
            <label>Avis employeurs</label>
          </h3>
          <div class="card">
            <div class="compare-likestar">
              <p>"Recommandation laissée par un ancien employeur du candidat." </p>
              <ul>
                <li>Marc, dirigeant <br>
                  entreprise X</li>
                <li class="text-center">
                  <div class="blackstar">
                    <label>
                      <mat-icon class="blackStar-icon" svgIcon="icon_blackStar"></mat-icon>
                    </label>
                    <label>
                      <mat-icon class="blackStar-icon" svgIcon="icon_blackStar"></mat-icon>
                    </label>
                    <label>
                      <mat-icon class="blackStarblank-icon" svgIcon="icon_blackStarBlanck"></mat-icon>
                    </label>
                  </div>
                  <span>2/3</span>
                </li>
              </ul>
            </div>
          </div>
          <!--          <div class="card">-->
          <!--            <div class="compare-likestar">-->
          <!--                            <p>"Recommandation laissée par un ancien employeur du candidat." </p>-->
          <!--              <ul>-->
          <!--                <li>Marc, dirigeant <br>-->
          <!--                    entreprise X</li>-->
          <!--                <li class="text-center">-->
          <!--                    <div class="blackstar">-->
          <!--                        <label><mat-icon class="blackStar-icon" svgIcon="icon_blackStar"></mat-icon></label>-->
          <!--                        <label><mat-icon class="blackStar-icon" svgIcon="icon_blackStar"></mat-icon></label>-->
          <!--                        <label><mat-icon class="blackStarblank-icon" svgIcon="icon_blackStarBlanck"></mat-icon></label>-->
          <!--                    </div>-->
          <!--                    <span>2/3</span>-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="card">-->
          <!--            <div class="compare-likestar">-->
          <!--                            <p>"Recommandation laissée par un ancien employeur du candidat." </p>-->
          <!--              <ul>-->
          <!--                <li>Marc, dirigeant <br>-->
          <!--                    entreprise X</li>-->
          <!--                <li class="text-center">-->
          <!--                    <div class="blackstar">-->
          <!--                        <label><mat-icon class="blackStar-icon" svgIcon="icon_blackStar"></mat-icon></label>-->
          <!--                        <label><mat-icon class="blackStar-icon" svgIcon="icon_blackStar"></mat-icon></label>-->
          <!--                        <label><mat-icon class="blackStarblank-icon" svgIcon="icon_blackStarBlanck"></mat-icon></label>-->
          <!--                    </div>-->
          <!--                    <span>2/3</span>-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="prfl-canddt">
          <h3 class="prfl-canddt-title">
            <mat-icon class="qualifications-icon" svgIcon="icon_qualifications"></mat-icon>
            <label>Dernier diplôme</label>
          </h3>
          <div class="card">
            <div class="qualifications" *ngFor="let qualification of userResume.qualification;  let index = index">
              <h3 style="width: min-content;">{{qualification.qualification}}</h3>
              <div class="qualifications-info">
                <h3>{{qualification.university}}</h3>
                <p>{{qualification.description}}</p>
              </div>
            </div>
            <!--<div class="qualifications">
              <h3>Avril<br>2010</h3>
              <div class="qualifications-info">
                <h3>{{qualification.university}}</h3>
                <p>{{qualification.description}}</p>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" class="hide-mobile">
        <div class="prfl-canddt">
          <h3 class="prfl-canddt-title">
            <mat-icon class="experiences-icon" svgIcon="icon_experiences"></mat-icon>
            <label>Expériences professionnelles</label>
          </h3>
          <div class="card">

            <div class="experiences" *ngFor="let experience of userResume.experience;  let index = index">
              <div class="exprync-img">
                <img src="assets/image/rockwood-bordeaux-logo.png">
                <mat-icon class="restauration-icon" svgIcon="{{experience.jobCategory.image}}"></mat-icon>
              </div>
              <div class="qualifications-info">
                <h3>{{experience.title}}<span>{{experience.company}}, à {{experience.address}}</span></h3>
                <label class="time-date">{{experience.startAt}} à {{experience.endAt}}</label>
                <p>{{experience.description}}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%">
        <div class="prfl-canddt">
          <h3 class="prfl-canddt-title">
            <mat-icon class="contacter-icon" svgIcon="icon_contacter"></mat-icon>
            <label>Contacter {{userResume.firstName}}</label>
          </h3>
          <div class="caandy-prof-btn">
            <div class="candidt-info-btns" fxLayout="column" fxLayout.xs="row" fxLayout.sm="row"
              fxLayoutAlign="center center">
              <button [ngClass]="{'button-border': showPhone, 'button-grad': !showPhone}" (click)="setStateShowPhone()">
                <mat-icon class="call-icon" svgIcon="icon_call"></mat-icon>
                <span class="hide-mobile">{{showPhone === false ? 'Contacter' : ''}} {{showPhone === false ?
                  userResume.firstName : userResume.phone}}</span>
                <span class="hide-web">{{showPhone === false ? 'Contacter' : ''}} {{showPhone === false ?
                  userResume.firstName : userResume.phone}}</span>
              </button>
              <button class="button-border">
                <mat-icon class="plus-icon" svgIcon="icon_plus"></mat-icon>
                <span class="hide-mobile">Ajouter à ma liste de candidat</span>
                <span class="hide-web">Ajouter à ma liste</span>
              </button>
              <button class="black-btn">
                <mat-icon class="compare-icon" svgIcon="icon_compare"></mat-icon>
                <span class="hide-mobile">Comparer {{userResume.firstName}}</span>
                <span class="hide-web">Comparer</span>
              </button>
            </div>
            <ul class="img-buble">
              <li class="add-candidate">
                <img src="assets/image/add-candidate.png">
                <mat-icon class="plus-icon" svgIcon="icon_plus"></mat-icon>
              </li>
              <li>
                <img src="{{imageBaseUrl + userResume.avatar}}">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="hide-web tab-in-mob">
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="mat-tab-desn">
              <mat-icon class="likeStar-icon" svgIcon="icon_likeStar"></mat-icon>
              Avis
            </div>
          </ng-template>
          <div class="prfl-canddt">
            <div class="card">
              <div class="compare-likestar">
                <p>"Recommandation laissée par un ancien employeur du candidat." </p>
                <ul>
                  <li>Marc, dirigeant <br>
                    entreprise X</li>
                  <li class="text-center">
                    <div class="blackstar">
                      <label>
                        <mat-icon class="blackStar-icon" svgIcon="icon_blackStar"></mat-icon>
                      </label>
                      <label>
                        <mat-icon class="blackStar-icon" svgIcon="icon_blackStar"></mat-icon>
                      </label>
                      <label>
                        <mat-icon class="blackStarblank-icon" svgIcon="icon_blackStarBlanck"></mat-icon>
                      </label>
                    </div>
                    <span>2/3</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <div class="compare-likestar">
                <p>"Recommandation laissée par un ancien employeur du candidat." </p>
                <ul>
                  <li>Marc, dirigeant <br>
                    entreprise X</li>
                  <li class="text-center">
                    <div class="blackstar">
                      <label>
                        <mat-icon class="blackStar-icon" svgIcon="icon_blackStar"></mat-icon>
                      </label>
                      <label>
                        <mat-icon class="blackStar-icon" svgIcon="icon_blackStar"></mat-icon>
                      </label>
                      <label>
                        <mat-icon class="blackStarblank-icon" svgIcon="icon_blackStarBlanck"></mat-icon>
                      </label>
                    </div>
                    <span>2/3</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <div class="compare-likestar">
                <p>"Recommandation laissée par un ancien employeur du candidat." </p>
                <ul>
                  <li>Marc, dirigeant <br>
                    entreprise X</li>
                  <li class="text-center">
                    <div class="blackstar">
                      <label>
                        <mat-icon class="blackStar-icon" svgIcon="icon_blackStar"></mat-icon>
                      </label>
                      <label>
                        <mat-icon class="blackStar-icon" svgIcon="icon_blackStar"></mat-icon>
                      </label>
                      <label>
                        <mat-icon class="blackStarblank-icon" svgIcon="icon_blackStarBlanck"></mat-icon>
                      </label>
                    </div>
                    <span>2/3</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <div class="mat-tab-desn">
              <mat-icon class="experiences-icon" svgIcon="icon_experiences"></mat-icon>
              Expériences
            </div>
          </ng-template>
          <div class="prfl-canddt">
            <div class="card">
              <div class="experiences" *ngFor="let experience of userResume.experience;  let index = index">
                <div class="exprync-img">
                  <img src="assets/image/rockwood-bordeaux-logo.png">
                  <mat-icon class="restauration-icon" svgIcon="{{experience.jobCategory.image}}"></mat-icon>
                </div>
                <div class="qualifications-info">
                  <h3>{{experience.title}}<span>{{experience.company}}, à {{experience.address}}</span></h3>
                  <label class="time-date">{{experience.startAt}} à {{experience.endAt}}</label>
                  <p>{{experience.description}}</p>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <div class="mat-tab-desn">
              <mat-icon class="qualifications-icon" svgIcon="icon_qualifications"></mat-icon>
              Qualifications
            </div>
          </ng-template>
          <div class="prfl-canddt">
            <div class="card">
              <div class="qualifications" *ngFor="let qualification of userResume.qualification;  let index = index">
                <h3 style="width: min-content;">{{qualification.qualification}}</h3>
                <div class="qualifications-info">
                  <h3>{{qualification.university}}</h3>
                  <p>{{qualification.description}}</p>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
