<div id="message">
  <span class="valid-message" [hidden]=!valid>
    <mat-icon>check_circle</mat-icon>
    {{message}}
  </span>
  <span class="info-message" [hidden]=!info>
    <mat-icon>info</mat-icon>
    {{message}}
  </span>
  <span class="warn-message" [hidden]=!warn>
    <mat-icon>warning</mat-icon>
    {{message}}
  </span>
  <span class="error-message" [hidden]=!error>
    <mat-icon>error</mat-icon>
    {{message}}
  </span>
</div>
