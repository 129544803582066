import { Component, OnInit } from '@angular/core';
import { CategoriePoleEmploi, categorieCommercePoleEmploie, categorieBtpPoleEmploie, categorieLogiquePoleEmploie, categorieServicePoleEmploie, categorieHotelRestoPoleEmploie } from '../../../shared/const/suggestions';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { SharedServiceService } from '../../../shared/shared-service.service';
import { Platform } from '@angular/cdk/platform';
// import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JobImageSuggessionComponentComponent } from '../job-image-suggession-component/job-image-suggession-component.component';
import { HttpClientService } from 'src/app/service/http-client/http-client.service';

@Component({
  selector: 'app-job-image-suggestion',
  templateUrl: './job-image-suggestion.component.html',
  styleUrls: ['./job-image-suggestion.component.css']
})
export class JobImageSuggestionComponent implements OnInit {
  public sugestionForm: FormGroup;
  public categories: any[];
  public categoriePoleEmploie: any[] = [];
  public sousCategoriePoleEmploie: any[] = [];
  code: string;

  constructor(private formBuilder: FormBuilder,
    private httpClient: HttpClientService,
    public dialog: MatDialog,
    public _dialogRef: MatDialogRef<JobImageSuggestionComponent>
  ) { }

  ngOnInit(): void {
    this.sugestionForm = this.formBuilder.group({
      categorieId: [''],
      souscategorieId: [''],
      souscategoriePoleEmploiCode: ['']
    });

    // tslint:disable-next-line: deprecation
    this.httpClient.get('assets/data/suggestions.json').subscribe((data: any) => {
      this.categories = data;
      console.log(data);
    });
  }

  public onChange(id: number): void {
    this.categoriePoleEmploie = this.categories[id].categorie_pole_emploi;
  }
  public onLastChange(id: number): void {
    this.sousCategoriePoleEmploie = this.categoriePoleEmploie[id].sous_categorie_pole_emploi;
  }
  public setCategorieCode(code: string): void {
    this.code = code;
  }

  submit(): void {
    const dialogRef = this.dialog.open(JobImageSuggessionComponentComponent, {
      data: this.code,
      width: '800px'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      this._dialogRef.close(result);
    });
  }
}
