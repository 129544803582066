<div>
    <div fxFlex *ngIf="!isRecording">
        <button (click)="startRecording()" mat-mini-fab color="secondary" class="btn-headphone-fab" style="width: 5em;
    height: 5em;">
      <mat-icon style="color: red;transform: scale(1.5);">mic</mat-icon>
    </button>
        <div style="height: 0;">
            <img class="audio-icon-badge" (click)="playAudio(true)" src="assets/image/picto_audio_medium.png" *ngIf="blobUrl">
        </div>
    </div>

    <div fxFlex *ngIf="isRecording && !blobUrl">
        <button (click)="stopRecording()" mat-mini-fab color="secondary" class="btn-headphone-fab" style="width: 5em;
    height: 5em; margin-top: 25px;">
      <mat-icon style="color: red;transform: scale(1.5);">stop</mat-icon>
    </button>
        <h5 class="record-time" *ngIf="isRecording && !blobUrl"> {{recordedTime}} </h5>
    </div>

    <!--  <div fxFlex="30">-->
    <!--    <mat-icon class="play-icon" svgIcon="play"></mat-icon>-->
    <!--  </div>-->
    <!-- <audio style="margin-top: 10px" *ngIf="!isRecording && blobUrl" controls style="width: inherit;" fxFlex="45"
  [src]="blobUrl">
</audio> -->

    <!-- <button class="cancel-button" *ngIf="!isRecording && blobUrl" (click)="clearRecordedData()">Clear Recording</button> -->


</div>
<!--<div>-->
<!--    <audio *ngIf="!isRecording && blobUrl" controls>-->
<!--        <source [src]="blobUrl" type="audio/webm">-->
<!--    </audio>-->
<!--</div>-->