import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment.prod';
import { SnackService } from '../../../../service/snack/snack.service';
import { AuthenticationService } from '../../../../service/authentication/authentication.service';
import { JobsService } from '../../../../service/jobs/jobs.service';
import { CompanyService } from '../../../../service/company/company.service';
import { AudioFileService } from '../../../../service/audiofile.service';
import { AudioService } from '../../../../service/audio.service';

@Component({
  selector: 'app-company-jobs',
  templateUrl: './company-jobs.component.html',
  styleUrls: ['./company-jobs.component.css', '../../../../../../node_modules/bootstrap/dist/css/bootstrap.css']
})
export class CompanyJobsComponent implements OnInit {
  public jobsCreate: any;
  public company: any;
  public imageBaseUrl: string = environment.imageBaseUrl;
  public pageNumber = 1;
  public lastPage = null;
  public loadingJobs = false;
  constructor(
    private jobsService: JobsService,
    private snack: SnackService,
    private authenticationService: AuthenticationService,
    private companyService: CompanyService,
    private audioFileService: AudioFileService,
    private audioService: AudioService,
  ) { }

  ngOnInit(): void {
    this.getCompany();
  }

  public onScroll(): void {
    if (this.loadingJobs || ( this.lastPage && this.pageNumber >= this.lastPage)) {
      return;
    }
    this.loadingJobs = false;
    this.companyService.getCompanyJobs(this.company.id, {page: this.pageNumber}).subscribe((data: any) => {
      this.jobsCreate.push(...data.companyJobs);
      this.lastPage = data.lastPage;
      this.loadingJobs = false;
    }, ignore => {
      this.jobsCreate = [];
      console.log('error when loading lastJobs'); // TODO: change here by a fancy snack to display error
    });
  }

  public getCompany(): void {
    this.companyService.getCompanyByUser(this.authenticationService.userValue.id).subscribe((data) => {
      this.company = data;
      this.getCompanyJobs(this.company.id);
    }, ignore => {
      console.log('error when loading lastJobs'); // TODO: change here by a fancy snack to display error
    });
  }

  public getCompanyJobs(companyId): void {
    this.loadingJobs = false;
    this.companyService.getCompanyJobs(companyId, {page: this.pageNumber}).subscribe((data: any) => {
      this.jobsCreate = data['companyJobs'];
      this.lastPage = data.lastPage;
      this.loadingJobs = false;
    }, ignore => {
      this.jobsCreate = [];
      console.log('error when loading lastJobs'); // TODO: change here by a fancy snack to display error
    });
  }
  public playAudio(audioName: string, audioToPlay: string, job: any): void {
    this.audioFileService.addToPlayer(job);
    // this.audioSource = audioToPlay;
    this.audioService.getAudioFile(audioName);
  }
}
