import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {
  public navigation = new Subject<boolean>();
  public navigation$ = this.navigation.asObservable();

  public navigationToDetails = new Subject<boolean>();
  public navigationToDetails$ = this.navigationToDetails.asObservable();
  constructor() { }
}
