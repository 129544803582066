<h1 mat-dialog-title>Mot de passe oublié</h1>
<mat-dialog-content>
  <ng-form [formGroup]="resetForm" class="login-form">
    <mat-form-field appearance="none" style="width: 100%;">
      <input class="login-input" matInput placeholder="Téléphone" formControlName="phone">
      <mat-error *ngIf="resetForm.get('phone').hasError('pattern')">
        Numéro de téléphone invalide
      </mat-error>
    </mat-form-field>
    <p style="text-align: center;">OU</p>
    <mat-form-field appearance="none" style="width: 100%;">
      <input class="login-input" type="email" matInput placeholder="Email" formControlName="email">
      <mat-error *ngIf="resetForm.get('email').hasError('pattern')">
        email incorect
      </mat-error>
    </mat-form-field>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Fermer</button>
      <button mat-button cdkFocusInitial (click)="submit()">Valider</button>
    </mat-dialog-actions>
  </ng-form>
</mat-dialog-content>
