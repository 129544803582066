import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { HttpClientService } from '../http-client/http-client.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl: any;

  constructor(
    private http: HttpClientService,
  ) {
    this.apiUrl = environment.backendApiUrl;
  }


  public addResume(data): Observable<any> {
    console.log(data);
    return this.http.post<any>(this.apiUrl + 'user/resume/add', data)
      .pipe(map((result) => {
        return result['userResume'];
      }));
  }


  /**
   * function to add user CV
   * @param userId
   */
  public getResume(userId: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'user/' + userId + '/resume').pipe(map(cv => {
      return cv['userResume'];
    }));
  }

  public updateResume(userId: number, data): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'user/' + userId + '/resume/update', data);
  }

  public getExperienceLevel(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'experienceLevel').pipe(map(cv => {
      return cv['experienceLevels'];
    }));
  }

  public updateUserInfos(data, userId: number): Observable<any> {
    console.log(data);
    return this.http.post<any>(this.apiUrl + 'user/update/' + userId, data)
      .pipe(map((result) => {
        return result['user'];
      }));
  }

  public sendRegisterConfirmSms(model: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'user/sendregisterconfirmationsms', model);
  }
}
