import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
// import { HttpClient } from '@angular/common/http';
import { HttpClientService } from '../http-client/http-client.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  private apiUrl: any;
  constructor(private http: HttpClientService) {
    this.apiUrl = environment.backendApiUrl;
  }

  public subscribeNewLetter(data: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'newsletter', data)
      .pipe(map((result) => {
        return result;
      }));
  }
}
