import { Component, OnInit } from '@angular/core';
import { NewsletterService } from '../../service/newsletter/newsletter.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackService } from '../../service/snack/snack.service';
import { MatDialog } from '@angular/material/dialog';
import { ContactUsComponent } from '../contact-us/contact-us.component'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public newsLetterForm: FormGroup;
  constructor(private newsLetterService: NewsletterService, private fb: FormBuilder,
              private snack: SnackService,private matDialog: MatDialog,) { }

  ngOnInit(): void {
    this.newsLetterForm = this.fb.group({
      email: ['', [Validators.email]],
    });
  }

  get f(): any {
    return this.newsLetterForm.controls;
  }

  public registerToNewLetter(): void {
    this.newsLetterService.subscribeNewLetter({ email: this.f.email.value }).subscribe((result) => {
      console.log(result);
      this.snack.info('Votre inscription à la newsletter a été prise en compte.');
      this.newsLetterForm.setValue({ email: '' });
    }, (err) => {
      this.snack.error('une erreur est survenue lors de votre souscription.');
    });
  }
  
  public contactForm(): void {
    this.matDialog.open(ContactUsComponent, { width: '470px', height: '470px' });
  }
}
