<h2 mat-dialog-title>Publier offres</h2>
<h3 mat-dialog-title>Veuillez envoyer le fichier Excel contenant vos offres</h3>
<mat-dialog-content class="mat-typography">
  <mat-form-field appearance="none" style="width: 100%;">
    <ngx-mat-file-input class="login-input" [formControl]="fileControl" [multiple]="multiple" [accept]="accept" [color]="color" [placeholder]=" 'choisissez un fichier' ">
    </ngx-mat-file-input>
    <section class="example-section">
      <div style="height: 10px;">

      </div>
      <mat-progress-bar *ngIf="progress !== 0"
          class="example-margin"
          [color]="color"
          [mode]="mode"
          [value]="progress"
          [bufferValue]="bufferValue">
      </mat-progress-bar>
    </section>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-button cdkFocusInitial (click)="submit()">Envoyer</button>
</mat-dialog-actions>
