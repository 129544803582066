<div class="main-header-banner">
    <div class="header-banner">
        <img src="assets/image/compare-bg.jpg">
    </div>
</div>
<form [formGroup]="userInformation" (ngSubmit)="saveUserInformations()">
    <div class="apply-empl-pro-sec apply-dasbord">
        <div class="container">
            <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="apply-emp-profile-box">
                <div fxFlex="45%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div class="apply-emp-profile-main">
                        <div class="apply-emp-profile-sub">
                            <label class="hoverable" for="fileInput">
                <img style="object-fit: contain" [src]="url ? url : imageBaseUrl + user.image">
                <div class="hover-text">Choisir une image</div>
                <div class="background"></div>
              </label>
                            <br />
                            <input id="fileInput" type='file' (change)="onSelectFile($event)" accept="image/*">
                            <button *ngIf="url" mat-raised-button (click)="delete()" class="action-button">Suppimer</button>
                        </div>
                        <div class="apply-emp-profile-info">
                            <div class="apply-emp-star">
                            </div>
                        </div>
                    </div>
                </div>
                <div fxFlex="55%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div class="candidt-info-main">
                        <mat-form-field appearance="none" style="width: 100%;">
                            <h4>
                                <mat-label>Prenom</mat-label>
                            </h4>
                            <label>
                <input class="login-input" matInput placeholder="Prénom" formControlName="firstName" type="text"
                  value="{{ user.firstName}}">
              </label>
                            <div *ngIf="!userInformation.controls.firstName.valid && isFirstBlockErrorShow == true" style="color:red;margin-top:10px;">
                                <div *ngIf="userInformation.controls.firstName.hasError('pattern')">
                                    First Name Is Not Valid
                                </div>
                                <div *ngIf="userInformation.controls.firstName.hasError('required')">
                                    First Name Is required
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field appearance="none" style="width: 100%;">

                            <h4>
                                <mat-label>Nom</mat-label>
                            </h4>
                            <label>
                <!-- <mat-form-field appearance="none" style="width: 40%;"> -->
                      <input class="login-input" matInput placeholder="Nom" formControlName="lastName" type="text"
                        value="{{ user.lastName}}">
                    </label>

                            <div *ngIf="!userInformation.controls.lastName.valid && isFirstBlockErrorShow == true" style="color:red;margin-top:10px;">
                                <div *ngIf="userInformation.controls.lastName.hasError('pattern')">
                                    Last Name Is Not Valid
                                </div>
                                <div *ngIf="userInformation.controls.lastName.hasError('required')">
                                    Last Name Is required
                                </div>
                            </div>
                            <!-- </mat-form-field> -->
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="dash-form">
        <div class="container">
            <div class="dash-form-box">
                <div [formGroup]="userInformation" fxLayout="column" fxLayoutAlign="center start">
                    <div class="dash-form-inr">
                        <mat-form-field appearance="none">
                            <h4>
                                <mat-label>Téléphone</mat-label>
                            </h4>
                            <label>
                <input class="login-input" matInput placeholder="Téléphone" formControlName="phone" type="text"
                  value="{{ user.phone}}">
              </label>
                            <div *ngIf="!userInformation.controls.phone.valid && isSecondBlockErrorShow==true" style="color:red;margin-top:10px;">
                                <div *ngIf="userInformation.controls.phone.hasError('pattern')">
                                    Phone Number Is Not Valid
                                </div>
                                <div *ngIf="userInformation.controls.phone.hasError('required')">
                                    Phone number Is required
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field appearance="none" class="input-with-icon">
                            <h4>
                                <mat-label>Date de naissance</mat-label>
                            </h4>
                            <label>
                <input class="login-input" matInput formControlName="birthDate" [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker startView="multi-year" touchUi></mat-datepicker>
                <!-- here manage the touchUi only for mobile devices-->
              </label>
                            <div *ngIf="!userInformation.controls.birthDate.valid && isSecondBlockErrorShow==true" style="color:red;margin-top:10px;">
                                <!-- <div *ngIf="userInformation.controls.birthDate.hasError('required')">
                  Birth Date Is required
                </div> -->
                            </div>

                        </mat-form-field>
                        <mat-form-field appearance="none">
                            <h4>
                                <mat-label>Email</mat-label>
                            </h4>
                            <label>
                <input class="login-input" matInput placeholder="Email" formControlName="email" type="text"
                  value="{{ user.email}}">
              </label>
                            <div *ngIf="!userInformation.controls.email.valid && isSecondBlockErrorShow==true" style="color:red;margin-top:10px;">
                                <div *ngIf="userInformation.controls.email.hasError('pattern')">
                                    Email Is Not Valid
                                </div>
                                <div *ngIf="userInformation.controls.email.hasError('required')">
                                    Email Is required
                                </div>
                            </div>
                        </mat-form-field>
                        <mat-form-field appearance="none">
                            <h4>
                                <mat-label>Adresse</mat-label>
                            </h4>
                            <label>
                <input class="login-input" matInput placeholder="adresse" formControlName="address" type="text"
                  value="{{ user.address}}">
              </label>
                            <div *ngIf="!userInformation.controls.address.valid && isSecondBlockErrorShow==true" style="color:red;margin-top:10px;">
                                <!-- <div *ngIf="userInformation.controls.address.hasError('required')">
                  Address Is required
                </div> -->
                            </div>
                        </mat-form-field>
                        <mat-slide-toggle formControlName="changePassword">Changer de mot de passe ?</mat-slide-toggle><br>
                        <mat-form-field appearance="none" *ngIf="userInformation.controls.changePassword.value">
                            <h4>
                                <mat-label>Mot de passe</mat-label>
                            </h4>
                            <label>
                <input class="login-input" matInput placeholder="Mot de passe" formControlName="password"
                  type="password" value="{{ user.password}}">
              </label>
                            <div *ngIf="!userInformation.controls.password.valid && isSecondBlockErrorShow==true" style="color:red;margin-top:10px;">
                                <!-- <div *ngIf="userInformation.controls.password.hasError('required')">
                  Password Is required
                </div> -->
                            </div>
                        </mat-form-field>
                        <div class="dash-form-btn validate-btn">
                            <button mat-raised-button type="submit" class="action-button">Enregistrer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>