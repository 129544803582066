<mat-dialog-content class="mat-typography">
    <ng-form [formGroup]="contactForm" class="login-form login-form-design">
        <h2 mat-dialog-title>Merci pour votre intérêt. </h2>
        <h3 mat-dialog-title>Laissez nous vos coordonnées. </h3>
        <h3 mat-dialog-title> Nous vous recontactons rapidement </h3>
        <mat-form-field appearance="none" style="width: 100%;">
            <input class="login-input" matInput placeholder="Nom complet" formControlName="name">
            <div *ngIf="!contactForm.controls.name.valid" style="color:red;margin-top:10px;">
                <div *ngIf="isShowValidation && contactForm.controls.name.hasError('required')">
                    Name Is required
                </div>
            </div>
        </mat-form-field>
        <mat-form-field appearance="none" style="width: 100%;">
            <input class="login-input" matInput placeholder="Adresse mail" formControlName="email">
            <div *ngIf="!contactForm.controls.email.valid" style="color:red;margin-top:10px;">
                <div *ngIf="isShowValidation && contactForm.controls.email.hasError('required')">
                    Email Is required
                </div>
            </div>
        </mat-form-field>
        <mat-form-field appearance="none" style="width: 100%;">
            <input class="login-input" matInput placeholder="Téléphone" formControlName="phone">
            <div *ngIf="!contactForm.controls.phone.valid" style="color:red;margin-top:10px;">
                <div *ngIf="isShowValidation && contactForm.controls.phone.hasError('required')">
                    Phone number Is required
                </div>
            </div>
        </mat-form-field>
        <mat-form-field appearance="none" style="width: 100%;">
            <input class="login-input" matInput placeholder="Message" formControlName="message">
            <div *ngIf="!contactForm.controls.message.valid" style="color:red;margin-top:10px;">
                <div *ngIf="isShowValidation && contactForm.controls.message.hasError('required')">
                    Message Is required
                </div>
            </div>
        </mat-form-field>
    </ng-form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="closeForm()">Close</button>
    <button mat-button cdkFocusInitial (click)="submit()">Valider</button>
</mat-dialog-actions>