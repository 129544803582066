import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackService } from '../../service/snack/snack.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactUsService } from '../../service/contact-us/contact-us.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  public contactForm: FormGroup;
  public isShowValidation = false;
  constructor(
    
    public dialogRef: MatDialogRef<ContactUsComponent>,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private snack: SnackService,
    private contactUsService: ContactUsService,
  ) { }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      message: ['', Validators.required],
      phone: ['', Validators.required]
    });
  }
  public submit(): void {
    if (this.contactForm.invalid) {
      this.isShowValidation = true;
      return;
    }
    const sendData = new FormData();
    sendData.append('name', this.contactForm.controls.name.value);
    sendData.append('email', this.contactForm.controls.email.value);
    sendData.append('message', this.contactForm.controls.message.value);
    sendData.append('phone', this.contactForm.controls.phone.value);
    this.dialogRef.close();
    swal.fire('', 'Request sent successfully', 'success');
    this.contactUsService.addContact(sendData).subscribe((data) => {

      console.log(data);
    }, (err) => {
    });
  }
  public closeForm(): void {
    this.dialogRef.close();
  }
}
